import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import AppRouter from "../../../routes/AppRouter";
import PublicRouter from "../../../routes/PublicRouter";
import Layout from "../Layout";
import { useAuth0 } from "@auth0/auth0-react";
import api from "../../../api/api";
import { Spinner } from "react-bootstrap";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { AlertSlideout } from "../../components/AlertSlideout";

function AuthController() {
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const options = {
    position: positions.BOTTOM_LEFT,
    offset: "8px",
    transition: transitions.FADE,
  };
  // const { isAuthenticated, setAuthenticated } = useContext(AppContext);
  // const [loggedIn, setLoggedIn] = useState();

  useEffect(() => {
    console.log("auth", isAuthenticated);
    if (isAuthenticated) {
      const getAuthToken = async () => {
        // const domain = "https://spark.alpha.rebelware.io";

        try {
          const idToken = await getAccessTokenSilently();
          console.log(idToken);
          api
            .post(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
              id_token: idToken,
            })
            .then((res) => {
              if (!res.data.auth) {
                Promise.reject(new Error("Whoops!"));
              }
              localStorage.setItem("token", res.data.id_token);
              Promise.resolve(true);
            })
            .then(() => {});
        } catch (e) {
          console.log("40", e.message);
        }
      };
      getAuthToken();
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     loginWithRedirect();
  //   }
  // }, []);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // uncomment to add redirect instead of login page
  // if (window.location.pathname.split("/")[1] === "presentations") {
  //   return <PublicRouter />;
  // } else if (
  //   window.location.pathname.split("/")[1] !== "presentations" &&
  //   !isAuthenticated
  // ) {
  //   loginWithRedirect();
  if (
    window.location.pathname.split("/")[1] === "presentations" ||
    !isAuthenticated
  ) {
    return <PublicRouter />;
  } else {
    return (
      <AlertProvider template={AlertSlideout} {...options}>
        <Layout>
          <AppRouter />
        </Layout>
      </AlertProvider>
    );
  }
}

export default AuthController;
