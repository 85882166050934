import React from "react";

export const Frontend404 = () => {
  return (
    <div id="frontend-fourohfour">
      <h1>Error 404</h1>
      <h3>Presentation Not Found</h3>
    </div>
  );
};
