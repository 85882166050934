import React from "react";
import XIcon from "./assets/XIcon";

export const AlertSlideout = ({ style, options, message, close }) => {
  return (
    <div style={style} className={`spark-alert bg-${options.type}`}>
      <p>{message}</p>
      <button className={`bg-${options.type}`} onClick={close}>
        <XIcon height="14px" width="14px" fill="#fff" />
      </button>
    </div>
  );
};
