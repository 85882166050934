import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { createNewPresentationTemplate } from "../../../../../../api/presentationTemplates";
import { useAlert } from "react-alert";

function SaveTemplateModal({ templateData, onHide, template, show }) {
  const { user } = useAuth0();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // save layout
  const { mutate } = useMutation(
    (newTemplate) => createNewPresentationTemplate(newTemplate),
    {
      onSuccess: (postData) => {
        queryClient.invalidateQueries("getPresentationTemplates");
        alert.show("Your presentation has successfully been created.", {
          timeout: 3000,
          type: "success",
        });
        navigate("/presentation-template");
      },
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  const [input, setInput] = useState({
    title: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // set template array
  const templateArr = [];
  template.map((template) => templateArr.push(template.id));

  useEffect(() => {
    if (templateData) {
      setInput({
        title: templateData.title,
        description: templateData.description,
      });
    }
  }, []);

  function handleTemplateSave() {
    mutate({
      title: input.title,
      description: input.description,
      layout: templateArr,
      user: user.sub,
      label: "custom",
    });
  }
  return (
    <Modal show={show} size="lg" aria-labelledby="save-template" centered>
      <Modal.Header>
        <Modal.Title id="save-template">Save Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {!template.length ? (
            <div className="mb-3">
              <h4>Please Add Modules Before Saving</h4>
            </div>
          ) : (
            <>
              <Form.Group className="mb-3" controlId="templateTitle">
                <Form.Label>Template Title</Form.Label>
                <Form.Control
                  type="text"
                  required={true}
                  autoComplete="none"
                  placeholder="Template Title"
                  value={input.title}
                  onChange={handleChange}
                  name="title"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="templateDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Description"
                  value={input.description}
                  onChange={handleChange}
                  name="description"
                />
              </Form.Group>
              <Button
                onClick={handleTemplateSave}
                variant="primary"
                disabled={template.length === 0}
              >
                Save
              </Button>
            </>
          )}

          <Button variant="secondary" onClick={() => onHide()}>
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SaveTemplateModal;
SaveTemplateModal.defaultProps = {
  templateData: null,
  onHide: null,
  template: null,
  show: null,
};
