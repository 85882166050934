import React from "react";
import { Form } from "react-bootstrap";
import XIcon from "./assets/XIcon";

export const Searchbar = ({ setSearchInput, searchInput, placeholder }) => {
  function handleSearchInput(e) {
    setSearchInput(e.target.value);
  }

  function handleSearchClear() {
    setSearchInput("");
  }

  return (
    <div className="rw-searchbar-container">
      <Form.Control
        className="rw-searchbar"
        type="text"
        value={searchInput}
        placeholder={placeholder}
        onChange={handleSearchInput}
      />
      {searchInput && (
        <button
          className="rw-searchbar-btn transparent"
          onClick={handleSearchClear}
        >
          <XIcon width="16px" height="16px" />
          <span className="visually-hidden">Clear search</span>
        </button>
      )}
    </div>
  );
};
Searchbar.defaultProps = {
  placeholder: "Type to filter results below",
};
