import React from "react";
import LinkIcon from "../assets/LinkIcon";
import { useAlert } from "react-alert";

export const CopySharableLinkButton = ({ presentation }) => {
  const alert = useAlert();

  return (
    <>
      <a
        onClick={() => {
          navigator.clipboard.writeText(
            `${process.env.REACT_APP_APP_ENDPOINT}/presentations/${presentation.slug}`
          );
          alert.show("Link copied to clipboard", {
            timeout: 3000,
            type: "success",
          });
        }}
        href="#"
      >
        <div className="d-flex align-items-center">
          <LinkIcon width="16px" height="16px" />
          <p>Copy Sharable Link</p>
        </div>
      </a>
    </>
  );
};
