import React from "react";

function LinkedInIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-336 -115)">
          <g transform="translate(49 23)">
            <g transform="translate(287 92)">
              <path stroke="#000" strokeWidth="2" d="M1 1H19V19H1z"></path>
              <path
                fill="#000"
                fillRule="nonzero"
                d="M7.067 8.282v6.81H4.8v-6.81h2.266zm.15-2.106c0 .654-.493 1.177-1.283 1.177h-.015c-.76 0-1.252-.523-1.252-1.177C4.667 5.51 5.174 5 5.949 5s1.252.509 1.267 1.176zm8.023 5.011v3.904h-2.266V11.45c0-.915-.328-1.54-1.149-1.54-.626 0-.999.421-1.163.828-.06.145-.074.348-.074.552v3.803H8.32s.03-6.17 0-6.81h2.267v.965c.3-.464.84-1.124 2.042-1.124 1.492 0 2.61.973 2.61 3.064z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LinkedInIcon;
