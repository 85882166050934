import React from "react";

export const TextTitle = ({
  id,
  data,
  title,
  index,
  active,
  handleNavigate,
}) => {
  if (!data[index] || !data[index].active || data[index].text === "") {
    return null;
  } else {
    console.log("textTitle", data[index]);
    return (
      <>
        <div className="nav-link">
          <a
            className={active === id ? "active" : ""}
            href={`#${id}`}
            onClick={() => handleNavigate(id)}
          >
            {title}
          </a>
        </div>
      </>
    );
  }
};
