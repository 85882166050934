import React from "react";
import dompurify from "dompurify";
import { Carousel } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import QuoteIcon from "../../../../components/assets/QuoteIcon";

export const Testimonials = ({ id, data, title, index }) => {
  const sanitizer = dompurify.sanitize;
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].testimonials.length > 0 && (
          <ComponentWrapper id={id} title={title}>
            <Carousel>
              {data[index].testimonials.map((testimonial, idx) => (
                <Carousel.Item
                  className="slide-item"
                  id={`testimonial-${idx}`}
                  index={idx}
                  key={`testimonial-${idx}`}
                >
                  <div className="slide-container">
                    {testimonial.image && testimonial.image.length ? (
                      <img
                        className="testimonial-image"
                        width="40"
                        height="40"
                        src={testimonial.image[0].fileUrl[1]}
                        alt={testimonial.image[0].fileAlt}
                      />
                    ) : (
                      <QuoteIcon width="2.5rem" height="2.5rem" />
                    )}
                    <div
                      className="testimonial-testimonial"
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(testimonial.testimonial),
                      }}
                    ></div>
                    <p className="testimonial-citation">{`- ${testimonial.name}`}</p>
                    <small className="testimonial-citation">
                      {testimonial.company}
                    </small>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </ComponentWrapper>
        )}
      </>
    );
  }
};
