import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

export const TestimonialImportModal = ({
  show,
  data,
  mutate,
  isLoading,
  isError,
  ...props
}) => {
  const [length, setLength] = useState(0);
  const { user } = useAuth0();
  useEffect(() => {
    setLength(data.length);
  }, [data]);
  if (data) {
    // make sure name in unique
    const validation = data.every((item) => item.name !== "");

    // make sure no duplicate names
    const uniqueValues = new Set(data.map((v) => v.name));

    function upload() {
      mutate({
        data,
        user: user.sub,
      });
    }

    return (
      <Modal
        {...props}
        show={show}
        className="spark-modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Import Team Members
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validation === false ? (
            <div className="error-message">
              Title is required for all testimonials
            </div>
          ) : uniqueValues.size !== data.length ? (
            <div className="error-message">
              Title must be unique for all testimonials
            </div>
          ) : (
            <>
              <Table responsive className="team-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Testimonial</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={`import-${index}`}>
                      <td>{item.title}</td>
                      <td>{item.name}</td>
                      <td>{item.company}</td>
                      <td>
                        {item.testimonial.split(" ").slice(0, 5).join(" ")} ...
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
        <div className="my-3">
          <small>
            Ready to import <strong>{length}</strong> records
          </small>
        </div>
        <Modal.Footer>
          {(validation === true || uniqueValues.size === data.length) && (
            <Button variant="tertiary" onClick={upload}>
              Import
            </Button>
          )}
          <Button variant="primary" onClick={props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
