import React from "react";
import EmailIcon from "../assets/EmailIcon";

export const SendPresentationButton = ({ setShowEmail }) => {
  function handleOpenModal() {
    setShowEmail(true);
  }
  return (
    <>
      <button className="transparent p-0" onClick={handleOpenModal}>
        <div className="d-flex align-items-center">
          <EmailIcon width="16px" height="16px" />
          <p>Send Email</p>
        </div>
      </button>
    </>
  );
};
