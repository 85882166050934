import React from "react";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import EmailIcon from "../../../../components/assets/EmailIcon";
import PhoneIcon from "../../../../components/assets/PhoneIcon";
import LinkedInIcon from "../../../../components/assets/LinkedInIcon";

export const ContactInformation = ({ id, data, title, contactRef, index }) => {
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].teamMembers.length > 0 && (
          <ComponentWrapper id={id} title={title}>
            {data[index].teamMembers.map((teamMember, idx) => (
              <Row
                className="contact-info-row"
                key={`tm-${idx}`}
                ref={contactRef}
              >
                <Col className="title-col" lg={4}>
                  <h3 className="contact-info-name">{teamMember.name}</h3>
                  <h4 className="contact-info-title">{teamMember.title}</h4>
                </Col>
                <Col className="contact-col" lg={8}>
                  {teamMember.email && (
                    <a
                      href={`mailto:${teamMember.email}`}
                      className="contact-info-contact"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <EmailIcon width="16px" height="16px" />
                      <span>{teamMember.email}</span>
                    </a>
                  )}
                  {teamMember.phone && (
                    <a
                      href={`tel:${teamMember.phone}`}
                      className="contact-info-contact"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <PhoneIcon width="16px" height="16px" />
                      <span>{teamMember.phone}</span>
                    </a>
                  )}
                  {teamMember.linkedIn && (
                    <a
                      href={teamMember.linkedIn}
                      className="contact-info-contact"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <LinkedInIcon width="16px" height="16px" />
                      <span>{teamMember.linkedIn}</span>
                    </a>
                  )}
                </Col>
              </Row>
            ))}
          </ComponentWrapper>
        )}
      </>
    );
  }
};
