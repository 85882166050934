import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { CollectionList } from "../../../layouts/CollectionList";
import { TeamListItem } from "./TeamListItem";

export const ListTeamMembers = ({
  setDeleteTeamObj,
  setShowModal,
  data,
  isLoading,
  isError,
}) => {
  console.log("teamMembers", data);
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return null;
  } else {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return (
      <CollectionList
        title="View / Edit Team Members"
        loading={isLoading}
        error={isError}
      >
        {data.map((teamMember) => (
          <Fragment key={teamMember._id}>
            <TeamListItem
              teamMember={teamMember}
              setDeleteTeamObj={setDeleteTeamObj}
              setShowModal={setShowModal}
            />
          </Fragment>
        ))}
      </CollectionList>
    );
  }
};
