import React from "react";

function HomeIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="gray" transform="translate(-31 -237)">
          <g transform="translate(0 107)">
            <g transform="translate(0 55) translate(28 75)">
              <g transform="translate(3)">
                <path d="M12.325 7.114l-.575-.57V12H7.455V7.294h-1.94V12H1.25V6.544l-.575.57L0 6.444 6.496 0 6.5.004 6.504 0 13 6.445l-.675.67zM6.5 1.335L2.205 5.597v5.456H4.56V6.347H8.41v4.706h2.386V5.596L6.5 1.335z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HomeIcon;
