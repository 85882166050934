import React from "react";

export const FileName = ({ filename }) => {
  const split = filename.split(".");
  let name = split[0];
  const extension = split[split.length - 1];
  if (name.length > 10) {
    name = name.substring(0, 20);
  }
  return <p>{name + "..." + extension}</p>;
};
