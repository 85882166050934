import React from "react";
import { Modal } from "react-bootstrap";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export const DocumentModal = ({ document, ...props }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (!document) {
    return null;
  } else {
    console.log("doc", document);
    return (
      <Modal
        {...props}
        className="document-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {document.fileAlt}
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Viewer
            plugins={[defaultLayoutPluginInstance]}
            fileUrl={document.fileUrl[0]}
          />
        </Modal.Body>
      </Modal>
    );
  }
};
