import React, { useEffect, useRef, useState } from "react";
import dompurify from "dompurify";
import { Modal } from "react-bootstrap";
import ImportIcon from "../components/assets/ImportIcon";

export const ScreenWrapper = ({
  screenTitle,
  importIcon,
  handleCsv,
  children,
  show,
  csv,
  csvRequiredStatement,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const fileUploadInput = useRef(null);
  const sanitizer = dompurify.sanitize;

  const handleClick = () => {
    fileUploadInput.current.click();
  };

  useEffect(() => {
    if (show === false) {
      fileUploadInput.current.value = "";
    }
  }, [show]);

  return (
    <>
      <div className="screen-header">
        <h1>{screenTitle}</h1>
        {importIcon && (
          <div className="button-import">
            <div className="button-import-input">
              <button className="btn-import" onClick={handleClick}>
                <ImportIcon height="16px" width="16px" />
                {`Import ${screenTitle}`}
              </button>
              <input
                type="file"
                className="import-input"
                multiple={false}
                name="csv-upload"
                accept=".csv"
                ref={fileUploadInput}
                onChange={handleCsv}
              />
            </div>

            <div className="info-btn-wrapper">
              <button className="btn-info" onClick={() => setModalShow(true)}>
                <span>i</span>
              </button>
            </div>
            <ImportInfoModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        )}
      </div>
      <>{children}</>
    </>
  );

  function ImportInfoModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        className="spark-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            How To Import A CSV File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="import-info-modal">
          <div className="sample-button">
            <a href={csv} className="btn btn-primary" download>
              Download A Sample CSV
            </a>
          </div>

          <p className="small">
            <strong className="fst-italic">If we may:</strong>
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(csvRequiredStatement),
            }}
          ></div>

          <p>
            <strong>Step 1:</strong>
          </p>
          <p>
            Easy: click the Import button, find the file you want to upload,
            then click Open.
          </p>
          <p>
            <strong>Step 2:</strong>
          </p>
          <p>
            Peasy: once you confirm the displayed information matches everything
            in your CSV, click Import.
          </p>
          <p>
            <strong>Step 3:</strong>
          </p>
          <p>Lemon Squeezy: Click Save. You&apos;re dunzo.</p>
        </Modal.Body>
      </Modal>
    );
  }
};

ScreenWrapper.defaultProps = {
  show: true,
};
