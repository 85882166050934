import React from "react";
import { Link } from "react-router-dom";
import arm from "./assets/images/BrynArm.png";
import body from "./assets/images/BrynBody.png";

function NotFound() {
  return (
    <div id="fourohfour">
      <div id="bloc404">
        <div className="row bryn">
          <div className="col-12 col-xl-5 error-bryn">
            <img className="brynBody" src={body} alt="Bryn Body" />
            <img className="wiggle" src={arm} alt="Bryn Arm" />
          </div>

          <div className="col-12 col-xl-6 error-text-bryn">
            <span>Ah Ah Ah ...</span>
            <h1>ERROR 404</h1>
            <span>Not Found</span>
            <p>Sorry about that&mdash;we forgot to use the magic word.</p>
            <Link className="btn btn-primary" to="/">
              Return to Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
