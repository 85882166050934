import React from "react";

export const ComponentWrapper = ({ children, title, id }) => {
  return (
    <div className="presentation-item-container">
      <div className={`${id} component-wrapper`}>
        {title && <h2 className="component-title">{title}</h2>}

        {children}
      </div>
    </div>
  );
};
