import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { slugify } from "../../../components/slugify";
import { ClientInfo } from "../frontend/modules/ClientInfo";
import { frontendModules } from "../frontend/modules/components";

export const PresentationPreview = ({ presentationData }) => {
  console.log("preview presentationData", presentationData);
  if (!presentationData) {
    return <h4>No Presentation Found Here</h4>;
  } else {
    const presentationComponents = [];
    presentationData.layout.forEach((o1) => {
      frontendModules.forEach((o2) => {
        if (o2.id === o1) {
          presentationComponents.push(o2);
        }
      });
    });

    return (
      <div className="presentation-preview-page presentation-page py-4">
        {presentationData.clientInfo.active && (
          <ClientInfo
            data={presentationData.clientInfo}
            title={presentationData.presentationTitle}
          />
        )}
        <Row>
          <Col>
            <div className="presentation-display">
              {presentationComponents.map((component, idx) => (
                <div key={`presentation-${idx}`} id={slugify(component.name)}>
                  <component.component
                    data={presentationData.data}
                    index={idx}
                    title={component.name}
                    id={slugify(component.name)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
