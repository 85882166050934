export const verifyUrlField = async (url) => {
  const hasPrefix = url.split("://");

  let validation = false;

  if (hasPrefix.length > 1) {
    const prefix = hasPrefix[0];
    const regex = /(http|https?)/;
    if (regex.exec(prefix)) {
      validation = true;
    }
  } else {
    validation = false;
  }

  return validation;
};
