import React from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { PresentationPreview } from "./PresentationPreview";
import { getPresentation } from "../../../../api/presentations";
import { useQuery } from "react-query";

export const PreviewModal = ({ show, title, onHide, id }) => {
  const { data, isLoading, isError } = useQuery(
    ["getPresentation", id],
    () => getPresentation(id),
    {
      onError: (error) => {
        alert.show(error.message);
      },
    }
  );

  function handleClose() {
    const noHashURL = window.location.href.replace(/#.*$/, "");
    window.history.replaceState("", document.title, noHashURL);
    onHide(false);
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this presentation</h4>;
  } else {
    const presentationData = data[0];

    return (
      <>
        <Modal
          onHide={() => handleClose()}
          show={show}
          className="spark-modal"
          aria-labelledby="presentation-preview"
          size="xl"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {`Preview Presentation: ${title}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="preview-modal-body">
            <Container>
              <PresentationPreview presentationData={presentationData} />
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};
