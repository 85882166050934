import React from "react";
import HeaderNavigation from "../navigation/HeaderNavigation";

function Header() {
  return (
    <header id="header" className="banner">
      <HeaderNavigation />
    </header>
  );
}

export default Header;
