import React from "react";
import { Container } from "react-bootstrap";
import { FrontEndHeader } from "./FrontEndHeader";

export const FrontEndLayout = ({
  children,
  expiration,
  isLoading,
  contactRef,
}) => {
  return (
    <div id="presentation-content-wrapper">
      <FrontEndHeader
        expiration={expiration}
        isLoading={isLoading}
        contactRef={contactRef}
      />
      <main id="presentation-container" className="w-100">
        <Container>{children}</Container>
      </main>
    </div>
  );
};
