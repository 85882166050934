import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

function ServicesPreviewModule() {
  return (
    <ComponentWrapper id="services" title="Services">
      <Row className="service-row">
        <Col className="service-title-col" xs={12}>
          <h3 className="contact-index">1</h3>
          <h3 className="service-title">Digital Marketing</h3>
        </Col>
        <Col className="service-description-col" xs={12}>
          <div>
            <p>
              Powerful marketing needs strategic creativity, digital fluency,
              and obsessive curiosity. Rebel provides outstanding results, as
              well as transparency in our techniques, by managing campaigns
              in-house.
            </p>
          </div>
          <button className="service-link transparent">View Service</button>
        </Col>
      </Row>
      <Row className="service-row">
        <Col className="service-title-col" xs={12}>
          <h3 className="contact-index">2</h3>
          <h3 className="service-title">Web Development</h3>
        </Col>
        <Col className="service-description-col" xs={12}>
          <div>
            <p>
              Building a website for your brand is more than just the marriage
              of beautiful design and well-written code. It’s building an engine
              that accomplishes your business goals through functionality and
              user experience.
            </p>
            <p>
              We’ve got the technical skills to do it. To name a few, our team
              are experts in PHP, .NET, HTML5, CSS3, Javascript, Ajax, XML, SQL,
              WordPress, Drupal, SharePoint, Sitecore, Umbraco, Kentico, Jekyll
              and the rest of the Jamstack, Magento, Bigcommerce, Shopify,
              Volusion, WooCommerce and more. Our experts work with you directly
              to create a custom website that not only accomplished your goals,
              but is also search engine-friendly and easy to use.
            </p>
          </div>
          <button className="service-link transparent">View Service</button>
        </Col>
      </Row>
      <Row className="service-row">
        <Col className="service-title-col" xs={12}>
          <h3 className="contact-index">3</h3>
          <h3 className="service-title">Graphic Design</h3>
        </Col>
        <Col className="service-description-col" xs={12}>
          <div>
            <p>
              Beautiful, award-winning design starts with a plan and a whole lot
              of talent. Our team of designers is adept at distilling the core
              principles of your business in order to represent you in the
              strongest fashion. The collective brain behind the visuals, our
              designers have a trained eye for creating impactful imagery and
              designs.
            </p>
          </div>
          <button className="service-link transparent">View Service</button>
        </Col>
      </Row>
    </ComponentWrapper>
  );
}

export default ServicesPreviewModule;
