import * as React from "react";

function ImportIcon(props) {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      viewBox="0 0 18 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.443 13.923h-.115a.568.568 0 01-.283.077H1.637A.708.708 0 011 13.387V9.48c0-.142.059-.278.163-.379a.57.57 0 01.394-.157H3.15a.57.57 0 01.395.157c.104.1.163.237.163.38v1.285c.057.295.257.629.875.629h8.836c.618 0 .819-.334.876-.629V9.48c0-.142.058-.278.163-.379a.57.57 0 01.394-.157h1.592c.308 0 .557.24.557.536v3.907c0 .296-.25.536-.557.536zM9.669 8.65a.827.827 0 01-.546.222l-.027.001H9.07a.827.827 0 01-.546-.223L4.446 4.866a.762.762 0 01-.013-1.107l.76-.745a.83.83 0 01.572-.236.832.832 0 01.579.224l1.395 1.26V.781c0-.207.086-.406.238-.553A.831.831 0 018.553 0H9.64c.216 0 .423.083.576.23a.767.767 0 01.238.553V4.26l1.396-1.26a.832.832 0 01.578-.223.83.83 0 01.573.235l.76.746a.762.762 0 01-.013 1.107L9.669 8.65z"
        fill="gray"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ImportIcon;
