import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { CollectionList } from "../../../layouts/CollectionList";
import { ServiceListItem } from "./ServiceListItem";

export const ListServices = ({
  setDeleteServiceObj,
  setShowModal,
  data,
  isLoading,
  isError,
}) => {
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return null;
  } else {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
      <CollectionList
        title="View / Edit Services"
        loading={isLoading}
        error={isError}
      >
        {data.map((service) => (
          <Fragment key={service._id}>
            <ServiceListItem
              service={service}
              setDeleteServiceObj={setDeleteServiceObj}
              setShowModal={setShowModal}
            />
          </Fragment>
        ))}
      </CollectionList>
    );
  }
};
