import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { updateAdditionalContacts } from "../../../api/presentations";
import PlusIcon from "../assets/PlusIcon";
import TrashIcon from "../assets/TrashIcon";
import { useAlert } from "react-alert";

// takes in update presentation function and also client info
export const EmailModal = ({ loading, show, presentationId, ...props }) => {
  const [data, setData] = useState();
  const [additionalContacts, setAdditionalContacts] = useState([]);
  const [inputList, setInputList] = useState([{ name: "", email: "" }]);
  const queryClient = useQueryClient();
  const alert = useAlert();

  useEffect(() => {
    if (presentationId.additionalContacts !== []) {
      const addContacts = [];
      const contacts = presentationId.additionalContacts;
      contacts.forEach((contact) => {
        addContacts.push({ name: contact[0].name, email: contact[0].email });
      });
      setAdditionalContacts(addContacts);
      setData(presentationId.mainContact);
    }
  }, [loading, show]);

  useEffect(() => {
    setInputList(additionalContacts);
  }, [additionalContacts]);

  // save presentation
  const { mutate } = useMutation(updateAdditionalContacts, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["getPresentations", { id: presentationId }],
        data
      );
      queryClient.invalidateQueries("getPresentations");
      queryClient.invalidateQueries("getPresentation");
      alert.show("Your presentation has successfully been sent.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  function handleContactInformation(e) {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", email: "" }]);
  };

  function sendEmail() {
    mutate({
      additionalContacts: inputList,
      mainContact: data,
      id: presentationId,
    });
    setAdditionalContacts([]);
  }

  if (data === undefined) {
    return null;
  } else {
    return (
      <Modal
        {...props}
        show={show}
        className="spark-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Send Presentation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="py-3">
            <h4 className="mb-0">Main Contact</h4>
          </Row>
          <Row className="mb-3">
            <Col lg={6}>
              <Form.Group className="mb-0">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Client Name"
                  placeholder="Client Name"
                  value={data.name}
                  onChange={handleContactInformation}
                  name="name"
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-0">
                <Form.Control
                  type="email"
                  className="form-control"
                  aria-label="Client Email"
                  placeholder="Client Email"
                  value={data.email}
                  onChange={handleContactInformation}
                  name="email"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="py-3">
            <h4 className="mb-0">Additional Contacts</h4>
          </Row>
          {inputList.map((x, i) => (
            <Row
              key={`additional-contacts-${i}`}
              className="mb-3 align-items-center"
            >
              <Col lg={5}>
                <Form.Control
                  name="name"
                  placeholder="Client Name"
                  value={x.name}
                  onChange={(e) => handleInputChange(e, i)}
                  required
                />
              </Col>
              <Col lg={5}>
                <Form.Control
                  className="ml10"
                  name="email"
                  placeholder="Client Email"
                  value={x.email}
                  onChange={(e) => handleInputChange(e, i)}
                  required
                />
              </Col>
              <Col lg={2}>
                {inputList.length !== 0 && (
                  <button
                    className="transparent"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <TrashIcon height="16px" width="16px" />
                  </button>
                )}
                {inputList.length - 1 === i && (
                  <button className="transparent" onClick={handleAddClick}>
                    <PlusIcon height="16px" width="16px" />
                  </button>
                )}
              </Col>
            </Row>
          ))}
          {!inputList.length && (
            <Row className="mb-3 justify-content-end">
              <Col lg={12}>
                <Button variant="outline-dark" onClick={handleAddClick}>
                  Add Contact
                </Button>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={sendEmail}>
            Send
          </Button>
          <Button variant="primary" onClick={props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
