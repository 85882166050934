import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { AppContext } from "../../../../context/AppContext";

export const ViewByFilter = ({
  // presentationData,
  // setViewFilteredData,
  filters,
}) => {
  const { setCurrentTab } = useContext(AppContext);
  // const date = moment(new Date()).format();
  // const [active, setActive] = useState("filter-0");
  // const [allArr, setAllArr] = useState([]);
  // const [activeArr, setActiveArr] = useState([]);
  // const [draftArr, setDraftArr] = useState([]);
  // const [scheduledArr, setScheduledArr] = useState([]);
  // const [expiredArr, setExpiredArr] = useState([]);
  // const [trashArr, setTrashArr] = useState([]);
  // if there are presentations, get the counts for filters.
  // useEffect(() => {
  //   if (presentationData) {
  //     // get trash presentations
  //     const trash = presentationData.filter((data) => data.status === "trash");
  //     setTrashArr(trash);

  //     // get all presentations
  //     const all = presentationData.filter((data) => data.status !== "trash");
  //     setAllArr(all);
  //     setViewFilteredData(all);
  //     // get draft presentations
  //     const draft = presentationData.filter((data) => data.status === "draft");
  //     setDraftArr(draft);

  //     // get scheduled presentations
  //     const scheduled = presentationData.filter(
  //       (data) =>
  //         data.publishDate > date &&
  //         (data.status !== "trash" || data.status !== "draft")
  //     );
  //     setScheduledArr(scheduled);

  //     // get expired presentations
  //     const expired = presentationData.filter(
  //       (data) => data.expirationDate < date && data.status !== "trash"
  //     );
  //     setExpiredArr(expired);

  //     // get active presentations

  //     setActiveArr(
  //       presentationData.filter(
  //         (data) =>
  //           !expired.includes(data) &&
  //           !scheduled.includes(data) &&
  //           !draft.includes(data) &&
  //           !trash.includes(data)
  //       )
  //     );
  //   }
  // }, [presentationData]);

  // function handleClick(e) {
  //   setActive(e.target.id);
  //   // eslint-disable-next-line eqeqeq
  //   const arr = filters.filter((item, idx) => e.target.dataset.key == idx);
  //   setViewFilteredData(arr[0].array);
  // }

  const [active, setActive] = useState();

  useEffect(() => {
    setActive("0");
  }, []);

  function handleClick(e) {
    setActive(e.target.id);
    setCurrentTab(e.target.name);
  }

  return (
    <>
      <h2 className="mb-3">Filter</h2>
      <div className="filter-by-selectors">
        <p className="filter-title">View By:</p>
        {filters.map((item, idx) => (
          <button
            className={`filters transparent ${
              active === String(idx) ? "active" : ""
            }`}
            onClick={handleClick}
            id={idx}
            key={idx}
            data-key={idx}
            name={item.name}
          >
            {`${item.name} (${item.count})`}
          </button>
        ))}
      </div>
    </>
  );
};
