import React from "react";

export const PresentationEditWrapper = ({ title, children }) => {
  return (
    <div className="presentation-editor">
      <h1 className="presentation-editor-title">{title}</h1>
      {children}
    </div>
  );
};
