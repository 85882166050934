import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";

import { useAlert } from "react-alert";
import { deletePresentation, trashPresentation } from "../../api/presentations";

export const ConfirmationPresentationModal = ({
  message,
  item,
  mutate,
  id,
  show,
  deleteMutation,
  ...props
}) => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  // delete presentation mutation
  const { mutate: permDelete } = useMutation(
    ["deletePresentation", id],
    () => deletePresentation(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPresentations");
        alert.show("Your presentation has successfully been deleted.", {
          timeout: 3000,
          type: "success",
        });
      },
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // send presentation to trash
  const { mutate: sendToTrash } = useMutation(trashPresentation, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPresentations");
      alert.show(
        "Your presentation has successfully been moved to the trash.",
        {
          timeout: 3000,
          type: "success",
        }
      );
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // send presentation to trash
  const { mutate: restoreFromTrash } = useMutation(trashPresentation, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPresentations");
      alert.show("Your presentation has successfully been restored.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  function handleDelete(id) {
    console.log("delete", deleteMutation);
    if (deleteMutation === "permDelete") {
      permDelete();
    } else if (deleteMutation === "sendToTrash") {
      sendToTrash({
        id: id,
        status: "trash",
      });
    } else if (deleteMutation === "restoreFromTrash") {
      restoreFromTrash({
        id: id,
        status: "draft",
      });
    }
  }

  return (
    <Modal
      {...props}
      show={show}
      className="spark-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p className="text-center">
          {message}
          <span className="fw-bold ms-2">{item}</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="tertiary" onClick={() => handleDelete(id)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
