import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

// Set Initial State
const initialState = {
  isLoading: true,
  isAuthenticated: false,
  authToken: null,
  mobileNavState: false,
  blankPresentationComponents: [],
  presentationData: [],
  listCount: 0,
  currentTab: "All",
  showAlert: {
    show: false,
    message: "",
    variant: "",
  },
  alertBar: {
    show: false,
    message: "",
    variant: "",
  },
};

// create context
export const AppContext = createContext(initialState);

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function setLoading(payload) {
    dispatch({
      type: "SET_LOADING",
      payload: payload,
    });
  }

  function setListCount(payload) {
    dispatch({
      type: "SET_LIST_COUNT",
      payload: payload,
    });
  }

  function setCurrentTab(payload) {
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: payload,
    });
  }

  function setAlertBar(payload) {
    dispatch({
      type: "SET_ALERT_BAR",
      payload: payload,
    });
  }
  function setShowAlert(payload) {
    const closeAlert = {
      show: false,
      message: "",
      variant: "",
    };
    if (state.showAlert.show) {
      dispatch({
        type: "SET_SHOW_ALERT",
        payload: closeAlert,
      });
    }
    dispatch({
      type: "SET_SHOW_ALERT",
      payload: payload,
    });
    setTimeout(() => {
      dispatch({
        type: "SET_SHOW_ALERT",
        payload: closeAlert,
      });
    }, 3000);
  }

  // Check if current session is authenticated
  function setAuthenticated(userToken) {
    // const userToken = localStorage.getItem("token");
    // console.log("31", userToken);
    if (userToken !== null) {
      dispatch({
        type: "IS_AUTHENTICATED",
        payload: true,
      });
    } else {
      dispatch({
        type: "IS_AUTHENTICATED",
        payload: false,
      });
    }
  }

  function setMobileNavState(payload) {
    dispatch({
      type: "SET_MOBILE_NAV_STATE",
      payload: payload,
    });
  }

  function updateBlankPresentationComponents(payload) {
    const arr = state.blankPresentationComponents;
    arr.push(payload);
    dispatch({
      type: "BLANK_PRESENTATION_COMPONENTS",
      payload: arr,
    });
  }

  return (
    <AppContext.Provider
      value={{
        isLoading: state.isLoading,
        isAuthenticated: state.isAuthenticated,
        authToken: state.authToken,
        mobileNavState: state.mobileNavState,
        blankPresentationComponents: state.blankPresentationComponents,
        listCount: state.listCount,
        currentTab: state.currentTab,
        showAlert: state.showAlert,
        alertBar: state.alertBar,
        setAlertBar,
        setLoading,
        setAuthenticated,
        setMobileNavState,
        updateBlankPresentationComponents,
        setListCount,
        setCurrentTab,
        setShowAlert,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
