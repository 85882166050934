import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getMediaAlt } from "../../../../../api/media";

export const FileInfoAlt = ({ id, fileAlt, setFileAlt }) => {
  const { data } = useQuery(["mediaAlt", id], () => getMediaAlt(id), {
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
    onSuccess: (data) => {
      setFileAlt(data.Media[0].fileAlt);
    },
  });

  return (
    <input
      id="alt_text"
      type="text"
      value={fileAlt}
      onChange={(e) => setFileAlt(e.target.value)}
    />
  );
};
