import React from "react";
import dompurify from "dompurify";
import { Col, Row } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";

export const Services = ({ id, data, title, index }) => {
  const sanitizer = dompurify.sanitize;
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].services.length > 0 && (
          <ComponentWrapper id={id} title={title}>
            {data[index].services.map((service, idx) => (
              <Row className="service-row" key={`service-${idx}`}>
                <Col className="service-title-col" xs={12}>
                  <h3 className="contact-index">
                    {(idx + 1).toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                  </h3>
                  <h3 className="service-title">{service.title}</h3>
                </Col>
                <Col className="service-description-col" xs={12}>
                  <div className="d-flex">
                    {service.image && service.image.length ? (
                      <figure className="mb-2 me-4">
                        <img
                          className="service-image"
                          width="150"
                          height="auto"
                          src={service.image[0].fileUrl[0]}
                          alt={service.image[0].fileAlt}
                        />
                      </figure>
                    ) : null}
                    <div>
                      <div
                        className="service-description"
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(service.description),
                        }}
                      ></div>
                      {service.url !== "" && (
                        <a
                          className="service-link"
                          href={service.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {service.urlTitle ? service.urlTitle : "View Service"}
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
          </ComponentWrapper>
        )}
      </>
    );
  }
};
