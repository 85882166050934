import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createNewMedia } from "../../../../../api/media";

export const MediaUpload = ({ multiple, accept }) => {
  const fileUploadInput = useRef(null);
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);

  // File Upload Mutation
  const { mutate } = useMutation(createNewMedia, {
    onSuccess: (newMedia) => {
      const data = newMedia.data.uploadResponse;
      data.forEach((file) => {
        queryClient.setQueryData("allMedia", (prev) => [...prev, file]);
      });
      setFiles([]);
    },
  });

  const handleClick = () => {
    fileUploadInput.current.click();
  };

  function handleSetFiles(e) {
    setFiles(Array.from(e.target.files));
  }

  // set form data and mutate
  useEffect(() => {
    const formData = new FormData();
    if (files.length) {
      files.forEach((file) => {
        formData.append("file", file);
        formData.append("name", file.name);
      });
      mutate(formData);
      fileUploadInput.current.value = "";
    }
  }, [files]);
  return (
    <div className="rw_media_upload">
      <p className="rw_media_upload_text">Drag and Drop files here</p>
      <p className="rw_media_upload_text">or</p>
      <button className="rw_media_upload_button" onClick={handleClick}>
        Browse Files
      </button>
      <input
        type="file"
        className="rw_media_upload_input"
        multiple={multiple}
        name="media"
        accept={accept}
        ref={fileUploadInput}
        onChange={handleSetFiles}
      />
      {/* <MediaUploadButton multiple={props.multiple} uploadedFiles={setFileList} /> */}
    </div>
  );
};
