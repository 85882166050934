import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { duplicatePresentation } from "../../../api/presentations";
import DuplicateIcon from "../assets/DuplicateIcon";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "react-alert";

export const DuplicatePresentationButton = ({ presentation }) => {
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const alert = useAlert();

  // duplicate presentation
  const { mutate: duplicateMutation } = useMutation(duplicatePresentation, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPresentations");
      alert.show("Your presentation has successfully been duplicated.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  function handleDuplicateSelect(data) {
    duplicateMutation({
      data: data,
      user: user,
    });
  }

  return (
    <>
      <button
        className="transparent p-0"
        onClick={() => handleDuplicateSelect(presentation)}
      >
        <div className="d-flex align-items-center">
          <DuplicateIcon width="16px" height="16px" />
          <p>Duplicate</p>
        </div>
      </button>
    </>
  );
};
