import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../components/assets/Logo";

function Footer() {
  const date = new Date().getFullYear();
  return (
    <footer id="footer" className="content-info">
      <div className="container-fluid">
        <div className="d-flex justify-content-between">
          <Logo width={147} height={60} />
          <div className="footer-links">
            <Nav>
              <Nav.Item>
                <p className="text-white mb-0">
                  <small>{`Spark by RebelWare © ${date} - All Rights Reserved`}</small>
                </p>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
