import React from "react";

function EmailIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 244.25 250"
      data-name="Envelope Icon 1"
      fill="#222"
      fillRule="evenodd"
      {...props}
    >
      <path d="M233.62,222.4c-11.638,11.667-27.168,27.28-42.793,27.019-15.506-.259-53.114,1.443-126.806-67.6l-0.12.12c-0.336-.385-0.642-0.753-0.974-1.135-0.2-.193-0.4-0.369-0.605-0.563l0.069-.063C-3.647,103.849-.269,66.538.16,51.112c0.432-15.545,16.8-30.291,29.025-41.343C44.893-4.427,50.477-.153,59.917,4.106S82.419,24,98.048,40.352,109.42,67.025,106.015,73.2,91.88,89.441,88.373,93.021s-9.516,6.416-5.691,11.327c3.4,4.37,22.817,26.956,27.056,31.882,3.409,3.178,26.9,25.057,31.31,28.783,4.76,4.02,7.874-1.827,11.623-5.155S167,146.31,173.354,143.2s16.9-6.885,32.622,9.379,30.838,29.943,34.694,39.517S248.569,207.411,233.62,222.4Zm-6.33-24.089c-7.714-9.429-20.581-25.011-30.4-33.658s-13.684-11.291-25.016-1.107-22.259,28.928-41,12.359c-16.34-14.446-28.289-26.309-31.083-29.113l-0.039.035S87.1,133.341,71.3,113.975s3.5-29.4,14.228-40.21,8.245-14.776,0-24.919S62.211,25.22,53.088,17.132s-15.216,4.343-21.626,10.76-18.9,14.992-14.8,41.343c6.984,46.319,49.45,92.876,56.913,100.756l0.027-.025s49.087,52.363,99.762,62.237c26.271,5.248,35.434-6.786,42.162-12.874S235.005,207.735,227.29,198.307Z" />
    </svg>
  );
}

export default EmailIcon;
