import React from "react";
import dompurify from "dompurify";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";

export const Text = ({ id, data, title, index }) => {
  const sanitizer = dompurify.sanitize;
  if (!data[index] || !data[index].active || data[index].text === "") {
    return null;
  } else {
    return (
      <ComponentWrapper id={id}>
        <Row>
          <div
            className="team-member-bio"
            dangerouslySetInnerHTML={{
              __html: sanitizer(data[index].text),
            }}
          ></div>
        </Row>
      </ComponentWrapper>
    );
  }
};
