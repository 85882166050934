import React from "react";

function CaseStudyIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="gray" fillRule="nonzero" transform="translate(-32 -404)">
          <g transform="translate(0 107)">
            <g transform="translate(0 55) translate(28 75)">
              <g transform="translate(0 56)">
                <g transform="translate(4 111)">
                  <path d="M10.043 14H.761c-.38-.128-.644-.371-.761-.76V.76C.108.395.394.109.76 0h7c.136.093.263.197.381.31.81.907 1.622 1.81 2.408 2.74.14.187.219.415.223.65.025.884.015 1.771.008 2.657 0 .214.07.336.266.444 1.65.919 2.159 2.768 1.225 4.425-.095.167-.113.272.041.42.522.497 1.028 1.011 1.54 1.522v.076l-.673.621-1.69-1.718c-.65.328-.662.327-.716.985-.045.469-.332.707-.73.868zM9.78 3.96c-.685 0-1.333.007-1.979 0-.433-.006-.592-.17-.595-.604-.006-.646-.017-1.293.005-1.938.01-.322-.068-.446-.416-.443-1.8.016-3.6.013-5.402 0-.305 0-.422.076-.421.402.009 3.754.009 7.506 0 11.256 0 .296.093.393.392.392 2.7-.009 5.402-.003 8.098-.011.111 0 .221-.094.331-.145-.108-.048-.214-.137-.325-.14-1.516-.05-2.847-1.24-3.007-2.714-.181-1.63.815-3.113 2.352-3.478.313-.076.642-.087.997-.133V4.31c0-.099-.017-.197-.03-.35zM7.393 9.554a2.194 2.194 0 104.387.065 2.194 2.194 0 00-4.387-.065zm1.715-6.57l.03-.105-.983-1.11c0 .407-.014.708.01 1.005a.292.292 0 00.205.2c.245.016.492.019.738.01z"></path>
                  <path d="M4.185 1.96h1.522c.415 0 .574.13.58.533.016.924.016 1.848 0 2.773-.005.396-.172.533-.577.533-1.026.003-2.052.003-3.077 0-.387 0-.566-.162-.571-.549-.012-.913-.012-1.826 0-2.74.005-.387.18-.545.568-.55.513-.004 1.032 0 1.555 0zm1.111.947c-.71 0-1.39-.007-2.068.012-.069 0-.186.158-.19.246-.018.48-.017.96 0 1.44 0 .085.127.236.199.238.678.018 1.356.01 2.059.01V2.908z"></path>
                  <path d="M4.07 7.905c-.533 0-1.062-.008-1.593 0-.245.004-.328-.093-.336-.335-.02-.646-.03-.646.626-.646.973 0 1.947.018 2.919-.008.403-.011.415.194.41.491 0 .284.02.527-.397.504-.542-.028-1.087-.006-1.63-.006zM4.124 12.04c-.557 0-1.114-.01-1.674.004-.233.005-.304-.085-.312-.305-.026-.636-.032-.639.589-.639 1 0 2 .018 2.999-.007.456-.012.344.276.365.525.022.281-.022.452-.38.433-.523-.03-1.056-.012-1.587-.012zM5.953 9.035c.04.072.05.082.051.093.096.85.095.852-.76.85-.974 0-1.947-.001-2.92-.006a1.065 1.065 0 01-.171-.045c-.01-.23-.007-.46.01-.69a.29.29 0 01.204-.193c1.198-.012 2.396-.009 3.586-.009z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CaseStudyIcon;
