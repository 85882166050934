import React from "react";
import { Form } from "react-bootstrap";

export const PublishCurrent = ({
  status,
  setStatus,
  isNew,
  currentDate,
  savedCurrentDate,
}) => {
  let publishText;
  if (isNew === false && savedCurrentDate < currentDate) {
    publishText = "Published";
  } else {
    publishText = "Publish Immediately";
  }
  return (
    <Form.Check
      className="d-flex align-items-center"
      name="publishStatus"
      type="radio"
      value="current"
      checked={status === "current"}
      onChange={() => setStatus("current")}
      label={
        <div className="d-flex align-items-center ms-3">
          <p className="mb-0">{publishText}</p>
        </div>
      }
    />
  );
};
