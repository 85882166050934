import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { useAuth0 } from "@auth0/auth0-react";
import { createNewService } from "../../../../api/services";
import { CollectionCreate } from "../../../layouts/CollectionCreate";
import { verifyUrlField } from "../../../../helpers/verifyUrlField";
import { useAlert } from "react-alert";
import { AddMediaComponent } from "../../../components/media/AddMediaComponent";

export const CreateService = () => {
  const [urlValidation, setUrlValidation] = useState(false);
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { user } = useAuth0();
  const [selectedImage, setSelectedImage] = useState([]);
  const [text, setText] = useState("");
  const [input, setInput] = useState({
    title: "",
    urlTitle: "",
    url: "",
  });

  // handle input change
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // create new service
  const { mutate: createMutate } = useMutation(createNewService, {
    onSuccess: (newService) => {
      const data = newService.data.Service;
      queryClient.setQueryData("getServices", (prev) => [...prev, data]);
      alert.show("Your service has successfully been created.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // validate url
  async function validateUrlInput() {
    const verify = await verifyUrlField(input.url);
    setUrlValidation(verify);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    createMutate({
      title: input.title,
      url: input.url,
      description: text,
      image: selectedImage,
      user: user.sub,
      urlTitle: input.urlTitle,
    });
    setText("");
    setInput({
      title: "",
      urlTitle: "",
      url: "",
    });
  }

  return (
    <CollectionCreate title="Create New Service">
      <AddMediaComponent
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        message="Recommended image size is 350px x 525px"
        showImages={true}
      />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Title"
              placeholder="Enter Service Title"
              value={input.title}
              onChange={handleInput}
              name="title"
              required
            />
            <small className="fst-italic text-primary">* Required</small>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="url-title"
              placeholder="Display Text for Link"
              value={input.urlTitle}
              onChange={handleInput}
              name="urlTitle"
              required={input.url}
            />
            {(input.url || input.urlTitle) && (
              <small className="fst-italic text-primary">* Required</small>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Url"
              placeholder="Enter URL of the Related Service"
              value={input.url}
              onBlur={validateUrlInput}
              onChange={handleInput}
              name="url"
              required={input.urlTitle}
            />

            {(input.url || input.urlTitle) && (
              <small className="fst-italic text-primary">{`* Required ${
                !urlValidation ? "URL must include http:// or https://" : ""
              }`}</small>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <ReactQuill
              theme="snow"
              value={text}
              onChange={setText}
              placeholder={"Add Service Description"}
            />
          </Col>
        </Row>
        <div className="mt-5">
          <Button variant="primary" size="lg" type="submit">
            Add Service
          </Button>
        </div>
      </Form>
    </CollectionCreate>
  );
};
