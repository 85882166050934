import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { deleteMedia, editMedia, getMediaAlt } from "../../../../../api/media";
import { imageDimensions } from "../../../../../helpers/imageDimensions";
import { imageSize } from "../../../../../helpers/imageSize";
import { useAlert } from "react-alert";
import { FileInfoAlt } from "./FileInfoAlt";

export const FileInfo = ({
  files,
  currentChecked,
  insertable,
  setSelectedImage,
  reset,
  allSelectedItems,
  selectedImage,
}) => {
  const queryClient = useQueryClient();
  const [fileDimensions, setFileDimensions] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [fileDate, setFileDate] = useState(null);
  const [fileAlt, setFileAlt] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const { user } = useAuth0();
  const alert = useAlert();
  let fileInfo = [];
  const allItems = [];

  // set fileInfo to current checked
  files.forEach((file) => {
    if (file._id === currentChecked) {
      fileInfo = file;
    }
  });

  // match all selected items to the file and save those matched files to array
  allSelectedItems.forEach((item) => {
    files.forEach((file) => {
      if (item === file._id) {
        allItems.push(file);
        console.log("file", file);
      }
    });
  });

  // useEffect(() => {
  //   setAllSelectedItems(
  //     allSelectedItems.splice(allSelectedItems.length - 1, 1, { fileInfo })
  //   );
  // }, [fileAlt]);
  useEffect(() => {
    // set file as image
    if (
      fileInfo.fileType !== "pdf" &&
      fileInfo.fileType !== "mp4" &&
      fileInfo.fileType !== "mov"
    ) {
      imageDimensions(fileInfo.fileUrl[0])
        .then(({ width, height }) => {
          setFileDimensions(width + "px x " + height + "px");
        })
        .catch((errorMessage) => console.log(errorMessage));
      setIsImage(true);
      // setFileAlt(fileInfo.fileAlt);
    } else {
      setFileDimensions(null);
      setIsImage(false);
    }

    // set file as image
    if (fileInfo.fileType === "pdf") {
      setIsPdf(true);
      // setFileAlt(fileInfo.fileAlt);
    } else {
      setIsPdf(false);
    }

    setFileSize(imageSize(fileInfo.fileSize));
    setFileDate(moment(fileInfo.updatedAt).format("MMMM Do YYYY, h:mm:ss a"));
  }, [fileInfo, files]);

  function handleSelectImage() {
    setSelectedImage(allItems);
  }

  // Delete Mutation
  const { mutate: deleteMutation } = useMutation(
    ["deleteMedia", fileInfo._id],
    () => deleteMedia(fileInfo._id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allMedia");
        reset("");
      },
    }
  );

  // Edit Mutation
  const { mutate: editMutation } = useMutation(editMedia, {
    onSuccess: (data) => {
      const newData = data.data.Media;
      // queryClient.setQueryData(["allMedia", { id: fileInfo._id }], newData);
      queryClient.invalidateQueries("allMedia");
      queryClient.setQueryData(["mediaAlt", { id: fileInfo._id }], newData);
      alert.show("Your image alt text has been updated.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  function handleUpdate() {
    editMutation({
      user: user.sub,
      id: fileInfo._id,
      alt: fileAlt,
    });
  }

  function handleDelete() {
    deleteMutation();
  }

  return (
    <div className="rw_media_library_file_info">
      <div className="rw_media_library_file_info_inner">
        <p className="rw_media_library_file_info_title">Attachment Details</p>
        <p className="rw_media_library_file_info_fileName">
          {fileInfo.fileName}
        </p>
        <div className="rw_media_library_file_info_media_info">
          <img
            src={fileInfo.image}
            width={63}
            height={63}
            alt={fileInfo.fileName}
          />
          <div>
            <p className="rw_media_library_file_info_fileData">
              {fileDimensions}
            </p>
            <p className="rw_media_library_file_info_fileData">{fileSize}</p>
            <p className="rw_media_library_file_info_fileData">{fileDate}</p>
          </div>
        </div>
        {isImage && (
          <div className="rw_media_library_file_info_input_group">
            <label htmlFor="alt_text">Alt Text</label>
            <FileInfoAlt
              id={fileInfo._id}
              fileAlt={fileAlt}
              setFileAlt={setFileAlt}
            />
          </div>
        )}
        {isPdf && (
          <div className="rw_media_library_file_info_input_group">
            <label htmlFor="alt_text">File Title</label>
            <FileInfoAlt
              id={fileInfo._id}
              fileAlt={fileAlt}
              setFileAlt={setFileAlt}
            />
          </div>
        )}
        <div className="rw_media_library_file_update">
          {(isImage || isPdf) && (
            <button
              className="rw_media_library_update_btn"
              onClick={handleUpdate}
            >
              Update
            </button>
          )}
          <button
            className="rw_media_library_delete_btn"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
        {insertable ? (
          <div className="rw_media_library_file_insert">
            <button
              className="rw_media_library_file_insert_button"
              onClick={handleSelectImage}
            >
              Select
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
