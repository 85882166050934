import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../partials/global/Header";
import Sidepanel from "../partials/global/Sidepanel";

function Layout(props) {
  const [collapse, setCollapse] = useState(false);
  const { pathname } = useLocation();
  const container = useRef(null);
  const wrapper = useRef(null);
  // useScrollToTop(pathname, container);

  useEffect(() => {
    container.current.scrollIntoView(true);
  }, [pathname]);

  return (
    <>
      <div id="skip" className="visually-hidden" ref={container}>
        <a href="#main-container">Skip to content</a>
      </div>
      <Header />
      <Sidepanel
        collapse={collapse}
        setCollapse={setCollapse}
        wrapper={wrapper}
      />
      <main
        id="main-container"
        className={`${collapse ? "menu-collapsed" : "menu-show"}`}
      >
        <div id="main-wrapper" className="main-wrapper">
          {props.children}
        </div>
      </main>
    </>
  );
}

export default Layout;
