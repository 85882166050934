import * as React from "react";

function PencilIcon(props) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.363 2.157L13.68.58a2.209 2.209 0 00-2.984 0L.778 9.817.01 14.182c-.083.475.356.887.862.808l4.656-.72 9.854-9.298c.823-.771.831-2.018-.02-2.815zm-9.1 6.97a.71.71 0 00.487.19.71.71 0 00.486-.19l3.72-3.487.893.837-5.287 4.988v-1.211h-1.5V8.848H3.77l5.32-4.956.893.837-3.72 3.487a.617.617 0 000 .912zm-3.696 4.295l-.884-.828.38-2.156.575-.536h1.3v1.407h1.5v1.218l-.572.54-2.3.355zM14.41 4.06l-.002.001-.001.001-1.501 1.417-2.751-2.58 1.51-1.406.002-.002.002-.001a.768.768 0 011.038 0l1.683 1.577c.309.29.31.72.02.993z" />
    </svg>
  );
}

export default PencilIcon;
