import React from "react";
import { LoginFooter } from "../screens/login/LoginFooter";

export const LoginLayout = (props) => {
  return (
    <>
      <div id="skip" className="visually-hidden">
        <a href="#main-container">Skip to content</a>
      </div>
      <div id="login-content-wrapper">
        <main id="login-container" className="w-100">
          <div className="login-wrapper">{props.children}</div>
        </main>
      </div>
      <LoginFooter />
    </>
  );
};
