import Datetime from "react-datetime";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import CalendarIcon from "../../../../../../components/assets/CalendarIcon";

function ExpirationDateTimePicker({ setCurrentTime, currentTime }) {
  const handleTimeSet = (val) => {
    setCurrentTime(moment(val));
  };

  return (
    <div className="d-flex align-items-center position-relative">
      <Datetime
        dateFormat={false}
        value={moment(currentTime)}
        timeFormat={true}
        input={true}
        onChange={handleTimeSet}
        // onBlur={() => setOpen(false)}
      />
      <CalendarIcon width="32px" height="32px" />
    </div>
  );

  // return <input ref={dateInput} />;
}

export default ExpirationDateTimePicker;
