import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    if (error.response.status === 401) {
      console.log("401 error");
    } else {
      return Promise.reject(error);
    }
  }
);

// api.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 403 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     // the below function makes an API call to grab a new token / handle refresh token
//     const token = await refreshAccessToken();
//     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     return api(originalRequest);
//   }
//   return Promise.reject(error);
// });

export default api;
