import React from "react";
import { Row, Col, Figure } from "react-bootstrap";
import rwLogo from "../../../../../assets/images/RebelWare-Logo-Black.png";

function ClientInfoPreviewModule() {
  return (
    <div className="frontend-client-information">
      <Row className="align-items-center">
        <Col lg={3} className="client-information-image">
          <Figure>
            <Figure.Image
              width="100%"
              height="auto"
              src={rwLogo}
              alt="Client image"
            ></Figure.Image>
          </Figure>
        </Col>
        <Col className="client-information-details details-border">
          <div>
            <h1>Welcome to Rebel</h1>
            <p>
              Results-driven and led by some of the brightest minds in the
              industry, Rebel Interactive Group gets the job done quickly,
              economically and brilliantly.
            </p>
            <p>
              Creative? Check. Brand strategy? Check. Paid media, SEO, and
              analytics? Check check check. How do we achieve this?
            </p>
            <p> By letting our creative, curious minds do their thing.</p>
            <div className="signature">
              <p>
                <strong>Bryn Tindall</strong>
              </p>
              <p>CEO &amp; Owner</p>
              <p>M: 860-930-1105</p>
              <p>bryn@rebelinteractivegroup.com</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ClientInfoPreviewModule;
