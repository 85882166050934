import * as React from "react";

function LinkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.177778in"
      height="0.177778in"
      viewBox="0 0 16 16"
    >
      <path
        id="Selection"
        fill="none"
        stroke="black"
        strokeWidth="1"
        d="M 7.68,8.32
           C 6.53,7.81 6.67,8.31 5.60,8.45
             4.81,8.55 4.19,8.42 3.52,7.98
             2.02,7.01 0.06,5.00 0.82,3.04
             1.09,2.36 1.73,1.79 2.24,1.28
             2.91,0.61 3.33,0.18 4.32,0.08
             4.82,0.04 5.47,0.16 5.92,0.39
             6.45,0.67 6.94,1.18 7.36,1.60
             8.26,2.50 9.23,3.40 9.10,4.80
             8.98,5.94 8.32,5.94 9.10,7.04
             10.49,5.69 12.27,6.55 13.44,7.68
             14.87,9.07 16.28,10.71 14.68,12.64
             13.60,13.95 12.53,14.99 10.72,14.68
             9.87,14.54 9.23,13.86 8.64,13.28
             7.01,11.66 6.20,10.41 7.68,8.32 Z
           M 7.20,4.96
           C 7.13,4.11 6.99,3.98 6.40,3.36
             6.05,3.00 5.27,2.15 4.80,2.03
             4.01,1.83 2.61,3.24 2.61,4.00
             2.61,4.69 4.05,6.10 4.64,6.40
             4.97,6.56 5.25,6.55 5.60,6.56
             5.36,5.94 4.74,4.49 6.08,4.59
             6.08,4.59 7.20,4.96 7.20,4.96 Z
           M 8.96,9.76
           C 8.68,10.90 9.45,11.41 10.24,12.15
             10.52,12.42 10.94,12.83 11.36,12.84
             11.95,12.85 13.18,11.58 13.34,11.04
             13.54,10.32 12.62,9.57 12.16,9.12
             11.52,8.51 11.31,8.20 10.39,8.32
             10.69,8.91 11.12,9.64 10.39,10.11
             10.17,10.24 10.00,10.27 9.76,10.21
             9.55,10.15 9.16,9.88 8.96,9.76 Z"
      />
    </svg>
  );
}

export default LinkIcon;
