import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation, useQueryClient } from "react-query";
import { addableModules } from "./backend/components";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { AddModuleModal } from "./backend/editor/modals/AddModuleModal";
import SaveTemplateModal from "./backend/editor/modals/SaveTemplateModal";
import { PublishBar } from "./backend/editor/PublishBar";
import { createNewPresentation } from "../../../api/presentations";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { Helmet } from "../../layouts/Helmet";
import { SavePresentationModal } from "./backend/editor/modals/SavePresentationModal";
import { EmailModal } from "../../components/send-email/EmailModal";
import { UnsavedPreviewModal } from "./backend/editor/modals/UnsavedPreviewModal";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

export const CreateFromBlankTemplate = (props) => {
  const [presentationData, setPresentationData] = useState();
  const [moduleData, setModuleData] = useState([]);
  const [clientContact, setClientContact] = useState({
    name: "",
    email: "",
  });
  const [clientData, setClientData] = useState({
    active: true,
    clientImage: [],
    clientNote: "",
    clientSignature: true,
  });
  const [addModuleShow, setAddModuleShow] = useState(false);
  const [saveTemplateShow, setSaveTemplateShow] = useState(false);
  const [addedModules, setAddedModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [componentIds, setComponentIds] = useState(["client"]);
  const [presentationModalShow, setPresentationModalShow] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [presentationID, setPresentationID] = useState();
  const { user } = useAuth0();
  const alert = useAlert();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [unsavedPreviewModalShow, setUnsavedPreviewModalShow] = useState(false);
  const [validation, setValidation] = useState({
    title: true,
  });

  // save and close
  const { mutate: mutateSaveClose, isLoading: saveLoading } = useMutation(
    createNewPresentation,
    {
      onSuccess: (postData) => {
        console.log("postData", postData);
        setPresentationID(postData.presentation);

        if (postData.ok === false) {
          alert.show(postData.error.message, {
            type: "error",
          });
        } else {
          alert.show("Your presentation has successfully been saved.", {
            timeout: 3000,
            type: "success",
          });
        }
        queryClient.invalidateQueries("getPresentations");
        navigate("/");
      },
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // save and continue=
  const { mutate: mutateSaveContinue } = useMutation(createNewPresentation, {
    onSuccess: (postData) => {
      console.log("postData", postData);
      setPresentationID(postData.presentation);

      if (postData.ok === false) {
        alert.show(postData.error.message, {
          type: "error",
        });
      } else {
        alert.show("Your presentation has successfully been saved.", {
          timeout: 3000,
          type: "success",
        });
      }
      queryClient.invalidateQueries("getPresentations");
      navigate(`/presentation/edit/${postData.presentation._id}`);
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // as modules are added, set array to be mapped through and displayed and set IDs to array to be saved
  useEffect(() => {
    addableModules.forEach((item) => {
      if (addedModules === item.id) {
        setFilteredModules((prev) => [...prev, item]);
      }
    });
  }, [addedModules]);

  useEffect(() => {
    const mod = [];
    filteredModules.forEach((obj) => {
      mod.push(obj.id);
    });
    setComponentIds(mod);
    setAddedModules(0);
    // sort module data to replicate componentIds
    setModuleData(
      moduleData.sort(function (a, b) {
        return mod.indexOf(a) - mod.indexOf(b);
      })
    );
  }, [filteredModules]);

  // reorder filtered modules and moduleData arrays on drag and drop
  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(filteredModules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilteredModules(items);
    const modules = Array.from(moduleData);
    const [reorderedModules] = modules.splice(result.source.index, 1);
    modules.splice(result.destination.index, 0, reorderedModules);
    setModuleData(modules);
  }

  function savePresentation() {
    mutateSaveClose({
      mainContact: clientContact,
      clientInfo: clientData,
      status: presentationData.status,
      presentationTitle: presentationData.presentationTitle,
      slug: presentationData.permalink,
      visibility: presentationData.visibility,
      password: presentationData.password,
      publishDate: presentationData.publishDate,
      expirationDate: presentationData.expirationDate,
      layout: componentIds,
      data: moduleData,
      author: user.name,
      user: user.sub,
    });
  }

  function saveContPresentation() {
    mutateSaveContinue({
      mainContact: clientContact,
      clientInfo: clientData,
      status: presentationData.status,
      presentationTitle: presentationData.presentationTitle,
      slug: presentationData.permalink,
      visibility: presentationData.visibility,
      password: presentationData.password,
      publishDate: presentationData.publishDate,
      expirationDate: presentationData.expirationDate,
      layout: componentIds,
      data: moduleData,
      author: user.name,
      user: user.sub,
    });
  }

  return (
    <PresentationEditWrapper title="Create New Presentation">
      <Helmet metaTitle="Blank Template | Spark | RebelWare" />
      <PublishBar
        presentationData={presentationData}
        setValidation={setValidation}
        setUnsavedPreviewModalShow={setUnsavedPreviewModalShow}
        setAddModuleShow={setAddModuleShow}
        setSaveTemplateShow={setSaveTemplateShow}
        setPresentationModalShow={setPresentationModalShow}
        previewPresentation={true}
      />
      <PresentationInformation
        setData={setPresentationData}
        data={presentationData}
        validation={validation}
      />
      <ClientInfoModule
        setData={setClientData}
        data={clientData}
        clientContact={clientContact}
        setClientContact={setClientContact}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="presentation-module-list">
          <Droppable droppableId="existing-presentation">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {filteredModules.map((component, idx) => (
                  <Draggable
                    key={`editor_${idx}`}
                    draggableId={`drag_${idx}`}
                    index={idx}
                  >
                    {(provided) => (
                      <li
                        className="presentation-edit-module"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <component.component
                          setData={setModuleData}
                          data={moduleData}
                          index={idx}
                          draggable={true}
                          setFilteredModules={setFilteredModules}
                          filteredModules={filteredModules}
                          dragHandle={provided.dragHandleProps}
                          name={component.name}
                          componentId={component.id}
                          layout={componentIds}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <AddModuleModal
        show={addModuleShow}
        onHide={() => setAddModuleShow(false)}
        setAddedModules={setAddedModules}
        usedModules={filteredModules}
      />
      <SaveTemplateModal
        template={filteredModules}
        show={saveTemplateShow}
        onHide={() => setSaveTemplateShow(false)}
      />
      <SavePresentationModal
        presentationId={presentationID}
        saveContPresentation={saveContPresentation}
        show={presentationModalShow}
        onHide={() => setPresentationModalShow(false)}
        savePresentation={savePresentation}
        setEmailModalShow={setEmailModalShow}
      />
      <UnsavedPreviewModal
        show={unsavedPreviewModalShow}
        onHide={() => setUnsavedPreviewModalShow(false)}
        presentationData={presentationData}
        moduleData={moduleData}
        componentIds={componentIds}
        clientInfo={clientData}
      />
      {presentationID && (
        <EmailModal
          show={emailModalShow}
          onHide={() => setEmailModalShow(false)}
          presentationId={presentationID}
          loading={saveLoading}
        />
      )}
    </PresentationEditWrapper>
  );
};
