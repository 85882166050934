import React, { useContext } from "react";
import { SearchFilter } from "./SearchFilter";
import { ViewByFilter } from "./ViewByFilter";
import { AppContext } from "../../../../context/AppContext";

export const PresentationFilter = ({
  setSearchInput,
  searchInput,
  filters,
}) => {
  // const { listCount } = useContext(AppContext);
  return (
    <div className="dashboard-filter-wrapper">
      <div className="dashboard-view-by">
        <ViewByFilter filters={filters} />
      </div>
      <div className="dashboard-search">
        <SearchFilter
          setSearchInput={setSearchInput}
          searchInput={searchInput}
        />
      </div>
    </div>
  );
};
