import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoBar from "../../components/assets/LogoBar";

export const LoginFooter = ({ setFooterHeight }) => {
  const date = new Date().getFullYear();

  return (
    <footer id="login-footer" className="content-info">
      <div className="container">
        <div className="d-flex justify-content-start align-items-center flex-column flex-md-row">
          {/* <Logo width={147} height={60} /> */}
          <h4 className="text-white display-3 my-2 text-uppercase">
            <strong>Our Products:</strong>
          </h4>
          <LogoBar width={110} height={45} />

          {/* <Nav>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://rebelware.co" target="_blank">
                Features
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://rebelware.co" target="_blank">
                Pricing
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
          {/* <div className="copyright">
            <p className="text-white mb-0">
              <small>{`Spark by RebelWare © ${date} - All Rights Reserved`}</small>
            </p>
          </div> */}
        </div>
      </div>
    </footer>
  );
};
