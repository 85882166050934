import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { addableModules } from "./backend/components";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { createNewPresentation } from "../../../api/presentations";
import { PublishBar } from "./backend/editor/PublishBar";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { Helmet } from "../../layouts/Helmet";
import { EmailModal } from "../../components/send-email/EmailModal";
import { SavePresentationModal } from "./backend/editor/modals/SavePresentationModal";
import { UnsavedPreviewModal } from "./backend/editor/modals/UnsavedPreviewModal";
import { useAlert } from "react-alert";

export const CreateFromExistingLayout = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const layout = location.state.template.layout;
  const template = location.state.template;
  const [presentationData, setPresentationData] = useState();
  const [moduleData, setModuleData] = useState([]);
  const [presentationModalShow, setPresentationModalShow] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [presentationID, setPresentationID] = useState();
  const [clientData, setClientData] = useState({
    active: true,
    clientImage: null,
    clientNote: "",
    clientSignature: true,
  });
  const [clientContact, setClientContact] = useState({
    name: "",
    email: "",
  });
  const [validation, setValidation] = useState({
    title: true,
  });
  const [filteredModules, setFilteredModules] = useState([]);
  const { user } = useAuth0();
  const navigate = useNavigate();
  const alert = useAlert();
  const [unsavedPreviewModalShow, setUnsavedPreviewModalShow] = useState(false);

  // save presentation
  const { mutate: mutateSaveClose, isLoading: saveLoading } = useMutation(
    createNewPresentation,
    {
      onSuccess: (postData) => {
        setPresentationID(postData.presentation);

        alert.show("Your presentation has successfully been saved.", {
          timeout: 3000,
          type: "success",
        });
        queryClient.invalidateQueries("getPresentations");
        navigate("/");
      },
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // save presentation
  const { mutate: mutateSaveContinue } = useMutation(createNewPresentation, {
    onSuccess: (postData) => {
      setPresentationID(postData.presentation);

      alert.show("Your presentation has successfully been saved.", {
        timeout: 3000,
        type: "success",
      });
      queryClient.invalidateQueries("getPresentations");
      navigate(`/presentation/edit/${postData.presentation._id}`);
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  useEffect(() => {
    template.layout.forEach((pres) => {
      addableModules.forEach((item) => {
        if (pres === item.id) {
          setFilteredModules((prev) => [...prev, item]);
        }
      });
    });
  }, [template]);

  // const componentIds = [];
  // filteredModules.forEach((module) => {
  //   componentIds.push(module.id);
  // });

  useEffect(() => {
    const mod = [];
    filteredModules.forEach((obj) => {
      mod.push(obj.id);
    });
    setModuleData(
      moduleData.sort(function (a, b) {
        return mod.indexOf(a) - mod.indexOf(b);
      })
    );
  }, [filteredModules]);

  function savePresentation() {
    mutateSaveClose({
      additionalContacts: [],
      mainContact: clientContact,
      clientInfo: clientData,
      status: presentationData.status,
      presentationTitle: presentationData.presentationTitle,
      slug: presentationData.permalink,
      visibility: presentationData.visibility,
      password: presentationData.password,
      publishDate: presentationData.publishDate,
      expirationDate: presentationData.expirationDate,
      layout: layout,
      data: moduleData,
      author: user.name,
      user: user.sub,
    });
    return true;
  }

  function saveContPresentation() {
    mutateSaveContinue({
      mainContact: clientContact,
      clientInfo: clientData,
      status: presentationData.status,
      presentationTitle: presentationData.presentationTitle,
      slug: presentationData.permalink,
      visibility: presentationData.visibility,
      password: presentationData.password,
      publishDate: presentationData.publishDate,
      expirationDate: presentationData.expirationDate,
      layout: layout,
      data: moduleData,
      author: user.name,
      user: user.sub,
    });
  }

  return (
    <PresentationEditWrapper title={`New ${template.title}`}>
      <Helmet metaTitle={`${template.title} | Spark | RebelWare`} />
      <PublishBar
        presentationData={presentationData}
        setValidation={setValidation}
        setUnsavedPreviewModalShow={setUnsavedPreviewModalShow}
        setPresentationModalShow={setPresentationModalShow}
        previewPresentation={true}
      />

      <PresentationInformation
        setData={setPresentationData}
        data={presentationData}
        validation={validation}
      />
      <ClientInfoModule
        setData={setClientData}
        data={clientData}
        clientContact={clientContact}
        setClientContact={setClientContact}
      />
      <div className="presentation-module-list">
        <ul>
          {filteredModules.map((component, idx) => (
            <li key={`editor_${idx}`} className="presentation-edit-module">
              <component.component
                setData={setModuleData}
                data={moduleData}
                index={idx}
                draggable={false}
                setFilteredModules={setFilteredModules}
                filteredModules={filteredModules}
                name={component.name}
                componentId={component.id}
                layout={layout}
              />
            </li>
          ))}
        </ul>
      </div>
      <SavePresentationModal
        show={presentationModalShow}
        onHide={() => setPresentationModalShow(false)}
        saveContPresentation={saveContPresentation}
        savePresentation={savePresentation}
        setEmailModalShow={setEmailModalShow}
      />
      <UnsavedPreviewModal
        show={unsavedPreviewModalShow}
        onHide={() => setUnsavedPreviewModalShow(false)}
        presentationData={presentationData}
        moduleData={moduleData}
        componentIds={layout}
        clientInfo={clientData}
      />
      {presentationID && (
        <EmailModal
          show={emailModalShow}
          onHide={() => setEmailModalShow(false)}
          presentationId={presentationID}
          loading={saveLoading}
        />
      )}
    </PresentationEditWrapper>
  );
};
