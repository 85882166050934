import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { DashboardWrapper } from "../../layouts/DashboardWrapper";
import { PresentationFilter } from "./filter/PresentationFilter";
import { Helmet } from "../../layouts/Helmet";
import { useQuery } from "react-query";
import { getPresentations } from "../../../api/presentations";
import { AllPresentations } from "./presentation-list/AllPresentations";
import { ExpiredPresentations } from "./presentation-list/ExpiredPresentations";
import { ScheduledPresentations } from "./presentation-list/ScheduledPresentations";
import { DraftPresentations } from "./presentation-list/DraftPresentations";
import { TrashPresentations } from "./presentation-list/TrashPresentations";
import { AppContext } from "../../../context/AppContext";
import { ActivePresentations } from "./presentation-list/ActivePresentations";
import { Spinner } from "react-bootstrap";
// import { Helmet } from "react-helmet";

export const Dashboard = () => {
  const { currentTab, isLoading, setLoading } = useContext(AppContext);
  const [allPresentations, setAllPresentations] = useState([]);
  const [activePresentations, setActivePresentations] = useState([]);
  const [expiredPresentations, setExpiredPresentations] = useState([]);
  const [scheduledPresentations, setScheduledPresentations] = useState([]);
  const [draftPresentations, setDraftPresentations] = useState([]);
  const [trashPresentations, setTrashPresentations] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const filters = [
    {
      name: "All",
      count: allPresentations.length,
    },
    {
      name: "Active",
      count: activePresentations.length,
    },
    {
      name: "Scheduled",
      count: scheduledPresentations.length,
    },
    {
      name: "Expired",
      count: expiredPresentations.length,
    },
    {
      name: "Drafts",
      count: draftPresentations.length,
    },
    {
      name: "Trash",
      count: trashPresentations.length,
    },
  ];

  // get presentations query
  const {
    data,
    isLoading: presentationLoading,
    isError,
  } = useQuery("getPresentations", getPresentations, {
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (data) {
      // Set All Presentations Array
      setAllPresentations(
        data.filter((presentation) => presentation.status !== "trash")
      );
      // Set Active Presentations Array
      setActivePresentations(
        data.filter(
          (presentation) =>
            presentation.status === "published" &&
            moment(presentation.publishDate) < moment() &&
            (!moment(presentation.expirationDate).isValid() ||
              moment(presentation.expirationDate) > moment())
        )
      );
      // Set Scheduled Presentations Array
      setScheduledPresentations(
        data.filter(
          (presentation) =>
            presentation.status === "published" &&
            moment(presentation.publishDate) > moment()
        )
      );
      // Set Expired Presentations Array
      setExpiredPresentations(
        data.filter(
          (presentation) =>
            presentation.status === "published" &&
            moment(presentation.expirationDate).isValid() &&
            moment(presentation.expirationDate) < moment()
        )
      );
      // Set Draft Presentations Array
      setDraftPresentations(
        data.filter((presentation) => presentation.status === "draft")
      );
      // Set Trash Presentations Array
      setTrashPresentations(
        data.filter((presentation) => presentation.status === "trash")
      );
    }
  }, [data]);

  useEffect(() => {
    if (presentationLoading) {
      setLoading(true);
    }
  }, [presentationLoading]);

  function DisplayList() {
    if (currentTab === "Active") {
      return (
        <ActivePresentations
          data={activePresentations}
          searchInput={searchInput}
        />
      );
    } else if (currentTab === "Scheduled") {
      return (
        <ScheduledPresentations
          data={scheduledPresentations}
          searchInput={searchInput}
        />
      );
    } else if (currentTab === "Expired") {
      return (
        <ExpiredPresentations
          data={expiredPresentations}
          searchInput={searchInput}
        />
      );
    } else if (currentTab === "Drafts") {
      return (
        <DraftPresentations
          data={draftPresentations}
          searchInput={searchInput}
        />
      );
    } else if (currentTab === "Trash") {
      return (
        <TrashPresentations
          data={trashPresentations}
          searchInput={searchInput}
        />
      );
    } else if (currentTab === "All") {
      return (
        <AllPresentations data={allPresentations} searchInput={searchInput} />
      );
    } else {
      return null;
    }
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <>
        <DashboardWrapper title="Dashboard">
          <Helmet metaTitle="Dashboard | Spark | RebelWare" />
          <PresentationFilter
            setSearchInput={setSearchInput}
            searchInput={searchInput}
            filters={filters}
          />
          {!isError && <DisplayList />}
        </DashboardWrapper>
      </>
    );
  }
};
Dashboard.DefaultProps = {
  data: [],
};
