import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

export const ImagesPreviewModule = () => {
  return (
    <ComponentWrapper id="images">
      <Row>
        <Col md={6}>
          <figure>
            <img
              src="https://picsum.photos/500/500"
              width="100%"
              height="auto"
              alt="Case Study"
            />
          </figure>
        </Col>
        <Col md={6}>
          <figure>
            <img
              src="https://picsum.photos/500/500"
              width="100%"
              height="auto"
              alt="Case Study"
            />
          </figure>
        </Col>
      </Row>
    </ComponentWrapper>
  );
};
