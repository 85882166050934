import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { addableModules } from "./backend/components";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { AddModuleModal } from "./backend/editor/modals/AddModuleModal";
import EditTemplateModal from "./backend/editor/modals/EditTemplateModal";
import { PublishBar } from "./backend/editor/PublishBar";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { useLocation } from "react-router-dom";

export const EditPresentationTemplate = (props) => {
  const [presentationData, setPresentationData] = useState();
  const [moduleData, setModuleData] = useState([]);
  const [addModuleShow, setAddModuleShow] = useState(false);
  const [saveTemplateShow, setSaveTemplateShow] = useState(false);
  const [addedModules, setAddedModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [clientData, setClientData] = useState({
    active: true,
    clientImage: null,
    clientNote: "",
    clientSignature: true,
  });
  const [clientContact, setClientContact] = useState({
    name: "",
    email: "",
  });
  const location = useLocation();
  const template = location.state.template;

  // grab initial data from template and match against components to display current template layout
  useEffect(() => {
    template.layout.forEach((pres) => {
      addableModules.forEach((item) => {
        if (pres === item.id) {
          setFilteredModules((prev) => [...prev, item]);
        }
      });
    });
  }, []);

  // as modules are added, set array to be mapped through and displayed and set IDs to array to be saved
  useEffect(() => {
    addableModules.forEach((item) => {
      if (addedModules === item.id) {
        setFilteredModules((prev) => [...prev, item]);
      }
    });
  }, [addedModules]);

  useEffect(() => {
    const mod = [];
    filteredModules.forEach((obj) => {
      mod.push(obj.id);
    });
    // setModuleData(
    //   moduleData.sort(function (a, b) {
    //     return mod.indexOf(a) - mod.indexOf(b);
    //   })
    // );
  }, [filteredModules]);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(filteredModules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilteredModules(items);
  }

  return (
    <PresentationEditWrapper title={`Edit ${template.title} Template`}>
      <PublishBar
        // savePresentation={savePresentation}
        // saveDraftPresentation={saveDraftPresentation}
        setAddModuleShow={setAddModuleShow}
        setSaveTemplateShow={setSaveTemplateShow}
        // send component data here to be published
      />
      <ClientInfoModule
        setData={setClientData}
        data={clientData}
        clientContact={clientContact}
        setClientContact={setClientContact}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="presentation-module-list">
          <Droppable droppableId="existing-presentation">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {filteredModules.map((component, idx) => (
                  <Draggable
                    key={`editor_${idx}`}
                    draggableId={`drag_${idx}`}
                    index={idx}
                  >
                    {(provided) => (
                      <li
                        className="presentation-edit-module"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <component.component
                          setData={setModuleData}
                          data={moduleData}
                          draggable={true}
                          index={idx}
                          setFilteredModules={setFilteredModules}
                          filteredModules={filteredModules}
                          dragHandle={provided.dragHandleProps}
                          name={component.name}
                          componentId={component.id}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <AddModuleModal
        show={addModuleShow}
        onHide={() => setAddModuleShow(false)}
        setAddedModules={setAddedModules}
        usedModules={filteredModules}
      />
      <EditTemplateModal
        templateData={template}
        template={filteredModules}
        show={saveTemplateShow}
        onHide={() => setSaveTemplateShow(false)}
      />
    </PresentationEditWrapper>
  );
};
