import Fuse from "fuse.js";
import React, { useState, useRef } from "react";
import { useQuery } from "react-query";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { getCaseStudies } from "../../../../../../api/caseStudies";
import placeholder from "../../../../../../assets/images/500x500-image-placeholder.jpg";
import XIcon from "../../../../../components/assets/XIcon";

export const CaseStudySelect = ({ setSelected, selectId, selected }) => {
  const { data, isLoading, isError } = useQuery(
    "getCaseStudies",
    getCaseStudies,
    {
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  const inputEl = useRef(null);
  const [searched, setSearched] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  function handleSelect(selectedItem) {
    // set selected items to an array
    setSelected((prev) => [...prev, selectedItem]);
    inputEl.current.blur();
    setShowDropdown(false);
  }

  function handleBlur(e) {
    setSearched("");
    setShowDropdown(false);
  }

  function handleSearchClear() {
    setSearched("");
    setShowDropdown(false);
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading case studies</h4>;
  } else {
    const fuse = new Fuse(data, {
      keys: ["url", "company", "title"],
      threshold: 0.25,
    });

    function handleSearch({ currentTarget = {} }) {
      const { value } = currentTarget;
      setSearched(value);
    }

    const results = fuse.search(searched);
    const match = searched ? results.map((result) => result.item) : data;

    return (
      <div className="editor-select">
        <div className="rw-searchbar-container">
          <Form.Control
            className="rw-searchbar"
            type="text"
            ref={inputEl}
            value={searched}
            placeholder="Search Case Studies"
            onChange={handleSearch}
            onFocus={() => setShowDropdown(true)}
            onBlur={handleBlur}
          />
          {searched && (
            <button
              className="rw-searchbar-btn transparent"
              onClick={handleSearchClear}
            >
              <XIcon width="16px" height="16px" />
              <span className="visually-hidden">Clear search</span>
            </button>
          )}
        </div>
        <Dropdown.Menu
          className="editor-select-dropdown"
          show={showDropdown}
          onMouseDown={(e) => e.preventDefault()}
          flip={false}
          rootCloseEvent="click"
        >
          {match.length ? (
            match.map((opt, idx) => (
              <Dropdown.Item
                className="editor-select-dropdown-item"
                key={`${selectId}-${idx}`}
                onClick={() => handleSelect(opt)}
                disabled={selected.some(
                  (item) =>
                    String(item._id + selectId) === String(opt._id + selectId)
                )}
              >
                <div className="editor-select-dropdown-item-image">
                  <img
                    src={
                      opt.image.length ? opt.image[0].fileUrl[1] : placeholder
                    }
                    alt={
                      opt.image.length
                        ? opt.image[0].fileAlt
                        : "placeholder-image"
                    }
                    width={40}
                    height={40}
                  />
                </div>
                <div className="editor-select-dropdown-item-name">
                  <p>{opt.title}</p>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item className="editor-select-dropdown-item">
              <div className="editor-select-dropdown-item-name">
                <p>No Results Found</p>
              </div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </div>
    );
  }
};
