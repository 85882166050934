import React from "react";
import { Form } from "react-bootstrap";
import ReorderIcon from "../../../../components/assets/ReorderIcon";
import TrashIcon from "../../../../components/assets/TrashIcon";

export const EditBar = ({
  draggable,
  active,
  data,
  setData,
  setActive,
  dragHandle,
  index,
  setFilteredModules,
  filteredModules,
}) => {
  function handleRemoveModule() {
    const temp = [...filteredModules];
    const tempData = [...data];

    temp.splice(index, 1);
    tempData.splice(index, 1);

    // updating the list
    setData(tempData);
    setFilteredModules(temp);
  }
  return (
    <div className="edit-bar">
      <Form.Check
        type="switch"
        id="custom-switch"
        checked={active}
        onChange={() => setActive(!active)}
      />
      {setFilteredModules && (
        <div className="delete-icon">
          <button className="transparent" onClick={handleRemoveModule}>
            <TrashIcon height="16px" width="16px" />
          </button>
        </div>
      )}
      {draggable && (
        <div className="reorder-icon" {...dragHandle}>
          <ReorderIcon height="16px" width="16px" />
        </div>
      )}
    </div>
  );
};
