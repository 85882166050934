import React from "react";
import { DeleteCollectionButton } from "./DeleteCollectionButton";
import { EditCollectionButton } from "./EditCollectionButton";
import { Dropdown } from "../Dropdown";

export const CollectionButtonPopover = ({
  setDeleteObj,
  path,
  setShowModal,
  data,
}) => {
  return (
    <Dropdown>
      <div className="collection-list-buttons">
        <EditCollectionButton path={path} />
        <DeleteCollectionButton
          setDeleteObj={setDeleteObj}
          setShowModal={setShowModal}
          data={data}
        />
      </div>
    </Dropdown>
  );
};
