import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { createNewTestimonial } from "../../../../api/testimonials";
import { CollectionCreate } from "../../../layouts/CollectionCreate";
import { useAlert } from "react-alert";
import { AddMediaComponent } from "../../../components/media/AddMediaComponent";

export const CreateTestimonial = () => {
  const { user } = useAuth0();
  const alert = useAlert();
  const [selectedImage, setSelectedImage] = useState([]);
  const queryClient = useQueryClient();
  const [text, setText] = useState("");
  const [input, setInput] = useState({
    title: "",
    name: "",
    company: "",
  });

  // handle input change
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // create new testimonial
  const { mutate } = useMutation(createNewTestimonial, {
    onSuccess: (newTestimonial) => {
      const data = newTestimonial.data.Testimonial;
      queryClient.setQueryData("getTestimonials", (prev) => [...prev, data]);
      alert.show("Your testimonial has successfully been created.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
    if (text.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      alert.show("Please enter a testimonial.", {
        type: "error",
      });
    } else {
      mutate({
        name: input.name,
        title: input.title,
        image: selectedImage,
        company: input.company,
        testimonial: text,
        user: user.sub,
      });
      setInput({
        title: "",
        name: "",
        company: "",
      });
      setText("");
    }
  }

  return (
    <CollectionCreate title="Create New Testimonial">
      <AddMediaComponent
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        message="Recommended image size is 350px x 525px"
        showImages={true}
      />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Title"
              placeholder="Enter Title"
              value={input.title}
              onChange={handleInput}
              name="title"
              required
            />
            <small className="fst-italic text-primary">* Required</small>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Name"
              placeholder="Enter Name"
              value={input.name}
              onChange={handleInput}
              name="name"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="text"
              placeholder="Enter Company"
              value={input.company}
              onChange={handleInput}
              name="company"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <ReactQuill theme="snow" value={text} onChange={setText} />
            <small className="fst-italic text-primary">* Required</small>
          </Col>
        </Row>
        <div className="mt-5">
          <Button variant="primary" size="lg" type="submit">
            Add Testimonial
          </Button>
        </div>
      </Form>
    </CollectionCreate>
  );
};
