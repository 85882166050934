import React from "react";
import { Carousel } from "react-bootstrap";
import QuoteIcon from "../../../../components/assets/QuoteIcon";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

function TestimonialsPreviewModule() {
  return (
    <ComponentWrapper id="testimonials" title="Testimonials">
      <Carousel>
        <Carousel.Item
          className="slide-item"
          id="testimonial-1"
          index="testimonial-1"
        >
          <div className="slide-container">
            <QuoteIcon width="2.5rem" height="2.5rem" />

            <div className="testimonial-testimonial text-white">
              <p>
                {`I am over the moon that I got in touch with Rebel. David was
                simply the best brand manager I could have asked for. He just
                knew what was correct.`}
              </p>
            </div>
            <p className="testimonial-citation">Peter Gibbons</p>
            <small className="testimonial-citation">Initech</small>
          </div>
        </Carousel.Item>
        <Carousel.Item
          className="slide-item"
          id="testimonial-1"
          index="testimonial-1"
        >
          <div className="slide-container">
            <QuoteIcon width="2.5rem" height="2.5rem" />

            <div className="testimonial-testimonial text-white">
              <p>
                {`It's been incredible watching Stevie apply her expertise to our
                business. Rebel and Nova? I love this journey for us.`}
              </p>
            </div>
            <p className="testimonial-citation">Stephanie Speck</p>
            <small className="testimonial-citation">NOVA Laboratory</small>
          </div>
        </Carousel.Item>
      </Carousel>
    </ComponentWrapper>
  );
}

export default TestimonialsPreviewModule;
