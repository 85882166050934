import React, { useEffect, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import CaseStudyIcon from "../../components/assets/CaseStudyIcon";
import GearIcon from "../../components/assets/GearIcon";
import HomeIcon from "../../components/assets/HomeIcon";
import LeftIcon from "../../components/assets/LeftIcon";
import MediaIcon from "../../components/assets/MediaIcon";
import PlusIcon from "../../components/assets/PlusIcon";
import TeamIcon from "../../components/assets/TeamIcon";
import TestimonialIcon from "../../components/assets/TestimonialIcon";

function MainNavigation({ collapse, setCollapse }) {
  const [active, setActive] = useState("");

  const url = window.location.pathname;
  const currentUrl = url.split("/")[1];

  useEffect(() => {
    if (currentUrl === "") {
      setActive("dashboard");
    } else {
      document.querySelectorAll("#main-navigation .nav-link").forEach((el) => {
        el.classList.remove("active");
        const currentelhref = el.href.split("/");
        if (currentUrl === currentelhref[currentelhref.length - 1]) {
          setActive(currentUrl);
        }
      });
    }
  }, [window.location]);

  function handleCreate() {
    document.querySelectorAll("#main-navigation .nav-link").forEach((el) => {
      el.classList.remove("active");
    });
  }

  return (
    <Nav
      id="main-navigation"
      activeKey={active}
      onSelect={(selectedKey) => setActive(selectedKey)}
    >
      <Link
        onClick={handleCreate}
        to="/presentation-template"
        className="create-new-link"
      >
        <span className="btn-span">New Presentation</span>
        <PlusIcon width="16px" height="16px" fill="#fff" />
      </Link>
      <Button
        className="menu-control"
        variant="dark"
        onClick={() => setCollapse(!collapse)}
      >
        <span className="btn-span">Menu</span>
        <LeftIcon width="12px" height="12px" />
      </Button>
      <Nav.Link as={Link} eventKey="dashboard" to="/">
        <HomeIcon width="16px" height="16px" fill="#808080" />
        <div className="span">Dashboard</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/team-members" eventKey="team-members">
        <TeamIcon width="16px" height="16px" fill="#808080" />
        <div className="span">Team Members</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/testimonials" eventKey="testimonials">
        <TestimonialIcon width="16px" height="16px" fill="#808080" />
        <div className="span">Testimonials</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/case-studies" eventKey="case-studies">
        <CaseStudyIcon width="16px" height="16px" fill="#808080" />
        <div className="span">Case Studies</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/services" eventKey="services">
        <GearIcon width="16px" height="16px" fill="#808080" />
        <div className="span">Services</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/media" eventKey="media">
        <MediaIcon width="16px" height="16px" fill="#808080" />
        <div className="span">Media Library</div>
      </Nav.Link>
    </Nav>
  );
}

export default MainNavigation;
