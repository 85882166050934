import * as React from "react";

const ReorderIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.9 22.9" {...props}>
    <g>
      <g>
        <path
          d="M11.07 12H1.89l.21.23L4.85 15a1.24 1.24 0 01.19.21.47.47 0 01-.65.64.52.52 0 01-.11-.1L.2 11.66l-.1-.11.3-.31q1.92-1.93 3.85-3.85a.84.84 0 01.24-.18.46.46 0 01.54.7l-.17.18-2.75 2.75a.6.6 0 00-.18.23h9.15V1.89a2.28 2.28 0 00-.22.21L8.05 4.9a.76.76 0 01-.46.2.45.45 0 01-.34-.7.75.75 0 01.12-.14l4-4 .18-.16.31.29c1.29 1.28 2.57 2.61 3.85 3.85a.8.8 0 01.15.17.47.47 0 01-.65.63 1.24 1.24 0 01-.21-.19c-.92-.91-1.83-1.85-2.75-2.75L12 1.89v9.19h9.18l-.18-.21L18.18 8a.48.48 0 01-.18-.54.46.46 0 01.66-.23l.14.11 4.11 4.12.08.1-.24.25-3.9 3.91-.09.08a.47.47 0 01-.65 0 .48.48 0 010-.65l.16-.16L21 12.25a2.51 2.51 0 00.21-.23H12v9.19l.21-.19c.93-.94 1.86-1.88 2.81-2.81a.72.72 0 01.38-.21.45.45 0 01.45.65 1.49 1.49 0 01-.15.2l-4 4-.2.18-.37-.35-3.73-3.81a.74.74 0 01-.12-.13.46.46 0 010-.6.48.48 0 01.63-.07 1.45 1.45 0 01.18.17L10.85 21c.06.06.11.14.17.21h.05z"
          transform="translate(-.1 -.1)"
        ></path>
      </g>
    </g>
  </svg>
);

export default ReorderIcon;
