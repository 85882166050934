import React, { useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import TemplatePreviewModal from "./backend/preview/modals/TemplatePreviewModal";
import {
  getPresentationTemplates,
  deletePresentationTemplate,
} from "../../../api/presentationTemplates";
import EyeIcon from "../../components/assets/EyeIcon";
import PencilIcon from "../../components/assets/PencilIcon";
import TrashIcon from "../../components/assets/TrashIcon";
import { Helmet } from "../../layouts/Helmet";
import { useAlert } from "react-alert";

function PresentationTemplateSelect() {
  let currentID = "";
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [previewModuleShow, setPreviewModuleShow] = useState(false);
  const [templateArr, setTemplateArr] = useState([]);
  const [templateTitle, setTemplateTitle] = useState("");
  const alert = useAlert();

  // get templates
  const { data, isLoading, isError } = useQuery(
    "getPresentationTemplates",
    getPresentationTemplates,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // delete selected template
  const { mutate } = useMutation(
    ["deleteTemplate", currentID],
    () => deletePresentationTemplate(currentID),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPresentationTemplates");
        alert.show("Your template has successfully been deleted", {
          timeout: 3000,
          type: "success",
        });
      },
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  function handleModal(t) {
    setTemplateArr(t.layout);
    setTemplateTitle(t.title);
    setPreviewModuleShow(true);
  }

  function handleLoadTemplate(template) {
    navigate(`/presentation/create/${template._id}`, { state: { template } });
  }

  function handleNewTemplate() {
    navigate("/presentation/create/");
  }

  function handleEditTemplate(template) {
    navigate(`/presentation-template/edit/${template._id}`, {
      state: { template },
    });
  }

  function handleDeleteTemplate(id) {
    currentID = id;
    mutate();
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading templates</h4>;
  } else {
    return (
      <div className="presentation-template">
        <Helmet metaTitle="Create Presentation | Spark | RebelWare" />
        <div className="screen-header">
          <h1>Spark your sales process. Create a Presentation.</h1>
        </div>
        <div className="row">
          <div className="col-lg-4 mb-4">
            <Card className="presentation-template-card template-blank">
              <div>
                <h2>Blank Template</h2>
                <p>
                  {`A clean slate. A fresh start. Call it whatever you want—it's your chance to set the world on fire with your custom presentation.`}
                </p>
              </div>
              <Button variant="primary" onClick={() => handleNewTemplate()}>
                Create
              </Button>
            </Card>
          </div>
          {data
            .sort((a, b) => a.createdAt - b.createdAt)
            .map((template) => (
              <div className="col-lg-4 mb-4" key={template._id}>
                <Card
                  className={`presentation-template-card  template-${template.label}`}
                >
                  <div>
                    <h2>{template.title}</h2>
                    <p>{template.description}</p>
                  </div>
                  <div>
                    <div className="edit-icons">
                      <button
                        className="transparent"
                        onClick={() => handleModal(template)}
                      >
                        <EyeIcon width="16px" height="16px" />
                      </button>
                      <button
                        className="transparent"
                        onClick={() => handleEditTemplate(template)}
                      >
                        <PencilIcon width="16px" height="16px" />
                      </button>
                      <button
                        className="transparent"
                        onClick={() => handleDeleteTemplate(template._id)}
                      >
                        <TrashIcon width="16px" height="16px" />
                      </button>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => handleLoadTemplate(template)}
                    >
                      Create
                    </Button>
                  </div>
                </Card>
              </div>
            ))}
        </div>
        <TemplatePreviewModal
          show={previewModuleShow}
          onHide={() => setPreviewModuleShow(false)}
          templateData={templateArr}
          templateTitle={templateTitle}
        />
      </div>
    );
  }
}

export default PresentationTemplateSelect;
