import dompurify from "dompurify";
import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getTestimonials,
  deleteTestimonial,
} from "../../../../api/testimonials";
import { CollectionButtonPopover } from "../../../components/collection-list-buttons/CollectionButtonPopover";
import { CollectionList } from "../../../layouts/CollectionList";
import { TestimonialListItem } from "./TestimonialListItem";

export const ListTestimonials = ({
  setDeleteTestimonialObj,
  setShowModal,
  data,
  isLoading,
  isError,
}) => {
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return null;
  } else {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    console.log("testimonials data", data);
    return (
      <CollectionList
        title="View / Edit Testimonials"
        loading={isLoading}
        error={isError}
      >
        {data.map((testimonial) => (
          <Fragment key={testimonial._id}>
            <TestimonialListItem
              testimonial={testimonial}
              setDeleteTestimonialObj={setDeleteTestimonialObj}
              setShowModal={setShowModal}
            />
          </Fragment>
        ))}
      </CollectionList>
    );
  }
};
