import React from "react";
import { Modal } from "react-bootstrap";
import { PresentationPreview } from "../../../preview/PresentationPreview";

export const UnsavedPreviewModal = ({
  show,
  onHide,
  presentationData,
  moduleData,
  componentIds,
  clientInfo,
}) => {
  function handleClose() {
    const noHashURL = window.location.href.replace(/#.*$/, "");
    window.history.replaceState("", document.title, noHashURL);
    onHide(false);
  }

  if (!show) {
    return null;
  } else {
    const presentationPreview = {
      presentationTitle: presentationData.presentationTitle,
      data: moduleData,
      layout: componentIds,
      clientInfo: clientInfo,
    };

    return (
      <Modal
        onHide={handleClose}
        show={show}
        className="spark-modal"
        aria-labelledby="presentation-preview"
        size="xl"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Preview Presentation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-background">
          <PresentationPreview presentationData={presentationPreview} />
        </Modal.Body>
      </Modal>
    );
  }
};
