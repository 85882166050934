import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EditBar } from "./EditBar";
import TrashIcon from "../../../../components/assets/TrashIcon";
import { AddVideoComponent } from "../../../../components/media/AddVideoComponent";
import ReorderIcon from "../../../../components/assets/ReorderIcon";
import PlusIcon from "../../../../components/assets/PlusIcon";
import { verifyVideoLinks } from "../../../../../helpers/verifyVideoLinks";

export const VideoModule = ({
  name,
  draggable,
  dragHandle,
  index,
  setData,
  data,
  componentId,
  initialData,
  filteredModules,
  setFilteredModules,
}) => {
  const [selected, setSelected] = useState([]);
  const [active, setActive] = useState(true);
  const [videoArray, setVideoArray] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [verified, setVerified] = useState([]);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(videoArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setVideoArray(items);
  }

  useEffect(() => {
    selected.forEach((sel) => {
      setVideoArray((prev) => [...prev, sel]);
    });
  }, [selected]);

  function handleDeleteSelect(idx) {
    setVideoArray(videoArray.filter((item, i) => i !== idx));
  }

  useEffect(() => {
    const tempData = [...data];
    tempData[index] = {
      id: componentId,
      active: active,
      videos: videoArray,
      videoLink: verified,
    };
    setData(tempData);
  }, [active, videoArray, verified]);

  useEffect(() => {
    if (initialData && initialData[index]) {
      setVideoArray(initialData[index].videos);
      setActive(initialData[index].active);
      setInputList(initialData[index].videoLink);
      setVerified(initialData[index].videoLink);
    }
    if (data && data[index]) {
      setVideoArray(data[index].videos);
      setActive(data[index].active);
      setInputList(data[index].videoLink);
      setVerified(data[index].videoLink);
    }
  }, []);

  console.log("verified", verified);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index].verified = false;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setVerified(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { url: "", verified: "", embed: "" }]);
  };

  // Verify Url

  const handleVerify = async (url, index) => {
    const list = [...inputList];
    //
    const verify = await verifyVideoLinks(url);
    list[index].verified = verify.verified;
    list[index].embed = verify.embed;
    if (verify.verified === true) {
      setVerified(list);
    }
  };

  // if edit, set the initial data from query
  // useEffect(() => {
  //   setInputList([{ url: "", verified: false, embed: "" }]);
  //   if (initialData) {
  //     setVideoArray(initialData.componentId.videos);
  //     setActive(initialData.componentId.active);
  //   } else if (data[index]) {
  //     setVideoArray(data[index].videos);
  //   }
  // }, [initialData, data]);

  return (
    <Card className="component-card editor-module video-editor-module">
      <EditBar
        data={data}
        setData={setData}
        draggable={draggable}
        index={index}
        active={active}
        setActive={setActive}
        dragHandle={dragHandle}
        setFilteredModules={setFilteredModules}
        filteredModules={filteredModules}
        componentId={componentId}
      />
      <h2 className="mb-3">{name}</h2>
      <p className="editor-module-field-label">Video Select</p>
      <AddVideoComponent
        setSelectedVideo={setSelected}
        selectedVideo={selected}
        multiple={true}
        showVideo={false}
      />
      <div className="editor-module-selected-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="videos">
            {(provided) => (
              <ul
                className="editor-module-selected-list-inner"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {videoArray &&
                  videoArray.map((doc, idx) => (
                    <Draggable
                      key={idx}
                      draggableId={`videos-inner_${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <li
                          className="editor-module-selected-list-inner-item"
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <div className="d-flex align-items-center">
                            <div className="editor-module-selected-list-item-name">
                              <p>{doc.fileName}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="transparent me-3"
                              onClick={() => handleDeleteSelect(idx)}
                            >
                              <TrashIcon height="16px" width="16px" />
                            </button>
                            <div
                              className="reorder-icon"
                              {...provided.dragHandleProps}
                            >
                              <ReorderIcon height="16px" width="16px" />
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Row className="py-3">
        <h4 className="mb-2">External Videos</h4>
        <small>
          Please verify before saving. Invalid links will not be saved.
        </small>
      </Row>
      {inputList.length > 0 &&
        inputList.map((x, i) => (
          <Row
            key={`additional-contacts-${i}`}
            className="mb-3 align-items-center"
          >
            <Col lg={10}>
              <Form.Control
                isValid={x.verified}
                isInvalid={x.verified === false}
                name="url"
                type="url"
                placeholder="Embed URL"
                value={x.url}
                onChange={(e) => handleInputChange(e, i)}
                required
              />
            </Col>
            <Col
              lg={2}
              className="d-flex justify-content-between align-items-center"
            >
              <Button
                variant={x.verified ? "outline-success" : "outline-primary"}
                disabled={x.verified}
                onClick={() => handleVerify(x.url, i)}
              >
                {x.verified === true ? "verified" : "verify"}
              </Button>
              <div>
                {inputList.length !== 0 && (
                  <button
                    className="transparent me-3"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <TrashIcon height="16px" width="16px" />
                  </button>
                )}
                <button className="transparent" onClick={handleAddClick}>
                  <PlusIcon height="16px" width="16px" />
                </button>
              </div>
            </Col>
          </Row>
        ))}
      {!inputList.length && (
        <Row className="mb-3 justify-content-end">
          <Col lg={12}>
            <Button variant="outline-dark" onClick={handleAddClick}>
              Add Video Embed
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};
