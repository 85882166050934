import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import { getTeamMember, editTeamMember } from "../../../api/teamMembers";
import { AddMediaComponent } from "../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../layouts/CollectionCreate";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import { Helmet } from "../../layouts/Helmet";
import { useAlert } from "react-alert";
import { verifyUrlField } from "../../../helpers/verifyUrlField";
import { IMaskInput } from "react-imask";

export const EditTeamMember = () => {
  const { user } = useAuth0();
  const [selectedImage, setSelectedImage] = useState([]);
  const [urlValidation, setUrlValidation] = useState(false);
  const [bio, setBio] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [inputValue, setInputValue] = useState({
    title: "",
    name: "",
    email: "",
    phone: "",
    linkedIn: "",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/");
  const teamMemberID = path[path.length - 1];
  const alert = useAlert();

  // set phone input masks
  const phoneMask = "(000)000-0000";

  // get TeamMember query
  const { data, isLoading, isError } = useQuery(
    ["getTeamMember", teamMemberID],
    () => getTeamMember(teamMemberID),
    {
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // edit TeamMember mutation
  const { mutate } = useMutation(editTeamMember, {
    onSuccess: () => {
      queryClient.invalidateQueries("getTeamMembers");
      alert.show("Your service has successfully been edited.", {
        timeout: 3000,
        type: "success",
      });
      navigate("/team-members");
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // set values on load
  useEffect(() => {
    if (data) {
      setSelectedImage(data[0].image);
      setOriginalName(data[0].name);
      setInputValue({
        title: data[0].title,
        name: data[0].name,
        email: data[0].email,
        phone: data[0].phone,
        linkedIn: data[0].linkedIn,
      });
      if (data[0].bio) {
        setBio(data[0].bio);
      }
    }
  }, [data]);

  // update inputs
  function handleInput(e) {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  // verify url
  async function validateUrlInput() {
    const verify = await verifyUrlField(inputValue.linkedIn);
    setUrlValidation(verify);
  }

  function handleSubmit(e) {
    e.preventDefault();
    mutate({
      id: teamMemberID,
      image: selectedImage,
      title: inputValue.title,
      name: inputValue.name,
      email: inputValue.email,
      phone: inputValue.phone,
      linkedIn: inputValue.linkedIn,
      bio: bio,
      user: user.sub,
    });
  }
  if (isLoading || isError) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <ScreenWrapper importIcon={false} screenTitle={`Edit ${originalName}`}>
        <Helmet metaTitle="Edit Team Member | Spark | RebelWare" />

        <CollectionCreate>
          <AddMediaComponent
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            message="Recommended image size is 350px x 525px"
            showImages={true}
          />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Name"
                  placeholder="Enter Name"
                  value={inputValue.name}
                  onChange={handleInput}
                  name="name"
                  required
                />
                <small className="fst-italic text-primary">* Required</small>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Title"
                  placeholder="Enter Title"
                  value={inputValue.title}
                  onChange={handleInput}
                  name="title"
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="email"
                  className="form-control"
                  aria-label="email"
                  placeholder="Enter Email"
                  value={inputValue.email}
                  onChange={handleInput}
                  name="email"
                  required
                />
                <small className="fst-italic text-primary">* Required</small>
              </Col>
              <Col md={6} className="mb-3">
                <IMaskInput
                  type="tel"
                  className="form-control"
                  aria-label="phone"
                  mask={phoneMask}
                  value={inputValue.phone}
                  onChange={handleInput}
                  placeholder="Enter Phone Number (XXX)XXX-XXXX"
                  name="phone"
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="url"
                  className="form-control"
                  aria-label="linkedIn"
                  placeholder="Enter LinkedIn URL"
                  value={inputValue.linkedIn}
                  onBlur={validateUrlInput}
                  onChange={handleInput}
                  name="linkedIn"
                />
                {inputValue.linkedIn && (
                  <small className="fst-italic text-primary">
                    {`${
                      !urlValidation
                        ? "URL must include http:// or https://"
                        : ""
                    }`}
                  </small>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <ReactQuill theme="snow" value={bio} onChange={setBio} />
              </Col>
            </Row>
            <div className="mt-5 d-flex justify-content-between">
              <Button
                as={Link}
                to="/team-members"
                variant="secondary"
                size="lg"
              >
                Cancel
              </Button>
              <Button variant="primary" size="lg" type="submit">
                Save Changes
              </Button>
            </div>
          </Form>
        </CollectionCreate>
      </ScreenWrapper>
    );
  }
};
