export const imageDimensions = (url, rejectTimeout) =>
  new Promise((resolve, reject) => {
    let timer = null;

    const img = new Image();

    img.addEventListener("load", () => {
      if (timer) {
        clearTimeout(timer);
      }

      resolve(img);
    });

    img.addEventListener("error", (event) => {
      if (timer) {
        clearTimeout(timer);
      }

      reject(new Error(event.message));
    });

    img.src = url;

    if (rejectTimeout) {
      timer = setTimeout(() => reject(new Error("Timeout exception")));
    }
  });
