import React from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

export const SavePresentationModal = ({
  presentationId,
  saveContPresentation,
  savePresentation,
  presentationData,
  setEmailModalShow,
  isSuccess,
  ...props
}) => {
  let isLive = false;
  const date = moment();
  if (presentationData) {
    const expiration = moment(presentationData.expirationDate);
    const publish = moment(presentationData.publishDate);
    if (
      presentationData.status === "published" &&
      (!expiration.isValid() || expiration > date) &&
      publish.format() < date.format()
    ) {
      isLive = true;
    } else {
      isLive = false;
    }
  }

  const saveAndSend = async () => {
    await savePresentation();
    props.onHide();
    setEmailModalShow(true);
  };

  const saveAndContinue = async () => {
    saveContPresentation();
    props.onHide();
  };

  const saveAndClose = async () => {
    await savePresentation();
    props.onHide();
  };

  return (
    <Modal
      {...props}
      className="spark-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Save Presentation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ready To Save Your Presentation?</p>
      </Modal.Body>
      <Modal.Footer>
        {isLive && (
          <Button variant="tertiary" onClick={() => saveAndSend()}>
            Save &amp; Send
          </Button>
        )}
        <Button variant="secondary" onClick={() => saveAndContinue()}>
          Save &amp; Continue
        </Button>
        <Button variant="dark" onClick={() => saveAndClose()}>
          Save &amp; Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
