import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ExpirationDatePicker from "./ExpirationDatePicker";
import ExpirationDateTimePicker from "./ExpirationDateTimePicker";

export const ExpirationSelect = ({
  status,
  setStatus,
  publishDate,
  publishStatus,
  savedExpirationDate,
  setNewExpirationDate,
  setExpirationDate,
}) => {
  const [currentDate, setCurrentDate] = useState();
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    setCurrentTime(moment(savedExpirationDate));
    setCurrentDate(moment(savedExpirationDate));
  }, [savedExpirationDate]);

  useEffect(() => {
    const time = moment(currentTime).format("LT");
    const date = moment(currentDate).format("L");
    const dateTime = moment(date + " " + time, "MM/DD/YYYY h:mm a");
    setNewExpirationDate(moment(dateTime).format());

    // if (dateTime.format() >= moment().format()) {
    //   setStatus("scheduled");
    // }
  }, [currentDate, currentTime]);

  useEffect(() => {
    if (publishStatus === "draft") {
      setCurrentTime(moment());
      setCurrentDate(moment());
    }
  }, [publishStatus]);

  useEffect(() => {
    if (status === "never") {
      setCurrentTime(moment());
      setCurrentDate(moment());
      setExpirationDate(null);
    }
  }, [status]);

  function ShowExpirationPicker() {
    if (status === "expires") {
      return (
        <div
          className={`d-flex align-items-md-center flex-column flex-md-row `}
        >
          <p className="mx-3 mb-0">Date:</p>
          <ExpirationDatePicker
            publishDate={publishDate}
            setCurrentDate={setCurrentDate}
            setCurrentTime={setCurrentTime}
            currentDate={currentDate}
          />

          <p className="mx-3 mb-0">Time:</p>
          <ExpirationDateTimePicker
            setCurrentTime={setCurrentTime}
            currentTime={currentTime}
            // currentDate={currentDate}
            // expirationDate={expirationDate}
            publishDate={publishDate}
          />
        </div>
      );
    } else {
      return null;
    }
  }
  return (
    <div className="d-flex flex-column flex-lg-row ">
      <Form.Check
        className="d-flex align-items-center form-check-fh"
        name="expirationStatus"
        type="radio"
        value="expires"
        disabled={publishStatus === "draft"}
        onChange={() => setStatus("expires")}
        checked={status === "expires"}
        label={
          <div className="d-flex align-items-center ms-3">
            {status !== "expires" ? (
              <p className="mb-0">Set Expiration</p>
            ) : (
              <p className="mb-0">Expires On</p>
            )}
          </div>
        }
      />
      <ShowExpirationPicker />
    </div>
  );
};
