import React from "react";
import { Link } from "react-router-dom";
import PencilIcon from "../assets/PencilIcon";

export const EditCollectionButton = ({ id, path }) => {
  return (
    <Link to={path}>
      <div className="d-flex align-items-center">
        <PencilIcon width="16px" height="16px" />
        <p>Edit</p>
      </div>
    </Link>
  );
};
