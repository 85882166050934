import React from "react";

function TestimonialIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="gray" fillRule="nonzero" transform="translate(-31 -348)">
          <g transform="translate(0 107)">
            <g transform="translate(0 55) translate(28 75)">
              <g transform="translate(0 56)">
                <g transform="translate(3 55)">
                  <path d="M.459 14H.383c-.212-.134-.25-.295-.167-.544.301-.91.566-1.836.865-2.746a.897.897 0 00-.051-.749 6.587 6.587 0 01-.624-4.058C1.024 2.126 4.443-.337 8.262.225c3.891.573 6.503 4.514 5.535 8.356-1.088 4.306-5.764 6.446-9.754 4.463-.106-.052-.25-.117-.35-.087C2.613 13.295 1.536 13.65.46 14zm1.384-1.72c.66-.206 1.23-.372 1.79-.563a.684.684 0 01.638.077c1.224.737 2.543.96 3.942.703 3.041-.561 5.017-3.454 4.496-6.574-.485-2.907-3.433-5.006-6.388-4.544-3.963.616-6.046 5.017-3.998 8.46a.718.718 0 01.076.666c-.193.557-.356 1.123-.558 1.775h.002z"></path>
                  <path d="M8.721 7.55c-.102-.02-.164-.026-.224-.043-.599-.172-.958-.558-1.017-1.092-.05-.46.238-.942.694-1.167a1.337 1.337 0 011.515.22c.842.75.712 2.31-.31 3.02-.375.26-.877.34-1.329.477-.07.023-.257-.08-.259-.131a.396.396 0 01.135-.306c.35-.24.642-.52.795-.977zM5.45 7.595c-.93-.24-1.319-.612-1.348-1.183a1.24 1.24 0 011.565-1.266c.696.185 1.05.677 1.158 1.357.194 1.224-.868 2.465-2.106 2.465-.117 0-.235-.092-.353-.142a1.89 1.89 0 01.197-.324c.268-.288.548-.563.887-.907z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TestimonialIcon;
