import React from "react";
import { Ratio } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";

export const Videos = ({ id, data, title, index }) => {
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {(data[index].videos.length > 0 ||
          data[index].videoLink.length > 0) && (
          <ComponentWrapper id={id} title={title}>
            {data[index].videos.map((video, idx) => (
              <div className="video-item-container" key={`video-${idx}`}>
                <video width="100%" height="auto" controls>
                  <source src={video.fileUrl[0]} type="video/mp4" />
                </video>
              </div>
            ))}
            {data[index].videoLink.map((link, idx) => (
              <div className="video-item-container" key={`video-link-${idx}`}>
                <Ratio aspectRatio="16x9">
                  <iframe
                    src={link.embed}
                    title="YouTube video"
                    allowFullScreen
                  ></iframe>
                </Ratio>
              </div>
            ))}
          </ComponentWrapper>
        )}
      </>
    );
  }
};
