import React from "react";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import EmailIcon from "../../../../components/assets/EmailIcon";
import PhoneIcon from "../../../../components/assets/PhoneIcon";

export const ContactInformationTitle = ({
  id,
  data,
  title,
  index,
  active,
  handleNavigate,
}) => {
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].teamMembers.length > 0 && (
          <div className="nav-link">
            <a
              className={active === id ? "active" : ""}
              href={`#${id}`}
              onClick={() => handleNavigate(id)}
            >
              {title}
            </a>
          </div>
        )}
      </>
    );
  }
};
