import React from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import EmailIcon from "../../../../components/assets/EmailIcon";
import PhoneIcon from "../../../../components/assets/PhoneIcon";
import LinkedInIcon from "../../../../components/assets/LinkedInIcon";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

function ContactInformationPreviewModule() {
  return (
    <ComponentWrapper id="contact-information" title="Contact Information">
      <div className="contact-information">
        <Row className="contact-info-row">
          <Col className="title-col" lg={4}>
            <h3 className="contact-info-name">David Rose</h3>
            <h4 className="contact-info-title">Brand Manager</h4>
          </Col>
          <Col className="contact-col" lg={8}>
            <p className="contact-info-contact">
              <EmailIcon width="16px" height="16px" />
              <span>david@email.com</span>
            </p>

            <p className="contact-info-contact">
              <PhoneIcon width="16px" height="16px" />
              <span>555-213-7724</span>
            </p>

            <p className="contact-info-contact">
              <LinkedInIcon width="16px" height="16px" />
              <span>View Linked In Profile</span>
            </p>
          </Col>
        </Row>
        <Row className="contact-info-row">
          <Col className="title-col" lg={4}>
            <h3 className="contact-info-name">Stevie Budd</h3>
            <h4 className="contact-info-title">VP of Business Development</h4>
          </Col>
          <Col className="contact-col" lg={8}>
            <p className="contact-info-contact">
              <EmailIcon width="16px" height="16px" />
              <span>stevie@email.com</span>
            </p>

            <p className="contact-info-contact">
              <PhoneIcon width="16px" height="16px" />
              <span>555-612-4553</span>
            </p>

            <p className="contact-info-contact">
              <LinkedInIcon width="16px" height="16px" />
              <span>View Linked In Profile</span>
            </p>
          </Col>
        </Row>
      </div>
    </ComponentWrapper>
  );
}

export default ContactInformationPreviewModule;
