import React from "react";
import { useAlert } from "react-alert";
import { Button } from "react-bootstrap";
import DocumentIcon from "../../../../components/assets/DocumentIcon";
import EyeIcon from "../../../../components/assets/EyeIcon";
import PlusIcon from "../../../../components/assets/PlusIcon";
import SaveIcon from "../../../../components/assets/SaveIcon";

export const PublishBar = ({
  setAddModuleShow,
  setSaveTemplateShow,
  setPresentationModalShow,
  setUnsavedPreviewModalShow,
  previewPresentation,
  presentationData,
  setValidation,
}) => {
  const alert = useAlert();

  function SaveTemplate() {
    if (setSaveTemplateShow) {
      return (
        <Button variant="secondary" onClick={() => setSaveTemplateShow(true)}>
          <span className="d-none d-lg-block">Save As Template</span>
          <span className="d-lg-none">
            <DocumentIcon width="16px" height="16px" fill="#232424" />
          </span>
        </Button>
      );
    } else {
      return null;
    }
  }

  function PreviewPresentation() {
    if (previewPresentation) {
      return (
        <Button variant="dark" onClick={() => setUnsavedPreviewModalShow(true)}>
          <span className="d-none d-lg-block">Preview</span>
          <span className="d-lg-none">
            <EyeIcon width="16px" height="16px" fill="#fff" />
          </span>
        </Button>
      );
    } else {
      return null;
    }
  }

  function handleSave() {
    if (!presentationData.presentationTitle) {
      alert.show("We shouldn't try saving before adding a title!", {
        type: "error",
      });
      setValidation({
        title: false,
      });
    } else {
      setValidation({
        title: true,
      });
      setPresentationModalShow(true);
    }
  }

  function SavePresentation() {
    if (setPresentationModalShow) {
      return (
        <Button variant="tertiary" onClick={handleSave}>
          <span className="d-none d-lg-block">Save</span>
          <span className="d-lg-none">
            <SaveIcon width="16px" height="16px" fill="#232424" />
          </span>
        </Button>
      );
    } else {
      return null;
    }
  }

  function AddModule() {
    if (setAddModuleShow) {
      return (
        <>
          <div className="border-start mx-4 d-none d-lg-block" />
          <Button variant="primary" onClick={() => setAddModuleShow(true)}>
            <span className="d-none d-lg-block">Add Module</span>
            <span className="d-lg-none">
              <PlusIcon width="16px" height="16px" fill="#fff" />
            </span>
          </Button>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="publish-bar">
      <SavePresentation />
      <PreviewPresentation />
      <SaveTemplate />
      <AddModule />
    </div>
  );
};
PublishBar.defaultProps = {
  setAddModuleShow: null,
  setSaveTemplateShow: null,
  setEmailModalShow: null,
};
