// import AirDatepicker from "air-datepicker";
// import "air-datepicker/air-datepicker.css";
// import localeEn from "air-datepicker/locale/en";
import Datetime from "react-datetime";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import CalendarIcon from "../../../../../../components/assets/CalendarIcon";

function PublishDatePicker({
  setCurrentDate,
  publishDate,
  expirationDate,
  setCurrentTime,
}) {
  const yesterday = moment().subtract(1, "day");
  const lastAvailable = moment(expirationDate);

  const valid = function (current) {
    return expirationDate === "" || expirationDate === null
      ? current.isAfter(yesterday)
      : current.isAfter(yesterday) && current.isBefore(lastAvailable);
  };

  const handleSetDate = (val) => {
    setCurrentDate(val);
    if (val.format() > moment().format()) {
      setCurrentTime(
        moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      );
    } else {
      setCurrentTime(moment());
    }
  };

  return (
    <div className="d-flex align-items-center position-relative">
      <Datetime
        className="date"
        isValidDate={valid}
        value={moment(publishDate)}
        timeFormat={false}
        dateFormat={true}
        onChange={handleSetDate}
      />
      <CalendarIcon width="32px" height="32px" />
    </div>
  );
}

export default PublishDatePicker;
