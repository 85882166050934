import React from "react";

function DocumentIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 191.375 250.25"
      data-name="Document Icon 1"
      fill="#222"
      fillRule="evenodd"
      {...props}
    >
      <path d="M199.685,239.261H47.252c-17.138,0-19.438-11.3-19.438-32.748V30.491c0-17.415,8.6-20.07,18.692-20.431A13.9,13.9,0,0,1,52.367,9h97.189c17.887,0,16.336,1.98,24.553,9.21s32.207,31.34,37.852,37.865,7.161,7.5,7.161,21.491V213.676C219.122,221.986,221.092,239.261,199.685,239.261ZM193.546,63.239C182.7,52.408,168.557,38.173,164.9,34.585c-0.761-.747-1.344-1.493-2.046-2.206V66.309h33.382A30.038,30.038,0,0,0,193.546,63.239ZM200.708,214.7V85.754h-36.83c-14.3,0-20.461-6.058-20.461-29.678V27.618c-2.921-.127-6.29-0.2-10.23-0.2H51.344a2.651,2.651,0,0,1-.587-0.077c-4.019-.021-4.528.342-4.528,3.147V212.653c0,8.405.859,7.164,7.161,7.164H196.615C201.6,219.817,200.708,219.536,200.708,214.7Zm-29.669-14.328H75.9a9.21,9.21,0,0,1,0-18.42h95.142A9.21,9.21,0,0,1,171.039,200.372Zm0-37.865H75.9a9.211,9.211,0,0,1,0-18.421h95.142A9.211,9.211,0,0,1,171.039,162.507Zm0-38.888H75.9a9.211,9.211,0,0,1,0-18.421h95.142A9.211,9.211,0,0,1,171.039,123.619Z" />
    </svg>
  );
}

export default DocumentIcon;
