import React from "react";
import { Form } from "react-bootstrap";

export const SelectVisibility = ({
  visibility,
  setVisibility,
  password,
  setPassword,
}) => {
  const visibilityOptions = [
    {
      value: "public",
      description: "Visible to everyone",
      label: "Public:",
      class: "form-check-fh",
    },
    {
      value: "password-protected",
      description: "Password Protected",
      label: "Private:",
      class: "",
    },
  ];
  return (
    <div className="rws-radio-group">
      {visibilityOptions.map((opt, idx) => (
        <Form.Check
          className={`d-flex align-items-center ${opt.class}`}
          key={`visibility-${idx}`}
          type="radio"
          value={opt.value}
          checked={visibility === opt.value}
          name="visibility"
          onChange={(e) => setVisibility(e.target.value)}
          label={
            <div className="d-flex align-items-center ms-3">
              <p className="mb-0">
                {`${opt.label}`}&nbsp;{opt.description}
              </p>
            </div>
          }
        />
      ))}
      {visibility === "password-protected" && (
        <Form.Group className="mb-3" controlId="passwordProtected">
          <Form.Control
            type="text"
            value={password}
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Text className="text-muted">
            Protected with a password you choose. Only those with the password
            can view this post.
          </Form.Text>
        </Form.Group>
      )}
    </div>
  );
};
