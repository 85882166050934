import React from "react";

function MediaIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      data-icon="media-icon"
      fill="#222"
      fillRule="evenodd"
      viewBox="0 0 384 297"
      {...props}
    >
      <path d="M0,297V0H384V297H0Zm351.3-29.149L230.544,146.558l-121.4,121.293H351.3Zm-321.334,0h36.8l96.77-96.683-45.46-45.42L30.272,213.476l-0.309-.308v54.683Zm0-238.7v142.3L96.891,104.58l-0.41-.409L117.668,83l67.057,67,45.634-45.593,0.253,0.254,0.241-.241L354.825,228.946V29.149H29.963Z"></path>
    </svg>
  );
}

export default MediaIcon;
