import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createNewMedia, getMedia } from "../../../api/media";
import { PdfMedia } from "../../screens/media-library/components/media-folders/PdfMedia";
import Library from "../../screens/media-library/Library";
import { ShowPdfs } from "./ShowPdfs";
import pdfPlaceholder from "../../../assets/images/500x500-placeholder-pdf.jpg";

export const AddPdfComponent = ({
  setSelectedDocument,
  selectedDocument,
  multiple,
  showPDFs,
}) => {
  const alert = useAlert();
  const [modalShow, setModalShow] = useState(false);
  const [pdfs, setPdfs] = useState([]);

  // Get Media Query
  const { data, isLoading } = useQuery("allMedia", getMedia, {
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  useEffect(() => {
    setModalShow(false);
  }, [selectedDocument]);

  useEffect(() => {
    // seperate file types
    const pdf = [];
    if (data) {
      data.forEach((file) => {
        if (file.fileType === "pdf") {
          Object.assign(file, { image: pdfPlaceholder });
          pdf.push(file);
        }
      });
    }
    setPdfs(pdf);
  }, [data]);

  function showModal() {
    setModalShow(true);
  }

  // return if successful
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <div className="add-media-button">
        <div className="add-media-button-container">
          <div>
            <Button variant="outline-primary" size="lg" onClick={showModal}>
              Upload / Select Document
            </Button>
          </div>
          {showPDFs && (
            <ShowPdfs
              selectedDocument={selectedDocument}
              showModal={showModal}
            />
          )}
        </div>
        <Modal
          show={modalShow}
          size="xl"
          className="spark-modal"
          aria-labelledby="add-pdfs"
          centered
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select PDFs
            </Modal.Title>
          </Modal.Header>
          <PdfMedia
            setSelectedDocument={setSelectedDocument}
            selectedDocument={selectedDocument}
            insertable={true}
            files={pdfs}
            tabViewsTitles={["PDFs"]}
            uploadMultiple={true}
            selectMultiple={multiple}
            isLoading={isLoading}
          />
        </Modal>
      </div>
    );
  }
};
