import React from "react";
import { Card } from "react-bootstrap";

export const CollectionCreate = (props) => {
  return (
    <Card className="component-card">
      <div className="create-collection">
        {props.title ? (
          <h2 className="create-collection-title">{props.title}</h2>
        ) : null}
        {props.children}
      </div>
    </Card>
  );
};
