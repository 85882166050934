import React from "react";

export const DashboardWrapper = ({ title, children }) => {
  return (
    <div className="dashboard">
      <h1 className="dashboard-title">{title}</h1>
      {children}
    </div>
  );
};
