import { CaseStudiesModule } from "./editor/CaseStudiesModule";
import { ContactInformationModule } from "./editor/ContactInformationModule";
import { DocumentsModule } from "./editor/DocumentsModule";
import { ImageModule } from "./editor/ImageModule";
import { MeetTheTeamModule } from "./editor/MeetTheTeamModule";
import { ServicesModule } from "./editor/ServicesModule";
import { TestimonialsModule } from "./editor/TestimonialsModule";
import { TextModule } from "./editor/TextModule";
import { VideoModule } from "./editor/VideoModule";
// import ClientInfoPreviewModule from "./preview/ClientInfoPreviewModule";
import ContactInformationPreviewModule from "./preview/ContactInformationPreviewModule";
import MeetTheTeamPreviewModule from "./preview/MeetTheTeamPreviewModule";
import DocumentPreviewModule from "./preview/DocumentPreviewModule";
import StudyPreviewModule from "./preview/StudyPreviewModule";
import VideoPreviewModule from "./preview/VideoPreviewModule";
import TestimonialPreviewModule from "./preview/TestimonialPreviewModule";
import ServicesPreviewModule from "./preview/ServicesPreviewModule";
import { ImagesPreviewModule } from "./preview/ImagesPreviewModule";
import { TextPreviewModule } from "./preview/TextPreviewModule";
import teamMemberModule from "../../../../assets/images/teamMemberModule.jpg";
import contactInfoModule from "../../../../assets/images/contactInfoModule.jpg";
import documentsModule from "../../../../assets/images/documentsModule.jpg";
import caseStudiesModule from "../../../../assets/images/caseStudiesModule.jpg";
import imageModule from "../../../../assets/images/imageModule.jpg";
import videoModule from "../../../../assets/images/videoModule.jpg";
import testimonialsModule from "../../../../assets/images/testimonialsModule.jpg";
import servicesModule from "../../../../assets/images/servicesModule.jpg";
import textModule from "../../../../assets/images/textModule.jpg";

export const addableModules = [
  // {
  //   id: "2",
  //   name: "Client Information",
  //   component: ClientInfoModule,
  // },
  {
    id: "3",
    name: "Team Members",
    component: MeetTheTeamModule,
    image: teamMemberModule,
    description:
      "Put your best foot forward. Introduce clients to your amazing team.",
  },
  {
    id: "4",
    name: "Contact Information",
    component: ContactInformationModule,
    image: contactInfoModule,
    description:
      "Spark connections. Add contact information so your clients can get in touch.",
  },
  {
    id: "5",
    name: "Documents",
    component: DocumentsModule,
    image: documentsModule,
    description:
      "Got documents that help tell your story? Use this module to share them in your presentation.",
  },
  {
    id: "6",
    name: "Case Studies",
    component: CaseStudiesModule,
    image: caseStudiesModule,
    description:
      "Make your case. Add a module to include case studies so your presentation is that much stronger.",
  },
  {
    id: "7",
    name: "Videos",
    component: VideoModule,
    image: videoModule,
    description:
      "A picture's worth a 1,000 words so videos are worth...a lot? Yeah, a lot. Use them in your presentation with this module.",
  },
  {
    id: "8",
    name: "Testimonials",
    component: TestimonialsModule,
    image: testimonialsModule,
    description:
      "Don't preach to the choir. Let the choir preach for you. Add testimonials to your presentation with this damn good module.",
  },
  {
    id: "9",
    name: "Services",
    component: ServicesModule,
    image: servicesModule,
    description:
      "Show clients that you spare no expense. Display every f***n' service you provide, right here.",
  },
  {
    id: "10",
    name: "Images",
    component: ImageModule,
    image: imageModule,
    description:
      "Did we mention? Pictures really are worth 1,000. Probably more. Include yours with this module.",
  },
  {
    id: "11",
    name: "Text",
    component: TextModule,
    image: textModule,
    description: "Get your words out. Use this module to get there.",
  },
];

export const previewModules = [
  // {
  //   id: "2",
  //   name: "Client Info Preview",
  //   component: ClientInfoPreviewModule,
  // },
  {
    id: "3",
    name: "Meet The Team",
    component: MeetTheTeamPreviewModule,
  },
  {
    id: "4",
    name: "Contact Information",
    component: ContactInformationPreviewModule,
  },
  {
    id: "5",
    name: "Documents",
    component: DocumentPreviewModule,
  },
  {
    id: "6",
    name: "Case Studies",
    component: StudyPreviewModule,
  },
  {
    id: "7",
    name: "Videos",
    component: VideoPreviewModule,
  },
  {
    id: "8",
    name: "Testimonials",
    component: TestimonialPreviewModule,
  },
  {
    id: "9",
    name: "Services",
    component: ServicesPreviewModule,
  },
  {
    id: "10",
    name: "Images",
    component: ImagesPreviewModule,
  },
  {
    id: "11",
    name: "Text",
    component: TextPreviewModule,
  },
];
