import * as React from "react";

function TrashIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      viewBox="0 0 13 15"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-84 -2)">
          <g transform="translate(0 2)">
            <g transform="translate(84)">
              <path d="M7.777 12.188h.696a.35.35 0 00.348-.352V5.508a.35.35 0 00-.348-.352h-.696a.35.35 0 00-.348.352v6.328a.35.35 0 00.348.352zm4.759-9.844h-2.392L9.158.683A1.39 1.39 0 007.963 0H5.037c-.489 0-.942.26-1.194.683l-.987 1.66H.464a.467.467 0 00-.464.47v.468c0 .26.208.469.464.469H.93v9.844A1.4 1.4 0 002.32 15h8.358a1.4 1.4 0 001.392-1.406V3.75h.465c.256 0 .464-.21.464-.469v-.468a.467.467 0 00-.464-.47zm-7.55-.852a.174.174 0 01.15-.086h2.728c.061 0 .118.033.15.086l.506.852H4.48l.506-.852zm5.693 12.102H2.32V3.75h8.358v9.844zm-6.152-1.406h.696a.35.35 0 00.348-.352V5.508a.35.35 0 00-.348-.352h-.696a.35.35 0 00-.348.352v6.328a.35.35 0 00.348.352z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TrashIcon;
