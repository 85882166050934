import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ShowImage } from "./ShowImage";
import { createNewMedia, getMedia } from "../../../api/media";
import Library from "../../screens/media-library/Library";
import { ImageMedia } from "../../screens/media-library/components/media-folders/ImageMedia";
import { useAlert } from "react-alert";

export const AddMediaComponent = ({
  setSelectedImage,
  selectedImage,
  multiple,
  message,
  showImages,
}) => {
  const alert = useAlert();

  const [modalShow, setModalShow] = useState(false);
  const [images, setImages] = useState([]);

  // Get Media Query
  const { data, isLoading } = useQuery("allMedia", getMedia, {
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  useEffect(() => {
    setModalShow(false);
  }, [selectedImage]);

  useEffect(() => {
    // seperate file types
    const img = [];
    if (data) {
      data.forEach((file) => {
        if (
          file.fileType === "jpg" ||
          file.fileType === "jpeg" ||
          file.fileType === "png"
        ) {
          Object.assign(file, { image: file.fileUrl[2] });
          img.push(file);
        }
      });
    }
    setImages(img);
  }, [data]);

  function showModal() {
    setModalShow(true);
  }

  // return if successful
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <div className="add-media-button">
        <div className="add-media-button-container">
          <div>
            <Button variant="outline-primary" size="lg" onClick={showModal}>
              Upload / Select Image
            </Button>
          </div>
          {showImages && (
            <ShowImage
              selectedImage={selectedImage}
              showModal={showModal}
              setSelectedImage={setSelectedImage}
            />
          )}
        </div>
        {message && <p className="my-3 editor-module-field-label">{message}</p>}

        <Modal
          show={modalShow}
          size="xl"
          className="spark-modal"
          aria-labelledby="add-modules"
          centered
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Images
            </Modal.Title>
          </Modal.Header>
          <ImageMedia
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            insertable={true}
            files={images}
            tabViewsTitles={["Images"]}
            uploadMultiple={true}
            selectMultiple={multiple}
            isLoading={isLoading}
          />
        </Modal>
      </div>
    );
  }
};
