import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { PresentationListItem } from "./PresentationListItem";

export const TrashPresentations = ({ data, searchInput }) => {
  const [showPresentations, setShowPresentations] = useState([]);

  // filter results on search input change
  useEffect(() => {
    if (data) {
      const fuse = new Fuse(data, {
        keys: ["presentationTitle", "slug"],
        threshold: 0.25,
      });
      const results = fuse.search(searchInput);
      const returnedData = searchInput
        ? results.map((result) => result.item)
        : data;
      setShowPresentations(
        returnedData.sort((a, b) =>
          a.presentationTitle.localeCompare(b.presentationTitle)
        )
      );
    }
  }, [searchInput]);

  return (
    <div className="presentation-list">
      <p className="text-primary fst-italic">
        {`Displaying ${showPresentations.length} results`}
      </p>
      {showPresentations &&
        showPresentations.map((presentation, idx) => (
          <PresentationListItem
            presentation={presentation}
            key={`presentation-list-${idx}`}
          />
        ))}
    </div>
  );
};

TrashPresentations.DefaultProps = {
  title: "",
  id: null,
  sortedPresentations: [],
};
