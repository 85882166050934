import React from "react";
import dompurify from "dompurify";
import { CollectionButtonPopover } from "../../../components/collection-list-buttons/CollectionButtonPopover";

export const TeamListItem = ({
  teamMember,
  setShowModal,
  setDeleteTeamObj,
}) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div className="collection-list-card">
      {teamMember.image &&
        teamMember.image.map((img, idx) => (
          <figure
            key={`img_${idx}`}
            className="collection-list-image d-none d-lg-block"
          >
            <img
              width={133}
              height={200}
              alt={img.fileAlt}
              src={img.fileUrl[3]}
            />
          </figure>
        ))}

      <div className="collection-list-col">
        <div className="collection-list-title mb-sm-4">
          <h5>{teamMember.name}</h5>
          <CollectionButtonPopover
            data={teamMember}
            setShowModal={setShowModal}
            setDeleteObj={setDeleteTeamObj}
            path={{ pathname: `/team-members/edit/${teamMember._id}` }}
          />
        </div>
        {teamMember.image &&
          teamMember.image.map((img, idx) => (
            <figure
              key={`img_${idx}`}
              className="collection-list-image d-block d-lg-none"
            >
              <img
                width={133}
                height={200}
                alt={img.fileAlt}
                src={img.fileUrl[3]}
              />
            </figure>
          ))}
        <div className="collection-list-textarea mb-sm-4">
          <div className="mb-3">
            <h4>Personal Information</h4>
            {teamMember.title && (
              <p className="mb-1">
                Title:
                <span className="light-font ms-2">{teamMember.title}</span>
              </p>
            )}
            {teamMember.email && (
              <p className="mb-1">
                Email:
                <span className="light-font ms-2">{teamMember.email}</span>
              </p>
            )}
            {teamMember.phone && (
              <p className="mb-1">
                Phone:
                <span className="light-font ms-2">{teamMember.phone}</span>
              </p>
            )}
            {teamMember.linkedIn && (
              <p className="mb-1">
                Linked In:
                <span className="light-font ms-2">{teamMember.linkedIn}</span>
              </p>
            )}
          </div>
          {teamMember.bio && (
            <div className="mb-3">
              <h4>Team Member Bio</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(teamMember.bio),
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
