import React from "react";
import MetaTags from "react-meta-tags";

export const Helmet = ({ metaTitle }) => {
  return (
    <MetaTags>
      <title>{metaTitle}</title>
    </MetaTags>
  );
};
