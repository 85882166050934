import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";

export const FrontEndHeader = ({ expiration, isLoading, contactRef }) => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (contactRef.current) {
      setRef(contactRef);
    }
    setRef(contactRef.current);
  }, [contactRef]);

  if (!isLoading) {
    let expirationTime;
    if (expiration) {
      expirationTime = moment(expiration).diff(moment(), "days");
    }

    return (
      <header className="presentation-header">
        <Navbar bg="dark" variant="dark">
          <Container fluid className="d-flex flex-column flex-sm-row">
            <Navbar.Brand className="text-white">Company Logo</Navbar.Brand>
            <Navbar.Text className="text-center d-flex align-items-center">
              {expiration && (
                <h4 className="me-3 text-white mb-0">{`Page expires in ${expirationTime}`}</h4>
              )}
              {ref && (
                <a className="btn btn-primary" href="#contact-information">
                  GET IN TOUCH
                </a>
              )}
            </Navbar.Text>
          </Container>
        </Navbar>
      </header>
    );
  }
};
