import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { FileInfo } from "../filters/FileInfo";
import { Search } from "../filters/Search";
import { MediaUpload } from "../upload/MediaUpload";
import { FileName } from "./FileName";

export const VideoMedia = ({
  files,
  multiple,
  selectMultiple,
  setSelectedVideo,
  selectedVideo,
  insertable,
}) => {
  console.log("videoFiles", files);
  const [checkedArr, setCheckedArr] = useState([]);
  const [search, setSearch] = useState("");
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [latestVal, setLatestVal] = useState("");

  // filter for search
  useEffect(() => {
    if (files) {
      const fuse = new Fuse(files, {
        keys: ["fileAlt", "fileName"],
        threshold: 0.25,
      });
      const results = fuse.search(search);
      setFilteredFiles(search ? results.map((result) => result.item) : files);
    }
  }, [search, files]);

  function handleChecked(e) {
    // set id's to an array
    if (selectMultiple) {
      if (e.currentTarget.checked) {
        setCheckedArr([...checkedArr, e.target.value]);
      } else {
        const newArr = checkedArr.filter((item) => item !== e.target.value);
        setCheckedArr(newArr);
      }
    } else {
      setCheckedArr([e.target.value]);
    }
  }

  // rerender every time a file is checked
  useEffect(() => {
    if (checkedArr.length) {
      // set the last item of the array of files
      setLatestVal(checkedArr[checkedArr.length - 1]);
      // set all tiems
      setAllSelectedItems(checkedArr);
    } else {
      setLatestVal("");
      setAllSelectedItems([]);
    }
  }, [checkedArr]);

  filteredFiles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return (
    <div className="rw_media_library_inner_container">
      <MediaUpload multiple={multiple} accept={".mp4,.mov"} />
      <Search activeTab="Images" setSearch={setSearch} search={search} />
      <div className="rw_media_Library_image_container">
        <div className="rw_media_list">
          {filteredFiles.map((file) => (
            <div key={file._id} className="rw_media_list_item">
              <input
                type={selectMultiple ? "checkbox" : "radio"}
                id={file._id}
                name="media"
                value={file._id}
                onChange={(e) => {
                  handleChecked(e);
                }}
              />
              <label htmlFor={file._id}>
                <img src={file.image} alt={file.fileName} />
                <FileName filename={file.fileName} />
              </label>
            </div>
          ))}
        </div>
        {latestVal !== "" ? (
          <FileInfo
            currentChecked={latestVal}
            reset={setLatestVal}
            files={files}
            insertable={insertable}
            selectedImage={selectedVideo}
            setSelectedImage={setSelectedVideo}
            allSelectedItems={allSelectedItems}
          />
        ) : (
          files.length > 0 && (
            <div className="rw_media_library_file_info">
              <h4 className="rw_media_library_file_info_title text-center fst-italic">
                No File Selected
              </h4>
            </div>
          )
        )}
      </div>
    </div>
  );
};
