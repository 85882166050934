import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { PublishDraft } from "./PublishDraft";
import { PublishScheduled } from "./PublishScheduled";
import { PublishCurrent } from "./PublishCurrent";

export const SelectPublishStatus = ({
  setPublishDate,
  publishDate,
  isNew,
  setPublishStatus,
  publishStatus,
  expirationDate,
  expStatus,
}) => {
  const [status, setStatus] = useState("current");
  const [savedCurrentDate, setSavedCurrentDate] = useState(moment().format());
  const [newPublishDate, setNewPublishDate] = useState();
  const currentDate = moment().format();

  useEffect(() => {
    if (isNew === true) {
      setStatus("current");
    } else {
      setSavedCurrentDate(publishDate);
      if (publishStatus === "draft") {
        setStatus("draft");
      } else {
        if (moment(publishDate).format() <= moment(currentDate).format()) {
          setStatus("current");
        } else {
          setStatus("scheduled");
        }
      }
    }
  }, [isNew]);

  useEffect(() => {
    if (status === "current") {
      setPublishDate(moment().format());
      setPublishStatus("published");
    }
    if (status === "draft") {
      setPublishDate(moment().format());
      setPublishStatus("draft");
    }
    if (status === "scheduled") {
      setPublishStatus("published");
    }
  }, [status]);

  useEffect(() => {
    setPublishDate(moment(newPublishDate).format());
  }, [newPublishDate]);

  return (
    <div className="rws-radio-group">
      <PublishCurrent
        status={status}
        setStatus={setStatus}
        isNew={isNew}
        currentDate={currentDate}
        savedCurrentDate={savedCurrentDate}
      />
      <PublishScheduled
        status={status}
        setStatus={setStatus}
        expStatus={expStatus}
        publishDate={publishDate}
        setNewPublishDate={setNewPublishDate}
        currentDate={currentDate}
        savedCurrentDate={savedCurrentDate}
        // setPublishDate={setPublishDate}
        expirationDate={expirationDate}
      />
      <PublishDraft status={status} setStatus={setStatus} />
    </div>
  );
};
