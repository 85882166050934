import api from "./api";
import imageapi from "./imageapi";

export const createNewMedia = async (payload) => {
  const data = await imageapi.post("/media/upload", payload);
  return data;
};

export const getMedia = async () => {
  const { data } = await api.get("/media");
  return data;
};

export const deleteMedia = async (id) => {
  const data = await api.delete(`/media/delete/${id}`);
  return data;
};

export const editMedia = async (payload) => {
  const data = await api.put("/media/edit", payload);
  return data;
};

export const getMediaAlt = async (id) => {
  const { data } = await api.get(`/media/${id}`);
  return data;
};
