import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";
import GlobeIcon from "../../../../components/assets/GlobeIcon";
import placeholder from "../../../../../assets/images/500x500-image-placeholder.jpg";
import DocumentIcon from "../../../../components/assets/DocumentIcon";

function StudyPreviewModule() {
  return (
    <ComponentWrapper id="case-studies" title="Case Studies">
      <Row>
        <Col lg={6} className="case-study-col">
          <figure>
            <img
              src="https://picsum.photos/500/500"
              width="100%"
              height="auto"
              alt="Case Study"
            />
          </figure>
          <h3 className="case-study-title">
            Shaving Cream or DNA? Rebel Helps Client Create Innovative,
            Headline-Grabbing Packaging
          </h3>
          <div className="case-studies-documents">
            <DocumentIcon height="16px" width="16px" />
            <button className="transparent">View Document</button>
          </div>
          <div className="case-studies-documents">
            <GlobeIcon height="16px" width="16px" />
            <button className="transparent">View Link</button>
          </div>
        </Col>
        <Col lg={6} className="case-study-col">
          <figure>
            <img
              src="https://picsum.photos/500/500"
              width="100%"
              height="auto"
              alt="Case Study"
            />
          </figure>
          <h3 className="case-study-title">
            Rebel Activates Influencers to Spur Visits to a Truly Historic Park
          </h3>
          <div className="case-studies-documents">
            <DocumentIcon height="16px" width="16px" />
            <button className="transparent">View Document</button>
          </div>
          <div className="case-studies-documents">
            <GlobeIcon height="16px" width="16px" />
            <button className="transparent">View Link</button>
          </div>
        </Col>
      </Row>
    </ComponentWrapper>
  );
}

export default StudyPreviewModule;
