import React from "react";

function EmailIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 245 184"
      data-name="Envelope Icon 1"
      fill="#222"
      fillRule="evenodd"
      {...props}
    >
      <path d="M0 184V0h245v184H0Zm90.876-80.8L28.5 166.839h186.981l-61.63-62.514c-7.265 6.871-12.8 11.985-15.145 13.893-11.082 9.02-20.544 7.7-31.459 0-2.413-1.703-8.447-7.318-16.371-15.018Zm-74.193 52.614 62.9-63.722C57.31 69.983 28.37 40.3 16.683 28.281v127.533ZM28.067 17.161c13.96 14.193 68.37 69.458 80.61 81.036 14.072 13.311 17.968 10.76 30.029-1.43 10.487-10.6 64.35-65.464 78.233-79.606H28.067Zm200.727 14.7c-9.851 9.628-39.605 38.667-62.5 60.605l62.5 63.349V31.864Z" />
    </svg>
  );
}

export default EmailIcon;
