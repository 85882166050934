import React from "react";
import { Modal, Button } from "react-bootstrap";
import { previewModules } from "../../components";
import ClientInfoPreviewModule from "../ClientInfoPreviewModule";

function TemplatePreviewModal(props) {
  const filteredModules = [];

  props.templateData.forEach((pres) => {
    previewModules.forEach((item) => {
      if (pres === item.id) {
        filteredModules.push(item);
      }
    });
  });

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={props.onHide}
      className="spark-modal"
      aria-labelledby="save-template"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="save-template">{props.templateTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="presentation-page">
          <ClientInfoPreviewModule />
          {filteredModules.map((component) => {
            return (
              <React.Fragment key={component.name}>
                <component.component name={component.name} />
              </React.Fragment>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TemplatePreviewModal;
