import React from "react";

function TeamIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 14" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="gray" transform="translate(-28 -293)">
          <g transform="translate(0 107)">
            <g transform="translate(0 55) translate(28 75)">
              <g transform="translate(0 56)">
                <path d="M21.952 14h-1.238v-1.375c.017-1.886-1.11-2.525-2-2.799-.889-.273-4.285-1.138-4.285-1.138s-2.843.718-4.381 1.138c-1.539.421-1.905 1.693-1.905 2.467V14H6.857v-1.755c0-2.191 1.609-3.223 2.692-3.581-.258-.485-.774-.602-1.359-.782-.889-.273-2.619-.664-2.619-.664s-1.128.29-2.666.711c-1.539.421-1.62 1.361-1.62 2.135v.995H0v-1.043c0-2.238 1.203-2.889 2.286-3.225.337-.105.784-.227 1.24-.346a3.223 3.223 0 01-1.004-3.588A3.238 3.238 0 015.577.713a3.238 3.238 0 013.048 2.155 3.223 3.223 0 01-1.017 3.584l1.154.292c1.254.337 1.835.973 2.098 1.554.395-.103.829-.214 1.254-.32a4.316 4.316 0 01-1.85-4.86A4.333 4.333 0 0114.435 0a4.333 4.333 0 014.16 3.133 4.316 4.316 0 01-1.868 4.853l2.225.56C21.627 9.265 22 11.337 22 12.15c0 .814-.048 1.85-.048 1.85zM5.595 1.954a1.971 1.971 0 00-1.972 1.97c.001 1.086.885 1.966 1.975 1.966s1.973-.881 1.973-1.968a1.972 1.972 0 00-1.976-1.968zm11.833 2.371a2.991 2.991 0 00-1.85-2.766 3.006 3.006 0 00-3.27.646 2.985 2.985 0 00-.652 3.26 3 3 0 002.772 1.848c.796 0 1.558-.314 2.121-.875.563-.56.88-1.32.88-2.113z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TeamIcon;
