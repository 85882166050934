import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PublishDatePicker from "./PublishDatePicker";
import PublishDateTimePicker from "./PublishDateTimePicker";

export const PublishScheduled = ({
  setStatus,
  status,
  publishDate,
  savedCurrentDate,
  setNewPublishDate,
  expirationDate,
  expStatus,
}) => {
  const [currentDate, setCurrentDate] = useState();
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    setCurrentTime(savedCurrentDate);
    setCurrentDate(savedCurrentDate);
  }, [savedCurrentDate]);

  useEffect(() => {
    const time = moment(currentTime).format("LT");
    const date = moment(currentDate).format("L");
    const dateTime = moment(date + " " + time, "MM/DD/YYYY h:mm");
    setNewPublishDate(dateTime);
  }, [currentDate, currentTime]);

  function ShowPublishPicker() {
    if (status === "scheduled") {
      return (
        <div className={`d-flex align-items-md-center flex-column flex-md-row`}>
          <p className="mx-3 mb-0">Date:</p>
          <PublishDatePicker
            setCurrentDate={setCurrentDate}
            setCurrentTime={setCurrentTime}
            publishDate={publishDate}
            expirationDate={expirationDate}
            expStatus={expStatus}
          />

          <p className="mx-3 mb-0">Time:</p>
          <PublishDateTimePicker
            setCurrentTime={setCurrentTime}
            currentTime={currentTime}
            currentDate={currentDate}
            expirationDate={expirationDate}
            publishDate={publishDate}
            expStatus={expStatus}
          />
        </div>
      );
    } else {
      return null;
    }
  }
  return (
    <div className="d-flex flex-column flex-lg-row">
      <Form.Check
        className="d-flex align-items-center form-check-fh"
        name="publishStatus"
        type="radio"
        value="scheduled"
        onChange={() => setStatus("scheduled")}
        checked={status === "scheduled"}
        label={
          <div className="d-flex align-items-center ms-3">
            {status !== "scheduled" ? (
              <p className="mb-0">Schedule Presentation</p>
            ) : (
              <p className="mb-0">Scheduled on:</p>
            )}
          </div>
        }
      />
      <ShowPublishPicker />
    </div>
  );
};

/* <p className="mx-3 mb-0">Date:</p>
<PublishDatePicker setCurrentDate={setCurrentDate} />

<p className="mx-3 mb-0">Time:</p>
<PublishDateTimePicker
  setCurrentTime={setCurrentTime}
  currentTime={currentTime}
  currentDate={currentDate}
/> */

// let setTime;

// if (moment(currentDate).format("L") === moment(new Date()).format("L")) {
//   setTime = moment(new Date()).format("LT");
// } else {
//   setTime = moment("0").format("LT");
// }
