import React from "react";
import "./assets/scss/app.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppProvider } from "./context/AppContext";
import AuthController from "./views/layouts/authentication/AuthControler";
import { BrowserRouter } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
        <AppProvider>
          <BrowserRouter>
            <AuthController />
          </BrowserRouter>
        </AppProvider>
      </Worker>
    </QueryClientProvider>
  );
}
