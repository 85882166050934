import React from "react";
import dompurify from "dompurify";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import placeholder from "../../../../../assets/images/500x500-image-placeholder.jpg";

export const MeetTheTeam = ({ id, data, title, index }) => {
  const sanitizer = dompurify.sanitize;
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].teamMembers.length > 0 && (
          <ComponentWrapper id={id} title={title}>
            <Row>
              {data[index].teamMembers.map((teamMember, idx) => (
                <Col key={`tm-${idx}`} lg={4} className="team-col">
                  <figure>
                    <img
                      src={
                        teamMember.image.length
                          ? teamMember.image[0].fileUrl[3]
                          : placeholder
                      }
                      width="100%"
                      height="auto"
                      alt={
                        teamMember.image.length
                          ? teamMember.fileAlt
                          : "placeholder-image"
                      }
                    />
                  </figure>
                  <h3 className="team-member-name">{teamMember.name}</h3>
                  <h4 className="team-member-title">{teamMember.title}</h4>
                  <div
                    className="team-member-bio"
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(teamMember.bio),
                    }}
                  ></div>
                </Col>
              ))}
            </Row>
          </ComponentWrapper>
        )}
      </>
    );
  }
};
