import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";

export const ImagesTitle = ({
  id,
  data,
  title,
  index,
  active,
  handleNavigate,
}) => {
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].images.length > 0 && (
          <div className="nav-link">
            <a
              className={active === id ? "active" : ""}
              href={`#${id}`}
              onClick={() => handleNavigate(id)}
            >
              {title}
            </a>
          </div>
        )}
      </>
    );
  }
};
