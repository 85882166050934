import React, { useContext, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import MainNavigation from "./MainNavigation";
import { AppContext } from "../../../context/AppContext";

function MobileNavigation() {
  const { mobileNavState } = useContext(AppContext);

  useEffect(() => {
    const currentUrl = window.location.pathname.split("/")[1];
    document.querySelectorAll("#main-navigation .nav-link").forEach((el) => {
      el.classList.remove("active");
      const currentelhref = el.href.split("/");
      if (currentUrl === currentelhref[currentelhref.length - 1]) {
        el.classList.add("active");
      }
    });
  }, [mobileNavState]);

  return (
    <Offcanvas
      className="mobile-main-nav header-height footer-height w-100"
      backdrop={false}
      show={mobileNavState}
    >
      <Offcanvas.Body>
        <MainNavigation />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default MobileNavigation;
