import React, { useRef, useState } from "react";
import { useOutsideAlerter } from "../../helpers/useOutsideAlerter";
import BarsIcon from "./assets/BarsIcon";

export const Dropdown = ({ children }) => {
  const [dropdownShow, setDropdownShow] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, setDropdownShow);

  document
    .querySelectorAll("spark-dropdown-menu a, spark-dropdown-menu button")
    .forEach((el) => {
      el.addEventListener("click", () => setDropdownShow(false));
    });

  return (
    <div className="spark-dropdown" ref={wrapperRef}>
      <button
        className="transparent"
        onClick={() => setDropdownShow(!dropdownShow)}
      >
        <BarsIcon width="16px" height="16px" />
      </button>
      <div className={`spark-dropdown-menu ${dropdownShow ? "show" : "hide"}`}>
        <div className="list-arrow"></div>
        {children}
      </div>
    </div>
  );
};
