import React, { useState } from "react";
import MainNavigation from "../navigation/MainNavigation";
import MobileNavigation from "../navigation/MobileNavigation";

function Sidepanel({ collapse, setCollapse, wrapper }) {
  return (
    <div
      className={`sidebar-wrapper ${collapse ? "menu-collapsed" : "menu-show"}`}
      ref={wrapper}
    >
      <nav className="sidebar-container">
        <MobileNavigation />
        <MainNavigation collapse={collapse} setCollapse={setCollapse} />
      </nav>
    </div>
  );
}

export default Sidepanel;
