import React from "react";
import { Form } from "react-bootstrap";
import { Searchbar } from "../../../components/Searchbar";

export const SearchFilter = ({ setSearchInput, searchInput }) => {
  return (
    <>
      <h2 className="mb-3">Search</h2>
      <Searchbar searchInput={searchInput} setSearchInput={setSearchInput} />
    </>
  );
};
