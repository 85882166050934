import React from "react";
import { Card } from "react-bootstrap";

export const CollectionList = (props) => {
  return (
    <Card className="component-card">
      <div className="collection-list">
        <h2 className="collection-list-title">{props.title}</h2>
        {props.children}
      </div>
    </Card>
  );
};
