import React from "react";
import TrashIcon from "../assets/TrashIcon";

export const DeletePresentationButton = ({
  presentation,
  setShowModal,
  setDeleteMutation,
  setMessage,
}) => {
  const status = presentation.status;

  // perm delete
  function handleDeleteSelect(data) {
    setShowModal(true);
    setDeleteMutation("permDelete");
    setMessage(
      "Are you sure you want to permanently delete this presentation?"
    );
  }

  // restore from trash
  function handleRemoveFromTrashSelect(data) {
    setShowModal(true);
    setDeleteMutation("restoreFromTrash");
    setMessage("Are you sure you want to restore this presentation?");
  }

  // send to trash
  function handleTrashSelect(data) {
    setShowModal(true);
    setDeleteMutation("sendToTrash");
    setMessage("Are you sure you want to move this presentation to the trash?");
  }

  return (
    <>
      {status === "trash" ? (
        <>
          <button
            className="transparent p-0"
            onClick={() => handleDeleteSelect(presentation._id)}
          >
            <div className="d-flex align-items-center">
              <TrashIcon width="16px" height="16px" />
              <p>Permanently Delete</p>
            </div>
          </button>
          <button
            className="transparent p-0"
            onClick={() => handleRemoveFromTrashSelect(presentation._id)}
          >
            <div className="d-flex align-items-center">
              <TrashIcon width="16px" height="16px" />
              <p>Restore</p>
            </div>
          </button>
        </>
      ) : (
        <button
          className="transparent p-0"
          onClick={() => handleTrashSelect(presentation._id)}
        >
          <div className="d-flex align-items-center">
            <TrashIcon width="16px" height="16px" />
            <p>Move To Trash</p>
          </div>
        </button>
      )}
    </>
  );
};
