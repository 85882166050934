import React from "react";
import { Form } from "react-bootstrap";

export const PublishDraft = ({ status, setStatus }) => {
  return (
    <Form.Check
      className="d-flex align-items-center"
      name="publishStatus"
      type="radio"
      value="draft"
      checked={status === "draft"}
      onChange={() => setStatus("draft")}
      label={
        <div className="d-flex align-items-center ms-3">
          <p className="mb-0">Draft</p>
        </div>
      }
    />
  );
};
