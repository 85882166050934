import React from "react";
import { Row } from "react-bootstrap";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

export const TextPreviewModule = () => {
  return (
    <ComponentWrapper id="text">
      <Row>
        <div className="team-member-bio">
          <p>
            If we may, um...you can use this module for whatever you want. Want
            to sell people on a deadly theme park? You can say it right here.
            You want to tell your customers you know the correct placement of
            plungers in a general store? Also right here. You got something else
            you need your client to know? Then shout it loud and shout it proud.
            And broadcast your message exactly how you want your audience to see
            it—because here, what you see is what you get.
          </p>
        </div>
      </Row>
    </ComponentWrapper>
  );
};
