import React, { useState } from "react";
import { CreateTeamMember } from "./modules/CreateTeamMember";
import { ListTeamMembers } from "./modules/ListTeamMembers";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteTeamMember,
  getTeamMembers,
  importTeamMembers,
} from "../../../api/teamMembers";
import { TeamImportModal } from "../../components/import-modals/TeamImportModal";
import teamMemberSampleCSV from "../../../assets/sample-csv/team-member-sample.csv";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { useAlert } from "react-alert";

export const TeamMembers = () => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const queryClient = useQueryClient();
  const [deleteTeamObj, setDeleteTeamObj] = useState({
    id: "",
    title: "",
  });
  const alert = useAlert();

  // bulk import team members
  const {
    mutate: bulkMutate,
    isError: bulkError,
    isLoading: bulkLoading,
  } = useMutation(importTeamMembers, {
    onSuccess: (newTeamMember) => {
      const data = newTeamMember.data.TeamMembers;
      queryClient.setQueryData("getTeamMembers", (prev) => [...prev, ...data]);
      alert.show("Your services have successfully been imported.", {
        timeout: 3000,
        type: "success",
      });
      setShow(false);
    },
    onError: (response) => {
      alert.show(response.message, {
        type: "error",
      });
    },
  });

  // Delete team member
  const { mutate } = useMutation(
    ["deleteTeamMember", deleteTeamObj.id],
    () => deleteTeamMember(deleteTeamObj.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTeamMembers");
        alert.show("Your service has successfully been deleted.", {
          timeout: 3000,
          type: "success",
        });
      },
      onError: (response) => {
        alert.show(response.message, {
          type: "error",
        });
      },
    }
  );

  // Get team members
  const { data, isLoading, isError } = useQuery(
    "getTeamMembers",
    getTeamMembers,
    {
      onError: (err) => {
        alert.show(err.message, {
          type: "error",
        });
      },
    }
  );

  function handleDeleteMutation() {
    mutate();
  }

  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        // skipEmptyLines: true,
        complete: function (results) {
          uploadFile(results);
        },
      });
    }
  }

  function uploadFile(results) {
    if (results.errors.length > 0) {
      alert(results.errors[0].message);
    } else {
      setCsvData(results.data);
      setShow(true);
    }
  }

  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Team Members"
      show={show}
      csv={teamMemberSampleCSV}
      csvRequiredStatement="<ul><li>You need to tell us your team member's name, and everyone's a special f***n' unicorn, so make it unique.</li><li>Also, no importing images. Manually upload pictures to show us each team member's pretty face.</li><ul>"
    >
      <Helmet metaTitle="Team Members | Spark | RebelWare" />
      <CreateTeamMember />
      <ListTeamMembers
        data={data}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isError={isError}
        setDeleteTeamObj={setDeleteTeamObj}
      />
      <TeamImportModal
        show={show}
        onHide={() => setShow(false)}
        data={csvData}
        isLoading={bulkLoading}
        isError={bulkError}
        mutate={bulkMutate}
      />
      <ConfirmationModal
        id={deleteTeamObj.id}
        show={showModal}
        message={`Are you sure you want to delete the following team member?`}
        item={deleteTeamObj.title}
        onHide={() => setShowModal(false)}
        mutate={handleDeleteMutation}
      />
    </ScreenWrapper>
  );
};
