import React from "react";
import { Button, Modal } from "react-bootstrap";

export const ConfirmationModal = ({
  message,
  item,
  mutate,
  id,
  show,
  ...props
}) => {
  function handleDelete(id) {
    mutate(id);
    props.onHide();
    console.log("id", id);
  }
  console.log("details", id);
  return (
    <Modal
      {...props}
      show={show}
      className="spark-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p className="text-center">
          {message}
          <span className="fw-bold ms-2">{item}</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="tertiary" onClick={() => handleDelete(id)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
