import api from "./api";

export const createNewTestimonial = async (payload) => {
  const data = await api.post("/testimonials/create", payload);
  return data;
};

export const getTestimonials = async () => {
  const { data } = await api.get("/testimonials");
  return data;
};

export const getTestimonial = async (id) => {
  const { data } = await api.get(`/testimonials/${id}`);
  return data;
};

export const deleteTestimonial = async (id) => {
  const data = await api.delete(`/testimonials/delete/${id}`);
  return data;
};

export const editTestimonial = async (payload) => {
  const data = await api.put("/testimonials/edit", payload);
  return data;
};

export const importTestimonials = async (payload) => {
  const data = await api.post("/testimonials/import", payload);
  return data;
};
