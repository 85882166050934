import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import background from "../../../assets/images/login-bg.jpg";
import LogoGIF from "../../../assets/images/sparklogo.gif";
import { AppContext } from "../../../context/AppContext";
// import LogoGIF from "../../components/assets/LogoGIF";
import Footer from "../../partials/global/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginLayout } from "../../layouts/LoginLayout";
import { Helmet } from "../../layouts/Helmet";
import { requestPasswordReset } from "../../../api/passwordReset";

function PasswordReset() {
  const navigate = useNavigate();
  // useEffect(() => {
  let footerHeight;
  const footer = document.getElementById("login-footer");
  if (footer) {
    footerHeight = footer.offsetHeight;
  }

  const [input, setInput] = useState({
    username: "",
  });

  const queryClient = useQueryClient();

  const { mutate, isError } = useMutation(requestPasswordReset, {
    onSuccess: (response) => {
      const data = response.data;

      console.log(data);
    },
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (input.username === "") {
      alert("Please enter a valid email adress");
    } else {
      mutate({
        username: input.username,
      });
    }
  }
  return (
    <LoginLayout>
      <Helmet metaTitle="Login | Spark | RebelWare" />
      <div
        className="login-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Container className="login-container">
          <Row
            className="login-row justify-content-center justify-content-lg-end"
            style={{ paddingBottom: footerHeight }}
          >
            <Col md={12}>
              <Card className="card-signin">
                <Card.Body>
                  <h2 className="card-signin-title my-0">Password Reset</h2>
                  <Form onSubmit={handleSubmit} className="form-signin">
                    <FloatingLabel
                      className="mb-3"
                      controlId="signupEmail"
                      label="Email address"
                    >
                      <Form.Control
                        type="text"
                        required={true}
                        autoComplete="none"
                        placeholder="user@email.com"
                        onChange={handleInput}
                        name="username"
                      />
                    </FloatingLabel>{" "}
                    <div className="my-3">
                      <Button type="submit" variant="primary">
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </LoginLayout>
  );
}

export default PasswordReset;
