import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import background from "../../../assets/images/login-bg.jpg";
import LogoGIF from "../../../assets/images/sparklogo.gif";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginLayout } from "../../layouts/LoginLayout";
import { Helmet } from "../../layouts/Helmet";

function Login() {
  const { loginWithRedirect } = useAuth0();

  let footerHeight;
  const footer = document.getElementById("login-footer");
  if (footer) {
    footerHeight = footer.offsetHeight;
  }

  function authLogin() {
    loginWithRedirect();
  }
  return (
    <LoginLayout>
      <Helmet metaTitle="Login | Spark | RebelWare" />
      <div
        className="login-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Container className="login-container">
          <Row
            className="login-row justify-content-center justify-content-lg-end"
            style={{ paddingBottom: footerHeight }}
          >
            <Col md={6} className="login-body">
              <img
                className="mb-3"
                src={`${LogoGIF}`}
                width={300}
                height={80}
              ></img>
              <h2 className="text-white display-2 my-4">
                Spark, Ignite, Deliver, Close
              </h2>
              <h3 className="text-white display-3 my-4">
                <p>
                  Speed is critical when it comes to sales. Deliver one thing
                  really well: send information to key audiences in a fast,
                  consistent, branded, engaging, and trackable way.
                </p>
              </h3>
            </Col>
            <Col md={6}>
              <Card className="card-signin">
                <Card.Body>
                  <Form className="form-signin">
                    <div className="my-3">
                      <a
                        className="btn btn-secondary"
                        href="https://rebelware.co/request-demo/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sign Up
                      </a>
                    </div>
                    <div className="my-3">
                      <Button variant="primary" onClick={authLogin}>
                        Sign In
                      </Button>
                    </div>
                    <div className="my-3">
                      <Button onClick={authLogin} variant="link">
                        Forgot Password
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </LoginLayout>
  );
}

export default Login;
