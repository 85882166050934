import React, { useState } from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PresentationListLabel } from "./PresentationListLabel";
import { PresentationButtonPopover } from "../../../components/presentation-list-buttons/PresentationButtonPopover";
import { EmailModal } from "../../../components/send-email/EmailModal";
import { PreviewModal } from "../../presentations/preview/PreviewModal";

import { ConfirmationPresentationModal } from "../../../components/ConfirmationPresentationModal";

export const PresentationListItem = ({ presentation }) => {
  const date = moment();
  const [deleteMutation, setDeleteMutation] = useState("");
  const [message, setMessage] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  let isLive = false;
  let status = {
    status: "",
    class: "",
  };
  if (presentation) {
    const expiration = moment(presentation.expirationDate);
    const publish = moment(presentation.publishDate);

    if (!expiration.isValid()) {
      status = {
        status: "Never Expires",
        class: "quartary",
      };
    }
    if (expiration.format() < date.format()) {
      status = {
        status: "Expired",
        class: "primary",
      };
    }
    if (expiration > date) {
      status = {
        status: `${expiration.diff(date, "days")} Days`,
        class: "tertiary",
      };
    }
    if (presentation.status === "draft") {
      status = {
        status: "Draft",
        class: "medium",
      };
    }

    if (presentation.status === "trash") {
      status = {
        status: "Trash",
        class: "welcome",
      };
    }

    if (
      presentation.status === "published" &&
      publish.format() > date.format()
    ) {
      status = {
        status: "Scheduled",
        class: "secondary",
      };
    }

    if (
      presentation.status === "published" &&
      (!expiration.isValid() || expiration > date) &&
      publish.format() < date.format()
    ) {
      isLive = true;
    } else {
      isLive = false;
    }

    function handlePreview(data) {
      setShow(true);
      setTitle(data.presentationTitle);
      setId(data._id);
    }

    return (
      <Card className="presentation-list-card">
        <PresentationListLabel status={status} />
        <div className="presentation-list-container">
          <div className="collection-list-col collection-list-info">
            <Link
              className="presentation-list-title-link"
              to={{ pathname: `/presentation/edit/${presentation._id}` }}
            >
              <h2 className="presentation-list-title">
                {presentation.presentationTitle}
              </h2>
            </Link>
            <div className="presentation-list-details">
              <div className="presentation-list-created-date">
                <p className="light-font mb-2 mb-md-0">
                  <strong>Created On:&nbsp;</strong>
                  {moment(presentation.createdAt).format("LL")}
                </p>
              </div>
              <div className="presentation-list-author">
                <p className="light-font mb-2 mb-md-0">
                  <strong>Author: </strong>
                  {presentation.author}
                </p>
              </div>
            </div>
          </div>
          <EmailModal
            show={showEmail}
            onHide={() => setShowEmail(false)}
            presentationId={presentation}
            loading={true}
          />
          <ConfirmationPresentationModal
            id={presentation._id}
            show={showModal}
            deleteMutation={deleteMutation}
            message={message}
            item={presentation.presentationTitle}
            onHide={() => setShowModal(false)}
          />
          <PresentationButtonPopover
            setShowEmail={setShowEmail}
            setShowModal={setShowModal}
            handlePreview={handlePreview}
            setDeleteMutation={setDeleteMutation}
            setMessage={setMessage}
            presentation={presentation}
            isLive={isLive}
          />
          <PreviewModal show={show} onHide={setShow} title={title} id={id} />
        </div>
      </Card>
    );
  } else {
    return null;
  }
};
