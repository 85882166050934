import axios from "axios";

export const verifyVideoLinks = async (url) => {
  const YOUTUBE = process.env.REACT_APP_YOUTUBE_API;
  const regex =
    /(http|https?:\/\/)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/;

  const match = regex.exec(url);
  let id;
  if (match) {
    id = match[6];
  } else {
    return { verified: false };
  }
  let embed = null;
  let verified = false;
  let video;
  if (match[3].indexOf("youtu") > -1) {
    video = await axios(
      `https://www.googleapis.com/youtube/v3/videos?part=id&id=${id}&key=${YOUTUBE}`
    );
    if (video.data.items.length) {
      embed = `https://www.youtube.com/embed/${video.data.items[0].id}`;
      verified = true;
    } else {
      verified = false;
    }
  } else if (match[3].indexOf("vimeo") > -1) {
    video = await axios(
      `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`
    );
    if (video.data.video_id) {
      embed = `https://player.vimeo.com/video/${video.data.video_id}`;
      verified = true;
    }
  }

  return {
    embed: embed,
    verified: verified,
  };
};
