import React from "react";

function BarsIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#242424" transform="translate(-81 -4)">
          <g transform="translate(81) translate(0 4)">
            <path d="M0 0H18V2H0z"></path>
            <path d="M0 5H18V7H0z"></path>
            <path d="M0 10H18V12H0z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BarsIcon;
