import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { AddMediaComponent } from "../../../../components/media/AddMediaComponent";
import { EditBar } from "./EditBar";

export const ClientInfoModule = ({
  setData,
  data,
  initialData,
  clientContact,
  setClientContact,
}) => {
  const [active, setActive] = useState(true);
  const [selectedImage, setSelectedImage] = useState([]);
  const [note, setNote] = useState("");
  // const [clientEmail, setClientEmail] = useState("");
  const [signature, setSignature] = useState(false);

  function handleContactInformation(e) {
    const { name, value } = e.target;
    setClientContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  useEffect(() => {
    setData({
      active: active,
      clientImage: selectedImage,
      clientNote: note,
      clientSignature: signature,
    });
  }, [active, selectedImage, note, signature]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      setActive(data.active);
      setSelectedImage(data.clientImage);
      setNote(data.clientNote);
      setSignature(data.clientSignature);
    }
  }, []);
  return (
    <Card className="component-card client-name-editor-module editor-module">
      <EditBar
        draggable={false}
        active={active}
        setActive={setActive}
        deletable={false}
      />
      <h2 className="mb-3">Client Information</h2>
      <div className="mb-3">
        <p className="editor-module-field-label">Client Name</p>
        <input
          type="text"
          className="form-control"
          aria-label="Client Name"
          placeholder="Client Name"
          value={clientContact.name}
          onChange={handleContactInformation}
          name="name"
        />
      </div>
      <div className="mb-3">
        <p className="editor-module-field-label">Client Email</p>
        <input
          type="email"
          className="form-control"
          aria-label="Client Email"
          placeholder="Client Email"
          value={clientContact.email}
          onChange={handleContactInformation}
          name="email"
        />
      </div>
      <div className="mb-3">
        <p className="editor-module-field-label">Client Logo</p>
        <AddMediaComponent
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          showImages={true}
          multiple={false}
        />
      </div>
      <div className="mb-3">
        <p className="editor-module-field-label">Note To Client</p>
        <ReactQuill theme="snow" value={note} onChange={setNote} />
      </div>
      <div className="mb-3">
        <Form.Check
          type="switch"
          className="toggle-switch"
          id="add-signature"
          checked={signature}
          label="Display Signature"
          onChange={() => setSignature(!signature)}
        />
      </div>
    </Card>
  );
};
