import React from "react";
import TrashIcon from "../assets/TrashIcon";

export const DeleteCollectionButton = ({
  setDeleteObj,
  setShowModal,
  data,
}) => {
  function handleOpenModal() {
    setDeleteObj({
      id: data._id,
      title: data.name,
    });
    setShowModal(true);
  }

  return (
    <button className="transparent p-0" onClick={handleOpenModal}>
      <div className="d-flex align-items-center">
        <TrashIcon width="16px" height="16px" />
        <p>Permanently Delete</p>
      </div>
    </button>
  );
};
