import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../404";
import CaseStudies from "../views/screens/caseStudies/CaseStudies";
import EditCaseStudy from "../views/screens/caseStudies/EditCaseStudy";
import { Dashboard } from "../views/screens/dashboard/Dashboard";
import { CreateFromBlankTemplate } from "../views/screens/presentations/CreateFromBlankTemplate.js";
import { CreateFromExistingLayout } from "../views/screens/presentations/CreateFromExistingLayout";
import { EditPresentationTemplate } from "../views/screens/presentations/EditPresentationTemplate";
import PresentationTemplateSelect from "../views/screens/presentations/PresentationTemplateSelect";
import { EditService } from "../views/screens/services/EditService";
import { Services } from "../views/screens/services/Services";
import { EditTeamMember } from "../views/screens/teamMembers/EditTeamMember";
import { TeamMembers } from "../views/screens/teamMembers/TeamMembers";
import { EditTestimonial } from "../views/screens/testimonials/EditTestimonial";
import { Testimonials } from "../views/screens/testimonials/Testimonials";
import { EditPresentation } from "../views/screens/presentations/EditPresentation";
import Library from "../views/screens/media-library/Library";

function AppRouter() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="case-studies" element={<CaseStudies />} />
        <Route
          exact
          path="case-studies/edit/:_id"
          element={<EditCaseStudy />}
        />
        <Route exact path="testimonials" element={<Testimonials />} />
        <Route
          exact
          path="testimonials/edit/:_id"
          element={<EditTestimonial />}
        />
        <Route exact path="team-members" element={<TeamMembers />} />
        <Route
          exact
          path="team-members/edit/:_id"
          element={<EditTeamMember />}
        />
        <Route exact path="services" element={<Services />} />
        <Route exact path="services/edit/:_id" element={<EditService />} />
        <Route
          exact
          path="presentation-template"
          element={<PresentationTemplateSelect />}
        />
        <Route
          exact
          path="presentation-template/edit/:_id"
          element={<EditPresentationTemplate />}
        />
        <Route
          exact
          path="presentation/create"
          element={<CreateFromBlankTemplate />}
        />
        <Route
          exact
          path="presentation/create/:_id"
          element={<CreateFromExistingLayout />}
        />
        <Route
          exact
          path="presentation/edit/:_id"
          element={<EditPresentation />}
        />
        <Route exact path="media" element={<Library />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AppRouter;
