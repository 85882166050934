import React from "react";
import dompurify from "dompurify";
import { CollectionButtonPopover } from "../../../components/collection-list-buttons/CollectionButtonPopover";

export const ServiceListItem = ({
  service,
  setShowModal,
  setDeleteServiceObj,
}) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div className="collection-list-card">
      {service.image &&
        service.image.map((img, idx) => (
          <figure
            key={`img_${idx}`}
            className="collection-list-image d-none d-lg-block"
          >
            <img
              width={133}
              height={200}
              alt={img.fileAlt}
              src={img.fileUrl[3]}
            />
          </figure>
        ))}

      <div className="collection-list-col">
        <div className="collection-list-title mb-sm-4">
          <h5>{service.title}</h5>
          <CollectionButtonPopover
            data={service}
            setShowModal={setShowModal}
            setDeleteObj={setDeleteServiceObj}
            path={{ pathname: `/services/edit/${service._id}` }}
          />
        </div>
        <div className="collection-list-textarea mb-sm-4">
          {service.url && (
            <div className="mb-3">
              <h4>Service Link</h4>
              <p className="mb-1">
                {service.urlTitle}
                <span className="light-font ms-2">{service.url}</span>
              </p>
            </div>
          )}
          {service.description && (
            <div className="mb-3">
              <h4>Service Description</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(service.description),
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
