import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";

export const Images = ({ id, data, index }) => {
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].images.length > 0 && (
          <ComponentWrapper id={id}>
            <Row>
              {data[index].images.map((image, idx) => (
                <Col key={`tm-${idx}`} md={6}>
                  <figure>
                    <img src={image.fileUrl[2]} width="100%" height="auto" />
                  </figure>
                </Col>
              ))}
            </Row>
          </ComponentWrapper>
        )}
      </>
    );
  }
};
