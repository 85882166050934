import React from "react";
import { Ratio } from "react-bootstrap";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

function VideoPreviewModule() {
  const heightFix = {
    width: 100,
    height: 500,
  };
  return (
    <ComponentWrapper id="cideos" title="Videos">
      <Ratio aspectRatio="16x9">
        <iframe
          src="https://player.vimeo.com/video/56282283"
          title="YouTube video"
        ></iframe>
      </Ratio>
    </ComponentWrapper>
  );
}

export default VideoPreviewModule;
