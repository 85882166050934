import React from "react";

function QuoteIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 200 200"
      version="1.1"
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      fill="#fff"
      {...props}
    >
      <path
        d="M79.4 129c0 13.3-10.8 24.1-24.1 24.1S31.1 142.3 31.1 129c0-2.4.3-4.6 1-6.8 1.9-6.7 6.4-13.3 6.4-13.3L71.1 46H92l-29.7 59.8c9.9 3.1 17.1 12.3 17.1 23.2zM157.8 129c0 13.3-10.8 24.1-24.1 24.1s-24.1-10.8-24.1-24.1c0-2.4.3-4.6 1-6.8 1.9-6.7 6.4-13.3 6.4-13.3L149.5 46h20.9l-29.7 59.8c9.9 3.1 17.1 12.3 17.1 23.2z"
        className="st0"
      ></path>
    </svg>
  );
}

export default QuoteIcon;
