import React, { useState, useEffect } from "react";
import XIcon from "../../../../../views/components/assets/XIcon";
import { Searchbar } from "../../../../../views/components/Searchbar";

export const Search = ({ activeTab, setSearch, search }) => {
  return (
    <div className="rw_media_library_search_container">
      <h2 className="rw_media_library_search_title">{activeTab}</h2>
      <div className="rw_media_library_search_wrapper">
        <Searchbar
          setSearchInput={setSearch}
          searchInput={search}
          placeholder="Filter Results By Filename"
        />
      </div>
    </div>
  );
};
