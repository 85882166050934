import React from "react";
import EyeIcon from "../assets/EyeIcon";

export const ViewPresentationButton = ({
  isLive,
  presentation,
  handlePreview,
}) => {
  return (
    <>
      {isLive ? (
        <a
          href={`/presentations/${presentation.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="d-flex align-items-center">
            <EyeIcon width="16px" height="16px" />
            <p>View Front-end Page</p>
          </div>
        </a>
      ) : (
        <button
          className="transparent p-0"
          onClick={() => handlePreview(presentation)}
        >
          <div className="d-flex align-items-center">
            <EyeIcon width="16px" height="16px" />
            <p>Preview Front-end Page</p>
          </div>
        </button>
      )}
    </>
  );
};
