import React, { Fragment, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { slugify } from "../../../components/slugify";
import { getPublicPresentation } from "../../../../api/presentations";
import { FrontEndLayout } from "./layouts/FrontEndLayout";
import { ClientInfo } from "./modules/ClientInfo";
import { frontendModules, frontendTitles } from "./modules/components";
import { Helmet } from "../../../layouts/Helmet";
import { Frontend404 } from "./layouts/Frontend404";

export const Presentations = (props) => {
  const [active, setActive] = useState();
  const path = window.location.pathname.split("/");
  const presentationID = path[path.length - 1];

  const { data, isLoading, isError } = useQuery(
    ["getPublicPresentation", presentationID],
    () => getPublicPresentation(presentationID),
    {
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  const contactRef = useRef();

  function handleNavigate(id) {
    setActive(id);
  }
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this presentation</h4>;
  } else if (!data.length) {
    return (
      <>
        <Helmet metaTitle="Presentation Not Found | Spark | RebelWare" />
        <FrontEndLayout>
          <Frontend404 />
        </FrontEndLayout>
      </>
    );
  } else {
    // grab layout array and match against presentation components object
    // push matched to new array to preserve order as added in editor
    const presentationData = data[0];
    const presentationComponents = [];
    const presentationTitles = [];
    presentationData.layout.forEach((o1) => {
      frontendModules.forEach((o2) => {
        if (o2.id === o1) {
          presentationComponents.push(o2);
        }
      });
      frontendTitles.forEach((o2) => {
        if (o2.id === o1) {
          presentationTitles.push(o2);
        }
      });
    });
    // const presentationComponents = [];
    // Object.values(presentationData.data).forEach((o1) => {
    //   presentationComponents.push(o1);
    // });

    return (
      <FrontEndLayout
        expiration={presentationData.expirationDate}
        isLoading={isLoading}
        contactRef={contactRef}
      >
        <Helmet
          metaTitle={`${presentationData.presentationTitle} | ${
            presentationData.presentationTitle
              ? presentationData.presentationTitle
              : "Spark"
          } | RebelWare`}
        />
        <div className="presentation-page">
          {presentationData.clientInfo.active && (
            <ClientInfo
              data={presentationData.clientInfo}
              title={presentationData.presentationTitle}
            />
          )}
          <Row>
            <Col md={3}>
              <div className="presentation-side-navigation">
                <p className="navigation-title">Jump to:</p>
                {presentationData &&
                  presentationTitles.map((component, idx) => (
                    <Fragment key={`presentation-title-${idx}`}>
                      <component.component
                        data={presentationData.data}
                        title={component.name}
                        id={slugify(component.name)}
                        contactRef={contactRef}
                        active={active}
                        index={idx}
                        handleNavigate={handleNavigate}
                      />
                    </Fragment>
                  ))}
              </div>
            </Col>
            <Col>
              <div className="presentation-display">
                {presentationData &&
                  presentationComponents.map((component, idx) => (
                    <Fragment key={`presentation-${idx}`}>
                      {
                        <div id={slugify(component.name)}>
                          <component.component
                            data={presentationData.data}
                            title={component.name}
                            id={slugify(component.name)}
                            contactRef={contactRef}
                            index={idx}
                          />
                        </div>
                      }
                    </Fragment>
                  ))}
              </div>
            </Col>
          </Row>
        </div>
      </FrontEndLayout>
    );
  }
};
