import React, { Fragment } from "react";
import { CollectionButtonPopover } from "../../../components/collection-list-buttons/CollectionButtonPopover";

export const CaseStudyListItem = ({
  caseStudy,
  setShowModal,
  setDeleteCaseObj,
}) => {
  return (
    <div className="collection-list-card">
      {caseStudy.image &&
        caseStudy.image.map((img, idx) => (
          <figure
            key={`img_${idx}`}
            className="collection-list-image d-none d-lg-block"
          >
            <img
              width={133}
              height={200}
              alt={img.fileAlt}
              src={img.fileUrl[3]}
            />
          </figure>
        ))}

      <div className="collection-list-col">
        <div className="collection-list-title mb-sm-4">
          <h5>{caseStudy.title}</h5>
          <CollectionButtonPopover
            data={caseStudy}
            setShowModal={setShowModal}
            setDeleteObj={setDeleteCaseObj}
            path={{ pathname: `/case-studies/edit/${caseStudy._id}` }}
          />
        </div>
        {caseStudy.image &&
          caseStudy.image.map((img, idx) => (
            <figure
              key={`img_${idx}`}
              className="collection-list-image d-block d-lg-none"
            >
              <img
                width={133}
                height={200}
                alt={img.fileAlt}
                src={img.fileUrl[3]}
              />
            </figure>
          ))}
        {caseStudy.documents.length > 0 && (
          <>
            <h4>Attached Documents</h4>
            <ul>
              {caseStudy.documents.map((doc, idx) => (
                <Fragment key={`doc_${idx}`}>
                  <li className="light-font text-primary mb-1">
                    {doc.fileName}
                  </li>
                </Fragment>
              ))}
            </ul>
          </>
        )}
        {caseStudy.links.length > 0 && (
          <>
            <h4>Links</h4>
            <ul>
              {caseStudy.links.map((link, idx) => (
                <Fragment key={`link_${idx}`}>
                  <li className="mb-1">
                    {link[0].title}
                    <span className="light-font ms-2">{link[0].url}</span>
                  </li>
                </Fragment>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
