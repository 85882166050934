import React from "react";
import { Dropdown } from "../Dropdown";
import { DeletePresentationButton } from "./DeletePresentationButton";
import { DuplicatePresentationButton } from "./DuplicatePresentationButton";
import { CopySharableLinkButton } from "./CopySharableLinkButton";
import { EditPresentationButton } from "./EditPresentationButton";
import { SendPresentationButton } from "./SendPresentationButton";
import { ViewPresentationButton } from "./ViewPresentationButton";

export const PresentationButtonPopover = ({
  setShowEmail,
  handlePreview,
  setShowModal,
  setDeleteMutation,
  setMessage,
  handleDuplicateSelect,
  presentation,
  isLive,
}) => {
  return (
    <Dropdown>
      <div className="collection-list-buttons">
        <ViewPresentationButton
          isLive={isLive}
          presentation={presentation}
          handlePreview={handlePreview}
        />
        {presentation.status !== "trash" && (
          <>
            {isLive && <CopySharableLinkButton presentation={presentation} />}
            <EditPresentationButton presentation={presentation} />
            <DuplicatePresentationButton
              presentation={presentation}
              handleDuplicateSelect={handleDuplicateSelect}
            />
            {isLive && (
              <SendPresentationButton
                presentation={presentation}
                setShowEmail={setShowEmail}
              />
            )}
          </>
        )}
        <DeletePresentationButton
          setShowModal={setShowModal}
          presentation={presentation}
          setDeleteMutation={setDeleteMutation}
          setMessage={setMessage}
          // setDeleteSuccessMessage={setDeleteSuccessMessage}
          // updateStatus={updateStatus}
          // permDelete={permDelete}
        />
      </div>
    </Dropdown>
  );
};

PresentationButtonPopover.defaultProps = {
  isLive: false,
  hasView: true,
  hasEdit: true,
  hasDelete: true,
  hasDuplicate: true,
  hasSend: true,
};
