import moment from "moment";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { SelectVisibility } from "./SelectVisibility";
import { SelectPublishStatus } from "./publishStatus/SelectPublishStatus";
import { SelectExpirationStatus } from "./expirationStatus/SelectExpirationStatus";

export const PresentationInformation = ({ setData, data, validation }) => {
  const [publishDate, setPublishDate] = useState(moment().format());
  const [isNew, setIsNew] = useState();
  const [expirationDate, setExpirationDate] = useState("");
  const [title, setTitle] = useState("");
  const [permalink, setPermalink] = useState("");
  const [visibility, setVisibility] = useState("public");
  const [password, setPassword] = useState("");
  const [publishStatus, setPublishStatus] = useState("published");
  const [expStatus, setExpStatus] = useState(null);

  // Send data to layout to be compiled with the data from other components
  useEffect(() => {
    let exp = null;
    if (expStatus !== "never") {
      exp = expirationDate;
    }
    setData({
      status: publishStatus,
      presentationTitle: title,
      visibility: visibility,
      publishDate: publishDate,
      expirationDate: exp,
      permalink: permalink,
      password: password,
    });
  }, [
    title,
    visibility,
    publishDate,
    expirationDate,
    permalink,
    password,
    publishStatus,
    expStatus,
  ]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (data) {
      setPublishStatus(data.status);
      setTitle(data.presentationTitle);
      setVisibility(data.visibility);
      setPublishDate(data.publishDate);
      setExpirationDate(data.expirationDate);
      setPermalink(data.slug);
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, []);

  return (
    <Card className="presentation-information-editor-module editor-module">
      <div className="mb-medium">
        <h2 className="mb-3">Presentation Info &amp; Settings</h2>
        <input
          type="text"
          className={`form-control ${!validation.title ? "is-invalid" : ""}`}
          aria-label="Presentation Name"
          placeholder="Enter Presentation Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          name="presentationName"
          required
        />
        <small className="fst-italic text-primary">* Required</small>
      </div>
      <div className="mb-medium">
        <h3 className="mb-3 editor-module-field-label">Visibility Status:</h3>
        <SelectVisibility
          setVisibility={setVisibility}
          visibility={visibility}
          setPassword={setPassword}
          password={password}
        />
      </div>
      <div className="mb-medium">
        <h3 className="mb-3 editor-module-field-label">Publish Status:</h3>
        <SelectPublishStatus
          setPublishDate={setPublishDate}
          publishDate={publishDate}
          setPublishStatus={setPublishStatus}
          publishStatus={publishStatus}
          expirationDate={expirationDate}
          expStatus={expStatus}
          isNew={isNew}
        />
      </div>
      <div className="mb-medium">
        <h3 className="mb-3 editor-module-field-label">Expiration Status:</h3>
        <SelectExpirationStatus
          publishDate={publishDate}
          publishStatus={publishStatus}
          setExpirationDate={setExpirationDate}
          expirationDate={expirationDate}
          status={expStatus}
          setStatus={setExpStatus}
          isNew={isNew}
        />
      </div>
      <div className="presentation-information-tags">
        <h3 className="mb-3 editor-module-field-label">Permalink:</h3>
        <input
          type="text"
          className="form-control mb-2"
          aria-label="Slug"
          placeholder="my-custom-slug"
          value={permalink}
          onChange={(e) => setPermalink(e.target.value)}
          name="presentationName"
        />
        <p className="editor-module-field-label small">
          If permalink is left blank, the title will be used
        </p>
      </div>
    </Card>
  );
};
