import React from "react";

function DuplicateIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 258.32 304.76"
      {...props}
    >
      <path
        fill="#222"
        fillRule="evenodd"
        d="M258.27 237.19V101.08c0-14-1.51-15-7.16-21.49S221.48 49 213.26 41.72s-6.67-9.21-24.55-9.21H91.52a14 14 0 00-5.86 1.06C75.56 33.93 67 36.59 67 54v53H24.55a14 14 0 00-5.86 1.06C8.6 108.42 0 111.08 0 128.49v176c0 21.51 2.3 32.77 19.44 32.77h152.43c21.41 0 19.44-17.27 19.44-25.58v-48.91h47.52c21.41 0 19.44-17.27 19.44-25.58zM202 55.89c.71.72 1.29 1.46 2.05 2.21 3.66 3.59 17.8 17.82 28.65 28.65a31.43 31.43 0 012.69 3.07H202zm-33.58 108.42H135v-33.93c.7.71 1.29 1.46 2.05 2.21 3.65 3.58 17.8 17.82 28.64 28.65a29 29 0 012.73 3.07zm.38 153.51H25.58c-6.3 0-7.16 1.24-7.16-7.17V128.49c0-2.81.5-3.17 4.52-3.15a3 3 0 00.59.08h81.84c3.94 0 7.31.07 10.23.2v28.46c0 23.62 6.17 29.67 20.47 29.67h36.83v129c0 4.79.89 5.07-4.1 5.07zm67-74.49h-44.49v-67.76c0-14-1.52-15-7.16-21.49s-29.64-30.64-37.85-37.87-6.67-9.21-24.56-9.21H85.38V54c0-2.8.51-3.17 4.53-3.15a2.32 2.32 0 00.58.08h81.85c3.94 0 7.31.07 10.23.2v28.46c0 23.62 6.16 29.68 20.46 29.68h36.83v128.94c0 4.84.89 5.12-4.1 5.12z"
        transform="translate(0 -32.5)"
      ></path>
    </svg>
  );
}

export default DuplicateIcon;
