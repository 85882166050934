export default (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "IS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "SET_MOBILE_NAV_STATE":
      return {
        ...state,
        mobileNavState: action.payload,
      };
    case "BLANK_PRESENTATION_COMPONENTS":
      return {
        ...state,
        blankPresentationComponents: action.payload,
      };
    case "SET_LIST_COUNT":
      return {
        ...state,
        listCount: action.payload,
      };
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentTab: action.payload,
      };
    case "SET_SHOW_ALERT":
      return {
        ...state,
        showAlert: action.payload,
      };
    case "SET_ALERT_BAR":
      return {
        ...state,
        alertBar: action.payload,
      };
    default:
      return state;
  }
};
