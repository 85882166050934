import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

function MeetTheTeamPreviewModule() {
  return (
    <div className="meet-the-team">
      <ComponentWrapper id="meet-the-team" title="Meet the Team">
        <Row>
          <Col lg={4} className="team-col">
            <figure>
              <img
                src="https://picsum.photos/525/350"
                width="100%"
                height="auto"
                alt="sample team member"
              />
            </figure>
            <h3 className="team-member-name">Jared Dunn</h3>
            <h4 className="team-member-title">Business Advisor</h4>
            <p>
              {` No one knows when this guy's birthday is, but everyone knows he's
              amazingly okay at doing...things. From being an executive team
              member at a tech company that doesn't exist, to making business
              plans for other tech companies that don't exist, this guy has all
              the makings of someone who might do something real someday. Or
              something.`}
            </p>
          </Col>
          <Col lg={4} className="team-col">
            <figure>
              <img
                src="https://picsum.photos/525/350"
                width="100%"
                height="auto"
                alt="sample team member"
              />
            </figure>
            <h3 className="team-member-name">Dinesh Chugtai</h3>
            <h4 className="team-member-title">Chief Technology Officer</h4>
            <p>
              {`A world-known coder, Dinesh knows two things: his Java, and that he can make a damn good piece of code with higher order functions that will run on anything. Period. End of sentence.`}
            </p>
          </Col>
          <Col lg={4} className="team-col">
            <figure>
              <img
                src="https://picsum.photos/525/350"
                width="100%"
                height="auto"
                alt="sample team member"
              />
            </figure>
            <h3 className="team-member-name">Erlich Bachman</h3>
            <h4 className="team-member-title">Chief PR Officer</h4>
            <p>
              {`Erlich has experience running innovation incubators for tech startups that don't exist (coincidentally, he and Jared have crossed paths before). He has the spirit of spokesman, but unfortunately can't be found. He may or may not have been sighted in Tibet.`}
            </p>
          </Col>
        </Row>
      </ComponentWrapper>
    </div>
  );
}

export default MeetTheTeamPreviewModule;
