import React from "react";
import dompurify from "dompurify";
import { CollectionButtonPopover } from "../../../components/collection-list-buttons/CollectionButtonPopover";

export const TestimonialListItem = ({
  testimonial,
  setShowModal,
  setDeleteTestimonialObj,
}) => {
  const sanitizer = dompurify.sanitize;
  console.log("image", testimonial.image);
  return (
    <div className="collection-list-card">
      {testimonial.image &&
        testimonial.image.map((img, idx) => (
          <figure
            key={`img_${idx}`}
            className="collection-list-image d-none d-lg-block"
          >
            <img
              width={133}
              height={200}
              alt={img.fileAlt}
              src={img.fileUrl[3]}
            />
          </figure>
        ))}
      <div className="collection-list-col">
        <div className="collection-list-title mb-sm-4">
          <h5>{testimonial.title}</h5>
          <CollectionButtonPopover
            data={testimonial}
            setShowModal={setShowModal}
            setDeleteObj={setDeleteTestimonialObj}
            path={{ pathname: `/testimonials/edit/${testimonial._id}` }}
          />
        </div>
        <div className="collection-list-textarea mb-sm-4">
          <div className="mb-3">
            <h4>Attestant</h4>
            {testimonial.name && (
              <p className="mb-1">
                Name:
                <span className="light-font ms-2">{testimonial.name}</span>
              </p>
            )}
            {testimonial.company && (
              <p className="mb-1">
                Company:
                <span className="light-font ms-2">{testimonial.company}</span>
              </p>
            )}
          </div>
          {testimonial.testimonial && (
            <div className="mb-3">
              <h4>Testimonial</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(testimonial.testimonial),
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
    // <div className="collection-list-card">
    //   <div className="collection-list-col collection-list-info">
    //     <div className="mb-sm-4">
    //       <p>{testimonial.title}</p>
    //       <p>{testimonial.name}</p>
    //     </div>
    //     <div>
    //       <p>{testimonial.company}</p>
    //     </div>
    //   </div>
    //   <div className="collection-list-col collection-list-textarea">
    //     <div
    //       dangerouslySetInnerHTML={{
    //         __html: sanitizer(testimonial.testimonial),
    //       }}
    //     ></div>
    //   </div>
    //   <CollectionButtonPopover
    //     data={testimonial}
    //     setShowModal={setShowModal}
    //     setDeleteObj={setDeleteTestimonialObj}
    //     path={{ pathname: `/testimonials/edit/${testimonial._id}` }}
    //   />
    // </div>
  );
};
