import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import { useAuth0 } from "@auth0/auth0-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation, useQueryClient } from "react-query";
import { addableModules } from "./backend/components";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { AddModuleModal } from "./backend/editor/modals/AddModuleModal";
import SaveTemplateModal from "./backend/editor/modals/SaveTemplateModal";
import { PublishBar } from "./backend/editor/PublishBar";
import { editPresentation } from "../../../api/presentations";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { Spinner } from "react-bootstrap";
import { EmailModal } from "../../components/send-email/EmailModal";
import { SavePresentationModal } from "./backend/editor/modals/SavePresentationModal";
import { UnsavedPreviewModal } from "./backend/editor/modals/UnsavedPreviewModal";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

export const EditPresentation = (props) => {
  const [data, setData] = useState();
  // const [initialData, setInitialData] = useState([]);
  const [clientData, setClientData] = useState({
    active: true,
    clientImage: null,
    clientNote: "",
    clientSignature: true,
  });
  const [isLoading, setLoading] = useState(true);
  const [presentationData, setPresentationData] = useState({});
  const [moduleData, setModuleData] = useState([]);
  const [addModuleShow, setAddModuleShow] = useState(false);
  const [saveTemplateShow, setSaveTemplateShow] = useState(false);
  const [addedModules, setAddedModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [componentIds, setComponentIds] = useState(["client"]);
  const [presentationModalShow, setPresentationModalShow] = useState(false);
  const [unsavedPreviewModalShow, setUnsavedPreviewModalShow] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [clientContact, setClientContact] = useState({
    name: "",
    email: "",
  });
  const [validation, setValidation] = useState({
    title: true,
  });
  const [presentation, setPresentation] = useState();
  const { user } = useAuth0();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const path = window.location.pathname.split("/");
  const presentationID = path[path.length - 1];
  let template;
  const navigate = useNavigate();

  // get presentation query
  // const { data, isLoading, isError } = useQuery(
  //   ["getPresentation", presentationID],
  //   () => getPresentation(presentationID)
  // );

  useEffect(() => {
    api.get("/presentation/" + presentationID).then((res) => {
      console.log("res", res.data[0]);
      setData(res.data[0]);
      setLoading(false);
    });
    // .then((res) => {
    //   setClientData({
    //     active: res.data[0].clientInfo.active,
    //     clientImage: res.data[0].clientInfo.clientImage,
    //     clientNote: res.data[0].clientInfo.clientNote,
    //     clientSignature: res.data[0].clientInfo.clientSignature,
    //   });
    //   template = res.data[0].layout;
    //   setComponentIds(template);
    //   setModuleData(res.data[0].data);
    //   setClientContact(res.data[0].clientInfo);
    //   template.forEach((pres) => {
    //     addableModules.forEach((item) => {
    //       if (pres === item.id) {
    //         setFilteredModules((prev) => [...prev, item]);
    //       }
    //     });
    //   });
    // });
  }, []);

  // save presentation
  // const { mutate, isLoading: saveLoading } = useMutation(editPresentation, {
  //   onSuccess: (data) => {
  //     console.log(data.data.presentation);
  //     setPresentation(data.data.presentation);
  //     queryClient.setQueryData(
  //       ["getPresentations", { _id: data.data.presentation._id }],
  //       data.data.presentation
  //     );
  //     alert.show("Your presentation has successfully been edited.", {
  //       timeout: 3000,
  //       type: "success",
  //     });
  //     navigate(`/presentation/edit/${data.data.presentation._id}`);
  //   },
  //   onError: (error) => {
  //     alert.show(error.message, {
  //       type: "error",
  //     });
  //   },
  // });

  // save and close
  const { mutate: mutateSaveClose, isLoading: saveLoading } = useMutation(
    editPresentation,
    {
      onSuccess: (data) => {
        console.log(data.data.presentation);
        setPresentation(data.data.presentation);
        queryClient.setQueryData(
          ["getPresentations", { _id: data.data.presentation._id }],
          data.data.presentation
        );
        alert.show("Your presentation has successfully been edited.", {
          timeout: 3000,
          type: "success",
        });
        navigate("/");
      },
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // save and continue
  const { mutate: mutateSaveContinue } = useMutation(editPresentation, {
    onSuccess: (data) => {
      console.log(data.data.presentation);
      setPresentation(data.data.presentation);
      queryClient.setQueryData(
        ["getPresentations", { _id: data.data.presentation._id }],
        data.data.presentation
      );
      alert.show("Your presentation has successfully been edited.", {
        timeout: 3000,
        type: "success",
      });
      navigate(`/presentation/edit/${data.data.presentation._id}`);
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // grab initial layout from presentation data and match against components to display current template layout
  // set presentation data set to queried data. this just a baseline. Still need to push the initial data to the components, but this way when items arent edited, the data is still there
  useEffect(() => {
    if (data) {
      setClientData({
        active: data.clientInfo.active,
        clientImage: data.clientInfo.clientImage,
        clientNote: data.clientInfo.clientNote,
        clientSignature: data.clientInfo.clientSignature,
      });
      template = data.layout;
      setComponentIds(template);
      setModuleData(data.data);
      setClientContact(data.mainContact);
      template.forEach((pres) => {
        addableModules.forEach((item) => {
          if (pres === item.id) {
            setFilteredModules((prev) => [...prev, item]);
          }
        });
      });
    }
  }, [data]);
  // as modules are added, set array to be mapped through and displayed and set IDs to array to be saved

  useEffect(() => {
    addableModules.forEach((item) => {
      if (addedModules === item.id) {
        setFilteredModules((prev) => [...prev, item]);
        // setComponentIds((prev) => [...prev, item.id]);
      }
    });
  }, [addedModules]);

  useEffect(() => {
    const mod = [];
    filteredModules.forEach((obj) => {
      const id = obj.id;
      mod.push(id);
    });
    setComponentIds(mod);
  }, [filteredModules]);

  // useEffect(() => {
  //   if (moduleData && componentIds) {
  //     const data = moduleData;
  //     const newset = new Set(componentIds);
  //     for (const prop of Object.keys(data)) {
  //       if (!newset.has(prop)) {
  //         delete data[prop];
  //       }
  //     }
  //     setModuleData(data);
  //   }
  // }, [componentIds]);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(filteredModules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilteredModules(items);
    const modules = Array.from(moduleData);
    const [reorderedModules] = modules.splice(result.source.index, 1);
    modules.splice(result.destination.index, 0, reorderedModules);
    setModuleData(modules);
  }

  // save edited presentation
  function savePresentation() {
    mutateSaveClose({
      mainContact: clientContact,
      id: presentationID,
      clientInfo: clientData,
      status: presentationData.status,
      presentationTitle: presentationData.presentationTitle,
      slug: presentationData.permalink,
      visibility: presentationData.visibility,
      password: presentationData.password,
      publishDate: presentationData.publishDate,
      expirationDate: presentationData.expirationDate,
      layout: componentIds,
      data: moduleData,
      author: user.name,
      user: user.sub,
    });
    return true;
  }

  function saveContPresentation() {
    mutateSaveContinue({
      mainContact: clientContact,
      id: presentationID,
      clientInfo: clientData,
      status: presentationData.status,
      presentationTitle: presentationData.presentationTitle,
      slug: presentationData.permalink,
      visibility: presentationData.visibility,
      password: presentationData.password,
      publishDate: presentationData.publishDate,
      expirationDate: presentationData.expirationDate,
      layout: componentIds,
      data: moduleData,
      author: user.name,
      user: user.sub,
    });
    return true;
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
    // } else if (isError) {
    //   return <h4>There was an error loading this presentation</h4>;
    // } else if (!data) {
    //   return <h4>There was an error loading data for this presentation</h4>;
  } else {
    return (
      <>
        <PresentationEditWrapper title={`Editing: ${data.presentationTitle}`}>
          <PublishBar
            setUnsavedPreviewModalShow={setUnsavedPreviewModalShow}
            setPresentationModalShow={setPresentationModalShow}
            setAddModuleShow={setAddModuleShow}
            setValidation={setValidation}
            previewPresentation={true}
            layout={componentIds}
            data={moduleData}
            presentationData={data}
          />
          <PresentationInformation
            setData={setPresentationData}
            data={data}
            validation={validation}
          />
          <ClientInfoModule
            setData={setClientData}
            data={clientData}
            clientContact={clientContact}
            setClientContact={setClientContact}
            initialData={clientData}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="presentation-module-list">
              <Droppable droppableId="existing-presentation">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredModules.map((component, idx) => (
                      <Draggable
                        key={`editor_${idx}`}
                        draggableId={`drag_${idx}`}
                        index={idx}
                      >
                        {(provided) => (
                          <li
                            className="presentation-edit-module"
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <component.component
                              setData={setModuleData}
                              data={moduleData}
                              initialData={data.data}
                              setFilteredModules={setFilteredModules}
                              filteredModules={filteredModules}
                              draggable={true}
                              index={idx}
                              deletable={true}
                              dragHandle={provided.dragHandleProps}
                              name={component.name}
                              componentId={component.id}
                              layout={componentIds}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </PresentationEditWrapper>
        <AddModuleModal
          show={addModuleShow}
          onHide={() => setAddModuleShow(false)}
          setAddedModules={setAddedModules}
          usedModules={filteredModules}
        />
        <SaveTemplateModal
          templateData={template}
          template={filteredModules}
          show={saveTemplateShow}
          onHide={() => setSaveTemplateShow(false)}
        />
        <SavePresentationModal
          presentationData={presentationData}
          show={presentationModalShow}
          onHide={() => setPresentationModalShow(false)}
          saveContPresentation={saveContPresentation}
          savePresentation={savePresentation}
          setEmailModalShow={setEmailModalShow}
        />
        <UnsavedPreviewModal
          show={unsavedPreviewModalShow}
          onHide={() => setUnsavedPreviewModalShow(false)}
          presentationData={presentationData}
          moduleData={moduleData}
          componentIds={componentIds}
          clientInfo={clientData}
        />
        {presentation && (
          <EmailModal
            show={emailModalShow}
            onHide={() => setEmailModalShow(false)}
            presentationId={presentation}
            loading={saveLoading}
          />
        )}
      </>
    );
  }
};
