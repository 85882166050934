import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { createNewTeamMember } from "../../../../api/teamMembers";
import { AddMediaComponent } from "../../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../../layouts/CollectionCreate";
import { verifyUrlField } from "../../../../helpers/verifyUrlField";
import { IMaskInput } from "react-imask";
import { useAlert } from "react-alert";

export const CreateTeamMember = () => {
  const [urlValidation, setUrlValidation] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const alert = useAlert();
  const [text, setText] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const [input, setInput] = useState({
    title: "",
    name: "",
    email: "",
    phone: "",
    linkedIn: "",
  });

  // set phone input masks
  const phoneMask = "(000)000-0000";

  // handle input change
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // create new team member
  const { mutate: createMutate } = useMutation(createNewTeamMember, {
    onSuccess: (newTeamMember) => {
      const data = newTeamMember.data.TeamMember;
      queryClient.setQueryData("getTeamMembers", (prev) => [...prev, data]);
      alert.show("Your team member has successfully been created.", {
        timeout: 3000,
        type: "success",
      });
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // validate url
  async function validateUrlInput() {
    const verify = await verifyUrlField(input.linkedIn);
    setUrlValidation(verify);
  }

  function handleSubmit(e) {
    e.preventDefault();
    createMutate({
      name: input.name,
      title: input.title,
      email: input.email,
      phone: input.phone,
      linkedIn: input.linkedIn,
      image: selectedImage,
      bio: text,
      user: user.sub,
    });
    setText("");
    setInput({
      title: "",
      name: "",
      email: "",
      phone: "",
      linkedIn: "",
    });
  }

  return (
    <CollectionCreate title="Create New Team Member">
      <AddMediaComponent
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        message="Recommended image size is 350px x 525px"
        showImages={true}
      />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Name"
              placeholder="Enter First and Last Name"
              value={input.name}
              onChange={handleInput}
              name="name"
              required
            />
            <small className="fst-italic text-primary">* Required</small>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Title"
              placeholder="Enter Title"
              value={input.title}
              onChange={handleInput}
              name="title"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="email"
              className="form-control"
              aria-label="email"
              placeholder="Enter Email"
              value={input.email}
              onChange={handleInput}
              name="email"
              required
            />
            <small className="fst-italic text-primary">* Required</small>
          </Col>
          <Col md={6} className="mb-3">
            <IMaskInput
              type="tel"
              className="form-control"
              aria-label="phone"
              mask={phoneMask}
              value={input.phone}
              onChange={handleInput}
              placeholder="Enter Phone Number (XXX)XXX-XXXX"
              name="phone"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="url"
              className="form-control"
              aria-label="linkedIn"
              placeholder="Enter LinkedIn URL"
              value={input.linkedIn}
              onBlur={validateUrlInput}
              onChange={handleInput}
              name="linkedIn"
            />
            {input.linkedIn && (
              <small className="fst-italic text-primary">
                {`${
                  !urlValidation ? "URL must include http:// or https://" : ""
                }`}
              </small>
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col md={6} className="mb-3">
            <div className="multi-select-container">
              <DepartmentSelect clear={clear} selectedValues={(selectedOption) => setDepartment(selectedOption)} />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div className="multi-select-container">
              <IndustrySelect clear={clear} selectedValues={(selectedOption) => setIndustry(selectedOption)} />
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} className="mb-3">
            <ReactQuill
              theme="snow"
              value={text}
              onChange={setText}
              placeholder={"Add Team Member Bio"}
            />
            {/* <RichTextEditor
              text={text}
              setText={setText}
              placeholder={"Add Team Member Bio"}
            /> */}
          </Col>
        </Row>
        <div className="mt-5">
          <Button variant="primary" size="lg" type="submit">
            Add Team Member
          </Button>
        </div>
      </Form>
    </CollectionCreate>
  );
};
