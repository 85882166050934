import React from "react";
import { Form } from "react-bootstrap";

export const ExpirationNever = ({ setStatus, status }) => {
  return (
    <Form.Check
      className="d-flex align-items-center"
      name="expirationStatus"
      type="radio"
      value="never"
      checked={status === "never"}
      onChange={() => setStatus("never")}
      label={
        <div className="d-flex align-items-center ms-3">
          <p className="mb-0">Never Expires</p>
        </div>
      }
    />
  );
};
