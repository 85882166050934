import React, { useEffect, useState } from "react";

export const Tabs = ({ titles, currentTab }) => {
  const [active, setActive] = useState();

  useEffect(() => {
    setActive("0");
  }, []);

  function handleTabClick(e) {
    setActive(e.target.id);
    currentTab(e.target.name);
  }

  const tabs = titles.map((btn, idx) => {
    return (
      <button
        className={`rw_media_tab ${active === String(idx) ? "active" : ""}`}
        key={idx}
        name={btn}
        id={idx}
        onClick={handleTabClick}
      >
        {btn}
      </button>
    );
  });

  return <div className="rw_media_tabs">{tabs}</div>;
};
