import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DocumentIcon from "../../../../components/assets/DocumentIcon";
import { ComponentWrapper } from "../../frontend/layouts/ComponentWrapper";

function DocumentPreviewModule() {
  return (
    <ComponentWrapper id="documents" title="Documents">
      <Row className="documents-row">
        <Col className="document-title-col" lg={4}>
          <h3 className="document-name">SOW</h3>
        </Col>
        <Col className="document-download-col" lg={8}>
          <DocumentIcon height="1.5rem" width="1.5rem" />
          <button className="transparent">View Document</button>
        </Col>
      </Row>
      <Row className="documents-row">
        <Col className="document-title-col" lg={4}>
          <h3 className="document-name">SOW</h3>
        </Col>
        <Col className="document-download-col" lg={8}>
          <DocumentIcon height="1.5rem" width="1.5rem" />
          <button className="transparent">View Document</button>
        </Col>
      </Row>
      <Row className="documents-row">
        <Col className="document-title-col" lg={4}>
          <h3 className="document-name">SOW</h3>
        </Col>
        <Col className="document-download-col" lg={8}>
          <DocumentIcon height="1.5rem" width="1.5rem" />
          <button className="transparent">View Document</button>
        </Col>
      </Row>
    </ComponentWrapper>
  );
}

export default DocumentPreviewModule;
