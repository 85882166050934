import React, { useState } from "react";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import { Col, Row } from "react-bootstrap";
import { DocumentModal } from "../layouts/DocumentModal";
import DocumentIcon from "../../../../components/assets/DocumentIcon";

export const Documents = ({ id, data, title, index }) => {
  const [modalShow, setModalShow] = useState(false);
  const [document, setDocument] = useState();

  function handleDocument(doc) {
    setDocument(doc);
    setModalShow(true);
  }
  if (!data[index] || !data[index].active) {
    return null;
  } else {
    return (
      <>
        {data[index].documents.length > 0 && (
          <ComponentWrapper id={id} title={title}>
            {data[index].documents.map((doc, idx) => (
              <Row className="documents-row" key={`tm-${idx}`}>
                <Col className="document-title-col" lg={4}>
                  <h3 className="document-name">{doc.fileAlt}</h3>
                </Col>
                <Col className="document-download-col" lg={8}>
                  <DocumentIcon height="1.5rem" width="1.5rem" />
                  <button
                    className="transparent"
                    onClick={() => handleDocument(doc)}
                  >
                    View Document
                  </button>
                  {/* <a href={doc.fileUrl} target="_blank" rel="noreferrer">
                Download Document
              </a> */}
                </Col>
              </Row>
            ))}
            <DocumentModal
              document={document}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </ComponentWrapper>
        )}
      </>
    );
  }
};
