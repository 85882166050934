import { Twirl as Hamburger } from "hamburger-react";
import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import Logo from "../../components/assets/Logo";
import { useAuth0 } from "@auth0/auth0-react";

function HeaderNavigation() {
  const [isOpen, setOpen] = useState(false);

  const { setMobileNavState } = useContext(AppContext);

  // Logout
  const { logout } = useAuth0();
  const { user } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    setOpen(false);
    setMobileNavState(false);
  }, [location]);

  return (
    <Navbar bg="dark">
      <Navbar.Brand href="/">
        <Logo width={147} height={60} />
      </Navbar.Brand>
      <Nav className="ms-auto">
        <div className="d-block d-lg-none">
          <Hamburger
            color="#fff"
            toggled={isOpen}
            toggle={setOpen}
            onToggle={(toggled) => {
              toggled ? setMobileNavState(true) : setMobileNavState(false);
            }}
          />
        </div>
        <NavDropdown
          title={
            <img
              className="user-img-button"
              src={user ? user.picture : ""}
              height="40px"
              width="40px"
              alt="user profile"
            />
          }
          id="nav-dropdown"
        >
          <button
            className="dropdown-item"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log Out
          </button>
          <a
            className="dropdown-item"
            target="_blank"
            rel="noreferrer"
            href="https://reg.rebelware.io/support/contact"
          >
            Support
          </a>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
}

export default HeaderNavigation;
