import api from "./api";

export const createNewPresentationTemplate = async (payload) => {
  const data = await api.post("/presentation-templates/create", payload);
  return data;
};

export const getPresentationTemplates = async () => {
  const { data } = await api.get("/presentation-templates");
  return data.result;
};

export const deletePresentationTemplate = async (id) => {
  const data = await api.delete(`/presentation-templates/${id}`);
  return data;
};

export const editPresentationTemplate = async (payload) => {
  const data = await api.put("/presentation-templates/edit", payload);
  return data;
};
