import * as React from "react";

function XIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#363636"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      {...props}
    >
      <title>{"X Close icon"}</title>
      <path d="m14.364.222 1.414 1.414L1.636 15.778.222 14.364z" />
      <path d="M.222 1.636 1.636.222l14.142 14.142-1.414 1.414z" />
    </svg>
  );
}

export default XIcon;
