import React, { useState } from "react";
import { CreateService } from "./modules/CreateService";
import { ListServices } from "./modules/ListServices";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";
import { ServicesImportModal } from "../../components/import-modals/ServicesImportModal";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useAlert } from "react-alert";
import {
  importServices,
  deleteService,
  getServices,
} from "../../../api/services";
import serviceSampleCSV from "../../../assets/sample-csv/service-sample.csv";
import { ConfirmationModal } from "../../components/ConfirmationModal";

export const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const alert = useAlert();
  const [csvData, setCsvData] = useState([]);
  const queryClient = useQueryClient();
  const [deleteServiceObj, setDeleteServiceObj] = useState({
    id: "",
    title: "",
  });

  // bulk import services
  const {
    mutate: bulkMutate,
    isError: bulkError,
    isLoading: bulkLoading,
  } = useMutation(importServices, {
    onSuccess: (newServices) => {
      console.log("newServices", newServices);
      const data = newServices.data.Services;
      queryClient.setQueryData("getServices", (prev) => [...prev, ...data]);
      alert.show("Your services have successfully been imported.", {
        timeout: 3000,
        type: "success",
      });
      setShow(false);
    },
    onError: (response) => {
      alert.show(response.message, {
        type: "error",
      });
    },
  });

  // Delete service
  const { mutate } = useMutation(
    ["deleteService", deleteServiceObj.id],
    () => deleteService(deleteServiceObj.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getServices");
        alert.show("Your service has successfully been deleted.", {
          timeout: 3000,
          type: "success",
        });
      },
      onError: (response) => {
        alert.show(response.message, {
          type: "error",
        });
      },
    }
  );

  // Get services
  const { data, isLoading, isError } = useQuery("getServices", getServices, {
    onError: (err) => {
      alert.show(err.message, {
        type: "error",
      });
    },
  });

  // handle delete mutation
  function handleDeleteMutation() {
    mutate();
  }

  // parse csv data
  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        // skipEmptyLines: true,
        complete: function (results) {
          uploadFile(results);
        },
      });
    }
  }

  // handle file upload
  function uploadFile(results) {
    if (results.errors.length > 0) {
      alert(results.errors[0].message);
    } else {
      setCsvData(results.data);
      setShow(true);
    }
  }
  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Services"
      show={show}
      csv={serviceSampleCSV}
      csvRequiredStatement="<ul><li>What're you selling? While you're telling everyone, remember that your service is like none other—make it unique.</li><ul>"
    >
      <Helmet metaTitle="Services | Spark | RebelWare" />
      <CreateService />
      <ListServices
        data={data}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isError={isError}
        setDeleteServiceObj={setDeleteServiceObj}
      />

      <ServicesImportModal
        show={show}
        onHide={() => setShow(false)}
        data={csvData}
        isLoading={bulkLoading}
        isError={bulkError}
        mutate={bulkMutate}
      />
      <ConfirmationModal
        id={deleteServiceObj.id}
        show={showModal}
        message={`Are you sure you want to delete the following service?`}
        item={deleteServiceObj.title}
        onHide={() => setShowModal(false)}
        mutate={handleDeleteMutation}
      />
    </ScreenWrapper>
  );
};
