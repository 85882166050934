import React from "react";

function GearIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 200 200"
      version="1.1"
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M170.2 98.5v3.6c-.3 2.8-.4 5.6-.8 8.3-2.4 15.5-9 28.9-20.4 39.7-16.4 15.7-36 22-58.5 19.1-15.6-2-29-8.8-39.9-20-15.9-16.4-22.5-36.1-19.5-58.7 2.5-19 11.8-34.5 27.3-46 16.5-12.3 35-16.4 55.2-12.6 14.4 2.7 26.6 9.4 36.8 19.9 10.2 10.5 16.5 23 18.8 37.5.5 3 .6 6.1 1 9.2zm-40.9-21.3c-8 1.1-15.9 2.2-23.8 3.2v14.5h26c-.1-6-.9-12-2.2-17.7zm-58.5.4c-.7 5.7-1.4 11.5-2.2 17.3h26.3V80.4c-8-.9-15.8-1.8-24.1-2.8zm34.8 28V120c8 1 15.9 2 23.8 3.1 1.3-5.6 2-11.5 2.2-17.5h-26zm-36.7-.1c.3 6 .9 11.8 2.2 17.3 8-1 16-2 23.9-2.9v-14.3c-8.8-.1-17.4-.1-26.1-.1zm5.2 27.9c4.3 10.5 10.4 19.2 20.8 24.7v-26.5c-6.9.2-13.8.6-20.8 1.8zM126 66.7c-3.3-9.7-13.7-22-20.5-24.3v26.3c6.9-.2 13.7-.7 20.5-2zM105.5 158c9.1-4.2 17-14.3 20.5-24.2-6.8-.7-13.6-1.4-20.5-2.1V158zM94.9 42.2C85 47.9 78.6 56.3 74.3 66.8c7 .7 13.8 1.3 20.6 1.9V42.2zm-53.2 63.4c.8 8.8 3.5 16.9 8 24.4 3.7-1.4 7.3-2.7 10.7-4-1.1-6.9-2.1-13.6-3.2-20.5-5 .1-10.2.1-15.5.1zM158.4 95c-.9-9.1-3.6-17.4-8.2-25.1-3.5 1.4-6.9 2.7-10.4 4 1.9 6.9 2.9 13.9 3.3 21.1h15.3zM49.1 71.3c-4.2 7.4-6.7 15.2-7.4 23.7h15.5c.4-3.5.6-6.8 1.1-10.2.5-3.3 1.2-6.6 1.9-9.9l-11.1-3.6zm91 54.7c3.1 1 6.3 2.1 9.5 3.2.9.3 1.2.1 1.7-.6 3.1-5.5 5.2-11.4 6.4-17.6.3-1.8.5-3.6.8-5.4h-15.1c-1.2 6.9-2.2 13.6-3.3 20.4zM63.5 64.5c2.6-5.2 5-10.1 7.4-15.1-5.7 3.3-10.6 7.3-15.1 12.4 2.8.9 5.3 1.8 7.7 2.7zm79.6-4.1c-4.1-4.3-8.4-7.8-13.4-10.7 2.3 4.7 4.6 9.4 7 14.1 2-1.1 4.1-2.2 6.4-3.4zm-86.3 78.9c2.4 3.3 10.7 10 12.5 10.3-1.9-4.4-3.8-8.9-5.7-13.6-2.3 1.1-4.6 2.2-6.8 3.3zm74.4 10.4c4.7-3 8.8-6.4 12.6-10.6-2.5-1-4.7-1.8-7-2.7-1.9 4.4-3.7 8.7-5.6 13.3z"></path>
    </svg>
  );
}

export default GearIcon;
