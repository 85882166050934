import React, { useState, useEffect } from "react";
import pdfPlaceholder from "../../../assets/images/500x500-placeholder-pdf.jpg";
import videoPlaceholder from "../../../assets/images/500x500-placeholder-video.jpg";
import { AllMedia } from "./components/media-folders/AllMedia";
import { ImageMedia } from "./components/media-folders/ImageMedia";
import { PdfMedia } from "./components/media-folders/PdfMedia";
import { VideoMedia } from "./components/media-folders/VideoMedia";
import { Tabs } from "./components/tabs/Tabs";
import { useQuery } from "react-query";
import { getMedia } from "../../../api/media";
import { Spinner } from "react-bootstrap";
import { Helmet } from "../../layouts/Helmet";
import { DashboardWrapper } from "../../layouts/DashboardWrapper";

export default function Library() {
  const tabViewsTitles = ["All Media", "Images", "Videos", "PDFs"];
  const [activeTab, setActiveTab] = useState(tabViewsTitles[0]);

  const allFiles = [];
  const pdf = [];
  const img = [];
  const vid = [];

  // Get Media Query
  const { data, isLoading } = useQuery("allMedia", getMedia, {
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    // seperate file types
    data.forEach((file) => {
      if (file.fileType === "pdf") {
        Object.assign(file, { image: pdfPlaceholder });
        pdf.push(file);
        allFiles.push(file);
      }
      if (file.fileType === "mp4" || file.fileType === "mov") {
        Object.assign(file, { image: videoPlaceholder });
        vid.push(file);
        allFiles.push(file);
      }
      if (
        file.fileType === "jpg" ||
        file.fileType === "jpeg" ||
        file.fileType === "png"
      ) {
        Object.assign(file, { image: file.fileUrl[2] });
        img.push(file);
        allFiles.push(file);
      }
    });
    return (
      <DashboardWrapper title="Media Library">
        <Helmet metaTitle="Media Library | Spark | RebelWare" />
        <div className="rw_media_library">
          <Tabs titles={tabViewsTitles} currentTab={setActiveTab} />
          {{
            Images: (
              <ImageMedia multiple={false} files={img} activeTab={activeTab} />
            ),
            Videos: (
              <VideoMedia multiple={false} files={vid} activeTab={activeTab} />
            ),
            PDFs: (
              <PdfMedia multiple={false} files={pdf} activeTab={activeTab} />
            ),
          }[activeTab] || (
            <AllMedia multiple={false} files={allFiles} activeTab={activeTab} />
          )}
        </div>
      </DashboardWrapper>
    );
  }
}

Library.defaultProps = {
  selectedImage: null,
  tabViewsTitles: ["All Media", "Images", "Videos", "PDFs"],
  uploadMultiple: false,
  selectMultiple: false,
  isLoading: false,
  insertable: true,
};
