import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createNewMedia, getMedia } from "../../../api/media";
import Library from "../../screens/media-library/Library";
import { ShowVideo } from "./ShowVideo";
import videoPlaceholder from "../../../assets/images/500x500-placeholder-video.jpg";
import { VideoMedia } from "../../screens/media-library/components/media-folders/VideoMedia";

export const AddVideoComponent = ({
  setSelectedVideo,
  selectedVideo,
  multiple,
  showVideo,
}) => {
  const alert = useAlert();
  const [modalShow, setModalShow] = useState(false);
  const [videos, setVideos] = useState([]);

  // Get Media Query
  const { data, isLoading } = useQuery("allMedia", getMedia, {
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  useEffect(() => {
    setModalShow(false);
  }, [selectedVideo]);

  useEffect(() => {
    // seperate file types
    const vid = [];
    if (data) {
      data.forEach((file) => {
        if (file.fileType === "mp4" || file.fileType === "mov") {
          Object.assign(file, { image: videoPlaceholder });
          vid.push(file);
        }
      });
    }
    setVideos(vid);
  }, [data]);

  function showModal() {
    setModalShow(true);
  }
  // return if successful
  // return if successful
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <div className="add-media-button">
        <div className="add-media-button-container">
          <div>
            <Button variant="outline-primary" size="lg" onClick={showModal}>
              Upload / Select Video
            </Button>
          </div>
          {showVideo && (
            <ShowVideo selectedVideo={selectedVideo} showModal={showModal} />
          )}
        </div>
        <Modal
          show={modalShow}
          size="xl"
          className="spark-modal"
          aria-labelledby="add-videos"
          centered
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Videos
            </Modal.Title>
          </Modal.Header>
          <VideoMedia
            insertable={true}
            files={videos}
            tabViewsTitles={["Videos"]}
            uploadMultiple={true}
            selectMultiple={multiple}
            isLoading={isLoading}
            setSelectedVideo={setSelectedVideo}
            selectedVideo={selectedVideo}
          />
        </Modal>
      </div>
    );
  }
};
