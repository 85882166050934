import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import { getService, editService } from "../../../api/services";
import { CollectionCreate } from "../../layouts/CollectionCreate";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import { Helmet } from "../../layouts/Helmet";
import { verifyUrlField } from "../../../helpers/verifyUrlField";
import { useAlert } from "react-alert";
import { AddMediaComponent } from "../../components/media/AddMediaComponent";

export const EditService = () => {
  const [description, setDescription] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [urlValidation, setUrlValidation] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [inputValue, setInputValue] = useState({
    title: "",
    url: "",
    urlTitle: "",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/");
  const serviceID = path[path.length - 1];
  const { user } = useAuth0();
  const alert = useAlert();

  // get services query
  const { data, isLoading, isError } = useQuery(
    ["getService", serviceID],
    () => getService(serviceID),
    {
      onError: (error) => {
        alert.show(error.message, {
          type: "error",
        });
      },
    }
  );

  // edit service mutation
  const { mutate } = useMutation(editService, {
    onSuccess: () => {
      queryClient.invalidateQueries("getServices");
      alert.show("Your service has successfully been edited.", {
        timeout: 3000,
        type: "success",
      });
      navigate("/services");
    },
    onError: (error) => {
      alert.show(error.message, {
        type: "error",
      });
    },
  });

  // set values on load
  useEffect(() => {
    if (data) {
      setOriginalName(data[0].title);
      setSelectedImage(data[0].image);
      setInputValue({
        title: data[0].title,
        url: data[0].url,
        urlTitle: data[0].urlTitle,
      });
      if (data[0].description) {
        setDescription(data[0].description);
      }
    }
  }, [data]);

  // update inputs
  function handleInput(e) {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  // verify url
  async function validateUrlInput() {
    const verify = await verifyUrlField(inputValue.url);
    setUrlValidation(verify);
  }

  // edit service mutation
  function handleSubmit(e) {
    e.preventDefault();
    mutate({
      id: serviceID,
      title: inputValue.title,
      image: selectedImage,
      url: inputValue.url,
      urlTitle: inputValue.urlTitle,
      description: description,
      user: user.sub,
    });
  }

  if (isLoading || isError) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <ScreenWrapper importIcon={false} screenTitle={`Edit ${originalName}`}>
        <Helmet metaTitle="Edit Service | Spark | RebelWare" />
        <CollectionCreate>
          <AddMediaComponent
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            message="Recommended image size is 350px x 525px"
            showImages={true}
          />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Title"
                  placeholder="Enter Title"
                  value={inputValue.title}
                  onChange={handleInput}
                  name="title"
                  required
                />
                <small className="fst-italic text-primary">* Required</small>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="url-title"
                  placeholder="Display Text for Link"
                  value={inputValue.urlTitle}
                  onChange={handleInput}
                  name="urlTitle"
                  required={inputValue.url}
                />
                {(inputValue.url || inputValue.urlTitle) && (
                  <small className="fst-italic text-primary">* Required</small>
                )}
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="url"
                  className="form-control"
                  aria-label="Url"
                  placeholder="Enter URL of the Related Service"
                  value={inputValue.url}
                  onChange={handleInput}
                  onBlur={validateUrlInput}
                  name="url"
                  required={inputValue.urlTitle}
                />

                {(inputValue.url || inputValue.urlTitle) && (
                  <small className="fst-italic text-primary">{`* Required ${
                    !urlValidation ? "URL must include http:// or https://" : ""
                  }`}</small>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </Col>
            </Row>
            <div className="mt-5 d-flex justify-content-between">
              <Button as={Link} to="/services" variant="secondary" size="lg">
                Cancel
              </Button>
              <Button variant="primary" size="lg" type="submit">
                Save Changes
              </Button>
            </div>
          </Form>
        </CollectionCreate>
      </ScreenWrapper>
    );
  }
};
