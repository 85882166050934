import React from "react";

function LeftIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 13" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" fillRule="nonzero" transform="translate(-211 -77)">
          <g transform="matrix(0 -1 1 0 211 89.25)">
            <path
              d="M6 4.4408921e-16L12 6.02 11.0666667 7 5.06666667 0.98z"
              transform="rotate(-180 8.533 3.5)"
            ></path>
            <path
              d="M8.8817842e-16 6.02L6 0 6.93333333 0.98 0.933333333 7z"
              transform="rotate(-180 3.467 3.5)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LeftIcon;
