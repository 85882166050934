import React from "react";
import { Link } from "react-router-dom";
import PencilIcon from "../assets/PencilIcon";

export const EditPresentationButton = ({ presentation }) => {
  return (
    <>
      <Link to={{ pathname: `/presentation/edit/${presentation._id}` }}>
        <div className="d-flex align-items-center">
          <PencilIcon width="16px" height="16px" />
          <p>Edit</p>
        </div>
      </Link>
    </>
  );
};
